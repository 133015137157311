import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { InputLabel } from './styledPeers';
import Loader from '../../../components/Loader';
import {
  FIELD_PRIORITY,
  PRIMARY_INPUT_FIELDS,
  SOC_FIELDS,
  EVENT_FORM,
  TOAST_REDUCER_CONSTANTS,
  MESSAGE_STRINGS,
} from '../../../constants';
import { useDemoContext } from '../../../contexts/demo/reducer';
import Select from '../../../components/Select';
import { getRecipeList } from '../../../utils/apiHelpers';
import { demoActions } from '../../../contexts/demo/actions';
import TriggerSOCTable from './triggerSOCTable';
import FieldsErrorIndicator from './fieldsErrorIndicator';
import { useToastContext } from '../../../contexts/toastContext';

const TriggerSocField = () => {
  const { demoState, demoDispatch } = useDemoContext();
  const { toastDispatch } = useToastContext();

  const { options, error, value } =
    demoState.eventForm[FIELD_PRIORITY.SOC][SOC_FIELDS.RECIPE_SELECTED.key];

  const { type: formType } = demoState.eventForm;

  const { value: eventName } =
    demoState.eventForm[FIELD_PRIORITY.PRIMARY][
      PRIMARY_INPUT_FIELDS.SELECT_EVENT.key
    ];
  const { value: plantId } =
    demoState.eventForm[FIELD_PRIORITY.PRIMARY][PRIMARY_INPUT_FIELDS.PLANT.key];

  const modifyRecipeResponse = (response) => {
    const newArray = response.map((item) => ({
      ...item,
      value: `${item.recipeId}`,
      label: `${item.recipeName}`,
    }));
    return newArray;
  };
  const { isFetching: recipeFetching, isError: recipeListError } = useQuery({
    queryKey: [eventName, formType],
    queryFn: async () => {
      const response = await getRecipeList(plantId);
      const updatedResponse = modifyRecipeResponse(response);
      demoDispatch(demoActions.updateSocEventFields(updatedResponse));
      return updatedResponse;
    },
    onError: () => {
      toastDispatch({
        type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
        payload: {
          message: MESSAGE_STRINGS['Toast.message.RECIPEERROR'],
        },
      });
    },
    enabled: !!eventName,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const errorBool = recipeListError;
  if (errorBool) {
    return null;
  }

  if (recipeFetching) {
    return <Loader />;
  }
  const recipeErrorMessage = EVENT_FORM.ERROR_MESSAGE.RECIPE_SELECTED;

  const onChangeHandler = (e) => {
    demoDispatch(demoActions.updateRecipeSelect(e.target.value));
  };

  return (
    <>
      <div style={{ width: 'min-content' }} data-testid="soc-select">
        <InputLabel>{SOC_FIELDS.RECIPE_SELECTED.name}</InputLabel>
        <Select
          options={options}
          error={error}
          onChange={onChangeHandler}
          value={value}
        />
      </div>
      {error && <FieldsErrorIndicator errorMessage={recipeErrorMessage} />}
      {demoState.eventForm[FIELD_PRIORITY.SOC].RECIPE_SELECTED.value && (
        <TriggerSOCTable />
      )}
    </>
  );
};

export default TriggerSocField;
