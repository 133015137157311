import { Box, Divider, styled, Typography, Grid, alpha } from '@mui/material';

const EventFormBox = styled(Box)(({ theme }) => ({
  width: '75rem',
  borderRadius: '.5rem',
  backgroundColor: theme.palette.background.blackGrey,
  maxHeight: '80%',
  overflowY: 'scroll',
}));

const HeaderBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '4rem',
  borderBottom: `.0625rem solid ${theme.customColors.wrapperGrey}`,
  padding: '1.125rem 1.5rem',
}));
const SOCErrorText = styled(Box)(({ theme }) => ({
  border: `.0625rem solid ${theme.palette.background.errorColor}`,
  padding: '0.1rem 0.5rem 0.5rem 0.5rem',
  width: '20rem',
}));

const SOCGridItem = styled((props) => <Grid item {...props} />)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '10%',
  height: '3rem',
}));
const SOCTableContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
}));
const SOCHeaderBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: '.5rem 0',
}));
const SOCTableBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '.5rem 0',
  height: '100%',
}));

const SOCBodyRowGrid = styled(Grid)(({ theme }) => ({
  borderColor: theme.customColors.wrapperGrey,
  borderStyle: 'solid',
  borderWidth: '0 0 .0625rem',
  '&:hover': {
    backgroundColor: alpha(theme.customColors.white, 0.08),
  },
}));

const SOCBodyBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.blackGrey,
  border: `.0625rem solid ${theme.customColors.wrapperGrey}`,
  borderWidth: '0 .0625rem',
  flexGrow: 1,
  maxHeight: '30rem',
  overflowY: 'auto',
}));

const SOCMainHeading = styled(Typography)(() => ({
  fontSize: '1rem',
  lineHeight: '1.4rem',
}));

const SOCTableContentLabel = styled(Typography, {
  shouldForwardProp: (p) => p !== 'isHeader',
})(({ theme, isHeader }) => ({
  color: theme.palette.text[isHeader ? 'lightUnitGrey' : 'primary'],
  fontWeight: 400,
  fontSize: '.875rem',
  lineHeight: isHeader ? '1.19rem' : '1.25rem',
}));

const HeadingTypo = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: '1.25rem',
  lineHeight: '1.7rem',
}));

const InputsWrapper = styled(Box)(() => ({ padding: '1.5rem' }));

const InputLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.lightUnitGrey,
  fontWeight: 400,
  fontSize: '.875rem',
  lineHeight: '1.19rem',
  marginBottom: '.5rem',
}));

const ErrorMessageTypo = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: '.75rem',
  lineHeight: '1.19rem',
  marginLeft: '.5rem',
}));

const DividerLine = styled(Divider)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.border.darkGrey}`,
  margin: '.5rem 0',
}));

const ThresholdWrapperBox = styled(Box)(({ theme }) => ({
  border: `.0625rem solid ${theme.customColors.wrapperGrey}`,
  borderRadius: '.25rem',
  width: '18.75rem',
}));

const ThresholdRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderBottom: `.0625rem solid ${theme.customColors.wrapperGrey}`,
  ':last-of-type': { borderBottom: 'unset' },
  height: '3rem',
  padding: '.875rem 1rem',

  '& span': {
    fontWeight: 400,
    fontSize: '.875rem',
    lineHeight: '1.25rem',
    ':nth-of-type(2)': { marginLeft: 'auto' },
    ':nth-of-type(3)': { margin: '0 .5rem' },
  },
}));

const SaveAsDemoBox = styled(Box)(({ theme }) => ({
  width: '56.25rem',
  borderRadius: '.5rem',
  backgroundColor: theme.palette.background.blackGrey,
}));

const ModalHeaderBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '4rem',
  borderBottom: `.0625rem solid ${theme.customColors.wrapperGrey}`,
  padding: '1.125rem 1.5rem',
}));

const OptionsBox = styled(Box)(() => ({ padding: '1.5rem' }));

const FlexBox = styled(Box, {
  shouldForwardProp: (p) => !['isOptionsRow', 'isOptionsGrow'].includes(p),
})(({ isOptionsRow, isOptionsGrow }) => ({
  display: 'flex',
  alignItems: 'center',
  ...(isOptionsRow && {
    width: '100%',
    padding: '.75rem 0',
    ':first-of-type': { paddingTop: 0 },
    ':last-of-type': { paddingBottom: 0 },
  }),
  ...(isOptionsGrow && { flex: 1, justifyContent: 'center' }),
}));

export {
  EventFormBox,
  HeaderBox,
  HeadingTypo,
  InputsWrapper,
  InputLabel,
  ErrorMessageTypo,
  DividerLine,
  ThresholdWrapperBox,
  ThresholdRow,
  SaveAsDemoBox,
  ModalHeaderBox,
  OptionsBox,
  FlexBox,
  SOCGridItem,
  SOCHeaderBox,
  SOCTableBox,
  SOCTableContainer,
  SOCMainHeading,
  SOCTableContentLabel,
  SOCBodyBox,
  SOCBodyRowGrid,
  SOCErrorText,
};
