import { Button as MuiButton, styled } from '@mui/material';
import PropTypes from 'prop-types';

const StyledButton = styled(MuiButton, {
  shouldForwardProp: (p) => !['isApply', 'h', 'w'].includes(p),
})(({ theme, isApply, h, w }) => ({
  width: w,
  height: h,
  border: `1px solid ${theme.customColors[isApply ? 'saveGreen' : 'white']}`,
  borderRadius: '1.25rem',
  color: theme.customColors[isApply ? 'black' : 'white'],
  backgroundColor: isApply ? theme.customColors.saveGreen : 'transparent',
  textTransform: 'none',
  '&.MuiButton-root:hover': {
    color: theme.customColors[isApply ? 'white' : 'black'],
    backgroundColor: isApply ? 'transparent' : theme.customColors.white,
    border: `1px solid ${theme.customColors[isApply ? 'saveGreen' : 'white']}`,
  },
  '&.MuiButton-text': {
    fontWeight: 600,
    fontSize: '.875rem',
    lineHeight: '1rem',
  },
}));

const Button = ({ isApply, height, width, onClick, children, ...props }) => {
  return (
    <StyledButton
      isApply={isApply}
      h={height}
      w={width}
      onClick={onClick}
      {...props}
    >
      {children}
    </StyledButton>
  );
};

Button.propTypes = {
  isApply: PropTypes.bool,
  height: PropTypes.string,
  width: PropTypes.string,
  onClick: PropTypes.func,
  fontWeight: PropTypes.bool,
  children: PropTypes.node,
};

export default Button;
