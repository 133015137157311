import { MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import DownArrowIcon from '../../assets/img/downArrowIcon.svg';
import { MenuProps, StyledSelect, StyledTooltip } from '../styledPeers';

const Select = ({
  value = '',
  onChange = () => {},
  options = [],
  disabled = false,
  error = false,
  children,
  ...props
}) => {
  const renderValue = (rv) => {
    if (!rv.length) return 'Select';
    return options.filter((o) => o.value === rv)[0]?.label ?? rv;
  };
  return (
    <StyledSelect
      IconComponent={(p) => <DownArrowIcon height={32} width={12} {...p} />}
      MenuProps={{ ...MenuProps }}
      value={value}
      onChange={onChange}
      displayEmpty
      renderValue={renderValue}
      disabled={disabled}
      error={error}
      {...props}
    >
      {options.map((option) => (
        <MenuItem
          style={{ width: '17rem' }}
          value={option.value}
          key={option.value}
        >
          <StyledTooltip
            title={option.label}
            placement="right"
            disableHoverListener={option.label.length < 32}
          >
            <div
              style={{
                overflow: 'hidden',
                textOverflow: option.label.length < 32 ? 'initial' : 'ellipsis',
                whiteSpace: 'nowrap',
                width: '14.85rem',
              }}
            >
              {option.label}
            </div>
          </StyledTooltip>
        </MenuItem>
      ))}
      {children}
    </StyledSelect>
  );
};

Select.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })
  ),
  error: PropTypes.bool,
  children: PropTypes.node,
};

export default Select;
