import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './App.css';
import { ToastProvider } from '../contexts/toastContext';
import Simulator from './Simulator';
import DemoProvider from '../contexts/demo/reducer';

const App = () => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <ToastProvider>
        <DemoProvider>
          <Simulator />
        </DemoProvider>
      </ToastProvider>
    </QueryClientProvider>
  );
};

export default App;
