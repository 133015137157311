import React from 'react';
import PropTypes from 'prop-types';
import { Button, Popover, alpha, styled, Box } from '@mui/material';
import AssetFilterSVG from '../../assets/img/assetFilterIcon.svg';
import TEST_IDS from '../../constants/testIds';
import DFilter from '../DFilter';

const customStyles = {
  selectedLenVal: {
    width: '1.3em',
    height: '1.3em',
    background: (theme) => theme.customColors.highlightBlue,
    borderRadius: '50%',
    fontSize: '0.75em',
    lineHeight: '1em',
    color: (theme) => theme.palette.background.configLighterDark,
    marginTop: '-1em',
    marginLeft: '-1em',
    textAlign: 'center',
    paddingTop: '0.2em',
  },
  selectedLenValLarge: {
    width: '2.2em',
    height: '2.2em',
    background: (theme) => theme.customColors.highlightBlue,
    borderRadius: '50%',
    fontSize: '0.75em',
    lineHeight: '1.5em',
    color: (theme) => theme.palette.background.configLighterDark,
    marginTop: '-1em',
    marginLeft: '-1em',
    textAlign: 'center',
    paddingTop: '0.36em',
  },
};

const FilterPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPopover-paper': {
    width: '17em',
    height: '30em',
    padding: '1em',
    background: theme.palette.background.configLighterDark,
    border: `0.063em solid ${alpha(theme.customColors.darkGrey, 0.2)}`,
    boxSizing: 'border-box',
    boxShadow: `0 0.25em 0.5em ${alpha(theme.customColors.black, 0.25)}`,
  },
  '& .MuiPaper-root .MuiPaper-rounded': {
    backgroundColor: 'transparent',
  },
  '& .MuiInputBase-root.MuiInputBase-formControl': {
    width: '100%',
    height: '1.75em',
    background: theme.customColors.wrapperGrey,
    borderRadius: '0.25em',
    padding: '0.75em',
    marginTop: '0.75em',
  },
}));

const FilterPopOver = (props) => {
  const {
    clickCallback,
    filterProps,
    onChangeFunc,
    itemDropDownValues,
    idRef,
    openBool,
    itemAnchorEl,
    maxChar,
    isNoRecords,
    filtersByName,
  } = props;

  return (
    <>
      <Button
        id={idRef}
        aria-describedby={idRef}
        variant="contained"
        color="primary"
        onClick={(event) => clickCallback(event.currentTarget)}
        disabled={!!isNoRecords}
        sx={{
          background: 'transparent',
          boxShadow: 'none',
          minWidth: 'auto',
          padding: '0',
          float: 'right',
          '&:hover': {
            background: 'transparent',
          },
        }}
        data-testid={TEST_IDS.FILTER_POPOVER}
      >
        <AssetFilterSVG
          data-testid={`${TEST_IDS.DEMOS_TABLE_HEADER_FILTER_ICON}-${filtersByName}`}
        />
        {itemDropDownValues && itemDropDownValues.length > 0 && (
          <Box
            component="span"
            sx={
              itemDropDownValues.length > 99
                ? customStyles.selectedLenValLarge
                : customStyles.selectedLenVal
            }
          >
            {itemDropDownValues.length}
          </Box>
        )}
      </Button>
      <FilterPopover
        id={idRef}
        open={openBool}
        anchorEl={itemAnchorEl}
        onClose={() => clickCallback(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div>
          <DFilter
            filtersListObj={filterProps}
            filterOnChange={onChangeFunc}
            callBackClose={clickCallback}
            selectedDropDownValues={itemDropDownValues}
            maxChar={maxChar || 128}
            filtersByName={filtersByName}
          />
        </div>
      </FilterPopover>
    </>
  );
};

FilterPopOver.propTypes = {
  clickCallback: PropTypes.func,
  filterProps: PropTypes.shape(),
  onChangeFunc: PropTypes.func,
  itemDropDownValues: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
  ]),
  idRef: PropTypes.string,
  openBool: PropTypes.bool,
  itemAnchorEl: PropTypes.instanceOf(Element),
  maxChar: PropTypes.number,
  isNoRecords: PropTypes.bool,
  filtersByName: PropTypes.string,
};

export default FilterPopOver;
