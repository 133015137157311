import { EVENT_FORM_TYPES } from '../../constants';

const DEMO_ACTIONS = {
  SET_DEMO_DATA: 'SET_DEMO_DATA',
  SET_SELECTED_DEMO: 'SET_SELECTED_DEMO',
  SET_SELECTED_DEMO_PLANT: 'SET_SELECTED_DEMO_PLANT',
  SET_SELECTED_DEMO_DATA_FROM_API: 'SET_SELECTED_DEMO_DATA_FROM_API',
  EVENT_FORM_SHOW: 'EVENT_FORM_SHOW',
  SAVE_AS_FORM_SHOW: 'SAVE_AS_FORM_SHOW',
  ADD_DEMO_FORM_SHOW: 'ADD_DEMO_FORM_SHOW',
  CHNAGE_ADD_DEMO_FORM_VALUE: 'CHNAGE_ADD_DEMO_FORM_VALUE',
  SET_FORM_DIRTY_STATE: 'SET_FORM_DIRTY_STATE',
  SET_ERRORS: 'SET_ERRORS',
  SET_ADD_FORM_PLANTS_OPTIONS: 'SET_ADD_FORM_PLANTS_OPTIONS',
  RESET_FORM: 'RESET_FORM',
  SET_PLANT_HIERARCHY: 'SET_PLANT_HIERARCHY',
  UPDATE_PRIMARY_EVENT_FIELDS: 'UPDATE_PRIMARY_EVENT_FIELDS',
  UPDATE_SECONDARY_EVENT_FIELDS: 'UPDATE_SECONDARY_EVENT_FIELDS',
  SET_EVENT_FORM_FIELD_ERROR: 'SET_EVENT_FORM_FIELD_ERROR',
  RESET_EVENT_FORM_FIELDS: 'RESET_EVENT_FORM_FIELDS',
  RESET_SAVE_AS_FORM: 'RESET_SAVE_AS_FORM',
  SET_SAVE_AS_FORM_PLANTS_OPTIONS: 'SET_SAVE_AS_FORM_PLANTS_OPTIONS',
  SET_SAVE_AS_ERRORS: 'SET_SAVE_AS_ERRORS',
  CHANGE_SAVE_AS_DEMO_FORM_VALUE: 'CHANGE_SAVE_AS_DEMO_FORM_VALUE',
  INSERT_EVENT_INTO_STATE: 'INSERT_EVENT_INTO_STATE',
  DELETE_EVENT_FROM_STATE: 'DELETE_EVENT_FROM_STATE',
  AUTO_FILL_EVENT_FORM: 'AUTO_FILL_EVENT_FORM',
  SET_EVENTS_DIRTYNESS: 'SET_EVENTS_DIRTYNESS',
  UPDATE_SECONDARY_THRESHOLD_API: 'UPDATE_SECONDARY_THRESHOLD_API',
  DELETE_DEMO: 'DELETE_DEMO',
  SET_EVENTS: 'SET_EVENTS',
  SET_DEMO_START_CONFIRMER: 'SET_DEMO_START_CONFIRMER',
  DISCARD_EVENTS: 'DISCARD_EVENTS',
  SAVE_EVENTS: 'SAVE_EVENTS',
  UPDATE_SOC_EVENT_FIELDS: 'UPDATE_SOC_EVENT_FIELDS',
  UPDATE_RECIPE_SELECT: 'UPDATE_RECIPE_SELECT',
  SET_TRIGGER_SOC_TABLE: 'SET_TRIGGER_SOC_TABLE',
  UPDATE_TRIGGER_SOC_DATA: 'UPDATE_TRIGGER_SOC_DATA',
  SET_TABLE_OF_PARAMETER_ERROR: 'SET_TABLE_OF_PARAMETER_ERROR',
  UPDATE_EC_EVENT_FIELDS: 'UPDATE_EC_EVENT_FIELDS',
};

/**
 * @returns demo reducer's dispatch case
 */
const demoActions = {
  setApiData: (demoData) => ({
    type: DEMO_ACTIONS.SET_DEMO_DATA,
    payload: demoData,
  }),
  selectDemo: (demo) => ({
    type: DEMO_ACTIONS.SET_SELECTED_DEMO,
    payload: demo,
  }),
  /**
   * @param {object} plant - {plantId, plantName, options - optional}
   */
  selectDemoPlant: (plant) => ({
    type: DEMO_ACTIONS.SET_SELECTED_DEMO_PLANT,
    payload: plant,
  }),
  /**
   * @param {object} demoName - selected demo name to delete
   */
  deleteDemo: (demoName) => ({
    type: DEMO_ACTIONS.DELETE_DEMO,
    payload: demoName,
  }),
  /**
   * @param {object} demoData - selected demo data from API
   */
  selectedDemoDataFromAPI: (demoData) => ({
    type: DEMO_ACTIONS.SET_SELECTED_DEMO_DATA_FROM_API,
    payload: demoData,
  }),
  /**
   * @param {bool} show - whether to show or not
   * @param {string} type - type of the form
   */
  eventFormShow: (show, type = EVENT_FORM_TYPES.ADD) => ({
    type: DEMO_ACTIONS.EVENT_FORM_SHOW,
    payload: { show, type },
  }),
  eventSaveAsFormShow: (show) => ({
    type: DEMO_ACTIONS.SAVE_AS_FORM_SHOW,
    payload: show,
  }),
  addDemoFormShow: (show) => ({
    type: DEMO_ACTIONS.ADD_DEMO_FORM_SHOW,
    payload: show,
  }),
  changeAddDemoformValues: (field, value) => ({
    type: DEMO_ACTIONS.CHNAGE_ADD_DEMO_FORM_VALUE,
    payload: { field, value },
  }),
  setErrorValidation: (field, error, errorMessage) => ({
    type: DEMO_ACTIONS.SET_ERRORS,
    payload: { field, error, errorMessage },
  }),
  setFormDirtyState: (formName, value) => ({
    type: DEMO_ACTIONS.SET_FORM_DIRTY_STATE,
    payload: { formName, value },
  }),
  setAddDemoPlantsOptions: (data) => ({
    type: DEMO_ACTIONS.SET_ADD_FORM_PLANTS_OPTIONS,
    payload: data,
  }),
  resetForm: (formName) => ({
    type: DEMO_ACTIONS.RESET_FORM,
    payload: formName,
  }),
  setPlantHierarchy: (hierarchy) => ({
    type: DEMO_ACTIONS.SET_PLANT_HIERARCHY,
    payload: hierarchy,
  }),
  changeSaveAsDemoFormValues: (field, value) => ({
    type: DEMO_ACTIONS.CHANGE_SAVE_AS_DEMO_FORM_VALUE,
    payload: { field, value },
  }),
  setSaveAsFormErrorValidation: (field, error, errorMessage) => ({
    type: DEMO_ACTIONS.SET_SAVE_AS_ERRORS,
    payload: { field, error, errorMessage },
  }),
  resetSaveAsForm: (formName) => ({
    type: DEMO_ACTIONS.RESET_SAVE_AS_FORM,
    payload: formName,
  }),
  setSaveAsDemoPlantsOptions: (data) => ({
    type: DEMO_ACTIONS.SET_SAVE_AS_FORM_PLANTS_OPTIONS,
    payload: data,
  }),
  /**
   * @param {string} fieldKey - PRIMARY_INPUT_FIELDS key value
   * @param {string} fieldValue - typed/selected input field's value
   * @param {string} siblingFieldKey - (undefined | PRIMARY_INPUT_FIELDS) key value
   */
  updatePrimaryEventFields: (
    fieldKey,
    fieldValue,
    siblingFieldKey = undefined
  ) => ({
    type: DEMO_ACTIONS.UPDATE_PRIMARY_EVENT_FIELDS,
    payload: { fieldKey, fieldValue, siblingFieldKey },
  }),
  /**
   * @param {string} fieldKey - SECONDARY_INPUT_FIELDS key value
   * @param {string} fieldValue - typed/selected input field's value
   */
  updateSecondaryEventFields: (fieldKey, fieldValue) => ({
    type: DEMO_ACTIONS.UPDATE_SECONDARY_EVENT_FIELDS,
    payload: { fieldKey, fieldValue },
  }),
  /**
   *  @param {string} eventType - eventType key Value
   *  @param {json}  data for Max Theoretical For Factory Health Events
   */
  updateSocEventFields: (data) => ({
    type: DEMO_ACTIONS.UPDATE_SOC_EVENT_FIELDS,
    payload: data,
  }),
  updateRecipeSelect: (data) => ({
    type: DEMO_ACTIONS.UPDATE_RECIPE_SELECT,
    payload: data,
  }),
  updateSocTableData: (data) => ({
    type: DEMO_ACTIONS.SET_TRIGGER_SOC_TABLE,
    payload: data,
  }),
  setTableOfParameterError: (error) => ({
    type: DEMO_ACTIONS.SET_TABLE_OF_PARAMETER_ERROR,
    payload: { error },
  }),
  updateTriggerSOCValues: (data) => ({
    type: DEMO_ACTIONS.UPDATE_TRIGGER_SOC_DATA,
    payload: data,
  }),
  updateECEventFields: (data) => ({
    type: DEMO_ACTIONS.UPDATE_EC_EVENT_FIELDS,
    payload: data,
  }),
  updateSecondaryThresholdApi: (eventType, data) => ({
    type: DEMO_ACTIONS.UPDATE_SECONDARY_THRESHOLD_API,
    payload: { eventType, data },
  }),
  /**
   * @param {string} fieldPriority - FIELD_PRIORITY value
   * @param {string} fieldKey - (PRIMARY_INPUT_FIELDS | SECONDARY_INPUT_FIELDS) key value
   * @param {string} errorMessage - VALIDATION_MESSAGES value
   */
  setEventFormFieldError: (fieldPriority, fieldKey, errorMessage, error) => ({
    type: DEMO_ACTIONS.SET_EVENT_FORM_FIELD_ERROR,
    payload: { fieldPriority, fieldKey, errorMessage, error },
  }),
  /**
   * no params required currently to reset
   */
  resetEventFormFields: () => ({ type: DEMO_ACTIONS.RESET_EVENT_FORM_FIELDS }),
  /**
   * @param {boolean} isDirty - the events dirtyness flag
   */
  setEventsDirtyness: (isDirty) => ({
    type: DEMO_ACTIONS.SET_EVENTS_DIRTYNESS,
    payload: isDirty,
  }),
  /**
   * @param {string} validationSignal - VALIDATION_SIGNAL
   * @param {string} selectedItem - undefined | seleceted item id
   */
  insertEventIntoState: (validationSignal, selectedItem) => ({
    type: DEMO_ACTIONS.INSERT_EVENT_INTO_STATE,
    payload: { validationSignal, selectedItem },
  }),

  /**
   * delete event from state for the selected event
   * @returns
   */
  deleteEventFromState: (selectedItem) => ({
    type: DEMO_ACTIONS.DELETE_EVENT_FROM_STATE,
    payload: { selectedItem },
  }),

  /**
   * @param {array} splitedEntityId - selected entityId in pipe separated
   * @param {string} startTime - selected time in HH:MM formart
   * @param {array} eventData - undefined | event data [type, name, position] - as per state obj structure
   */
  autoFillEditEventForm: (
    splitedEntityId,
    startTime,
    eventData,
    selectedEventData
  ) => ({
    type: DEMO_ACTIONS.AUTO_FILL_EVENT_FORM,
    payload: { splitedEntityId, startTime, eventData, selectedEventData },
  }),
  /**
   * @param {number} plantIndex - selected plant index
   * @param {array} events - modified events of selected plant
   */
  setEvents: (plantIndex, events) => ({
    type: DEMO_ACTIONS.SET_EVENTS,
    payload: { plantIndex, events },
  }),

  /**
   * @param {string} demoName - confirmer for which demo show(value)/hide(null)
   */
  setDemoStartConfirmer: (demoName) => ({
    type: DEMO_ACTIONS.SET_DEMO_START_CONFIRMER,
    payload: demoName,
  }),

  discardEvents: () => ({
    type: DEMO_ACTIONS.DISCARD_EVENTS,
    payload: {},
  }),
  /**
   * @param {array} savedEvents - savedEvents
   */
  saveEvents: (savedEvents) => ({
    type: DEMO_ACTIONS.SAVE_EVENTS,
    payload: savedEvents,
  }),
};

export { DEMO_ACTIONS, demoActions };
