import {
  EVENT_TYPE_KEYS,
  EVENT_TYPE_OPTIONS,
  FIELD_PRIORITY,
  PRIMARY_INPUT_FIELDS,
} from '../../../constants';
import { useDemoContext } from '../../../contexts/demo/reducer';
import OeeFields from './oeeFields';
import FactoryHealthThresholdField from './factoryHealthThresholdField';
import TriggerSocField from './triggerSocField';
import FaultIncludedEC from './faultIncludedEC';

const SecondaryFields = () => {
  const { demoState } = useDemoContext();

  const { value: selectedEventName } =
    demoState.eventForm[FIELD_PRIORITY.PRIMARY][
      PRIMARY_INPUT_FIELDS.SELECT_EVENT.key
    ];
  const selectedEventType = EVENT_TYPE_OPTIONS[selectedEventName]?.eventType;
  /**
   ** OEE & FH & Trigger SOC EVENTS
   */
  if (selectedEventType === EVENT_TYPE_KEYS.OEE) {
    return <OeeFields />;
  }
  if (selectedEventType === EVENT_TYPE_KEYS.FH) {
    return <FactoryHealthThresholdField />;
  }
  if (selectedEventName === EVENT_TYPE_KEYS.TRIGGER_SOC_ASSET) {
    return <TriggerSocField />;
  }
  if (selectedEventType === EVENT_TYPE_KEYS.EC) {
    return <FaultIncludedEC />;
  }
  return null;
};

export default SecondaryFields;
