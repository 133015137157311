import { Backdrop } from '@mui/material';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import {
  AddDemoBox,
  FlexBox,
  ModalHeaderBox,
  HeadingTypo,
  OptionsBox,
} from './styledPeers';
import { InputComponent } from './inputComponent';
import TEST_IDS from '../../constants/testIds';
import Button from '../../components/Button';
import {
  BUTTON_LABELS,
  HEADING_LABELS,
  ADD_DEMO_INPUT_LABELS,
  QUERY_KEYS,
} from '../../constants';
import { useDemoContext } from '../../contexts/demo/reducer';
import { demoActions } from '../../contexts/demo/actions';
import { getAllConfiguredPlants } from '../../utils/apiHelpers';
import Loader from '../../components/Loader';

const AddDemo = ({ closer, onSave, showValidation, isCreatingDemo }) => {
  const { demoState, demoDispatch } = useDemoContext();

  // Queries
  const { isFetching: isPlantsFetching } = useQuery(
    [QUERY_KEYS.GET_DDS_PLANTS],
    () => getAllConfiguredPlants(),
    {
      onSuccess: (response) => {
        const plantOptions = response.plants.map((e) => ({
          key: e.plantId,
          name: e.plantName,
        }));
        demoDispatch(demoActions.setAddDemoPlantsOptions(plantOptions));
      },
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const inputChangeHandler = (event, field) => {
    demoDispatch(
      demoActions.changeAddDemoformValues(field, event.target.value)
    );
    showValidation(field, event.target.value);
  };

  return (
    <>
      {(isCreatingDemo || isPlantsFetching) && <Loader />}
      <Backdrop open sx={{ zIndex: (theme) => theme.zIndex.drawer - 0.1 }}>
        <AddDemoBox data-testid={TEST_IDS.ADD_DEMO_MODAL}>
          <ModalHeaderBox>
            <HeadingTypo>{HEADING_LABELS.ADD_DEMO}</HeadingTypo>
          </ModalHeaderBox>
          <OptionsBox>
            <FlexBox isOptionsRow>
              <InputComponent
                label={ADD_DEMO_INPUT_LABELS.DEMO_NAME}
                value={demoState.addDemoForm.primaryFields.demoName.value}
                placeholder={
                  demoState.addDemoForm.primaryFields.demoName.placeholder
                }
                isText
                onChange={(e) => inputChangeHandler(e, 'demoName')}
                validationCondition={
                  demoState.addDemoForm.primaryFields.demoName.error &&
                  demoState.addDemoForm.isDirty
                }
                validationMessage={
                  demoState.addDemoForm.primaryFields.demoName.errorMessage
                }
                datatestid={TEST_IDS.ADD_DEMO_DEMONAME_INPUT}
              />
              <FlexBox isOptionsGrow>
                <InputComponent
                  label={ADD_DEMO_INPUT_LABELS.DURATION}
                  value={demoState.addDemoForm.primaryFields.duration.value}
                  placeholder={
                    demoState.addDemoForm.primaryFields.duration.placeholder
                  }
                  isText
                  onChange={(e) => inputChangeHandler(e, 'duration')}
                  validationCondition={
                    demoState.addDemoForm.primaryFields.duration.error &&
                    demoState.addDemoForm.isDirty
                  }
                  validationMessage={
                    demoState.addDemoForm.primaryFields.duration.errorMessage
                  }
                  datatestid={TEST_IDS.ADD_DEMO_DURATION_INPUT}
                />
              </FlexBox>
              <InputComponent
                label={ADD_DEMO_INPUT_LABELS.PLANT}
                options={
                  demoState.addDemoForm.primaryFields.plants.options || []
                }
                value={demoState.addDemoForm.primaryFields.plants.value || ''}
                onChange={(e) => inputChangeHandler(e, 'plants')}
                validationCondition={
                  demoState.addDemoForm.primaryFields.plants.error &&
                  demoState.addDemoForm.isDirty
                }
                validationMessage={
                  demoState.addDemoForm.primaryFields.plants.errorMessage
                }
                datatestid={TEST_IDS.ADD_DEMO_PLANTS_INPUT}
                addDemoPlantSelect
              />
            </FlexBox>
          </OptionsBox>
          <FlexBox sx={{ justifyContent: 'flex-end', padding: '1.5rem' }}>
            <Button
              width="7.25rem"
              height="2.25rem"
              sx={{ marginLeft: '1rem' }}
              onClick={closer}
              data-testid={TEST_IDS.ADD_DEMO_CANCEL_BUTTON}
            >
              {BUTTON_LABELS.CANCEL}
            </Button>
            <Button
              isApply
              width="7.25rem"
              height="2.25rem"
              sx={{ marginLeft: '1rem' }}
              onClick={() => onSave({ demoName: 'New Demo', demoDuration: 15 })}
              data-testid={TEST_IDS.ADD_DEMO_SAVE_BUTTON}
            >
              {BUTTON_LABELS.SAVE}
            </Button>
          </FlexBox>
        </AddDemoBox>
      </Backdrop>
    </>
  );
};

AddDemo.propTypes = {
  closer: PropTypes.func,
  onSave: PropTypes.func,
  showValidation: PropTypes.func,
  isCreatingDemo: PropTypes.bool,
};

export default AddDemo;
