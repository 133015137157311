import PropTypes from 'prop-types';
import { ThresholdRow, ThresholdWrapperBox } from './styledPeers';
import { objValueMapper } from '../../../utils/helpers';

const FactoryHealthComponent = ({ thresholdData }) => {
  const thresholdRange = (lb, ub) => {
    const lowerBound = parseFloat(lb);
    const upperBound = ub.includes('+')
      ? `${parseFloat(ub.replace('+', ''))}+`
      : parseFloat(ub);
    return `${lowerBound} - ${upperBound}`;
  };
  return (
    <ThresholdWrapperBox>
      {thresholdData?.map((threshold) => (
        <ThresholdRow key={threshold.color}>
          <span>{objValueMapper(threshold.color)}</span>
          <span>
            {thresholdRange(threshold.lowerBound, threshold.upperBound)}
          </span>
        </ThresholdRow>
      ))}
    </ThresholdWrapperBox>
  );
};
export default FactoryHealthComponent;

FactoryHealthComponent.propTypes = {
  thresholdData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      color: PropTypes.string,
      lowerBound: PropTypes.number,
      upperBound: PropTypes.number,
    })
  ),
};
