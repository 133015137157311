import PropTypes from 'prop-types';
import Select from '../../../components/Select';
import TextField from '../../../components/TextField';
import {
  FIELD_PRIORITY,
  HEIRARCHY_INPUT_FIELDS,
  INPUT_TYPE,
  PRIMARY_INPUT_FIELDS,
} from '../../../constants';
import { demoActions } from '../../../contexts/demo/actions';
import { useDemoContext } from '../../../contexts/demo/reducer';
import { FlexBox } from '../styledPeers';
import { DividerLine, InputLabel } from './styledPeers';
import FieldsErrorIndicator from './fieldsErrorIndicator';

export const InputComponent = ({ inputField, siblingInputField }) => {
  const { demoState, demoDispatch } = useDemoContext();

  const label = [
    HEIRARCHY_INPUT_FIELDS.ZONE.key,
    HEIRARCHY_INPUT_FIELDS.CELL.key,
    HEIRARCHY_INPUT_FIELDS.ASSET.key,
  ].includes(inputField.key)
    ? `${inputField.name} (Optional)`
    : inputField.name;

  const { value, options, error, errorMessage } =
    demoState.eventForm[FIELD_PRIORITY.PRIMARY][inputField.key];

  const onChangeHandler = (e) => {
    demoDispatch(
      demoActions.updatePrimaryEventFields(
        inputField.key,
        e.target.value,
        siblingInputField?.key
      )
    );
  };
  return (
    <div
      style={{ width: 'min-content' }}
      data-testid={`event-field-${inputField.key}`}
    >
      <InputLabel>{label}</InputLabel>
      {inputField.type === INPUT_TYPE.TEXT_FIELD ? (
        <TextField value={value} onChange={onChangeHandler} error={error} />
      ) : (
        <Select
          disabled={inputField.key === PRIMARY_INPUT_FIELDS.PLANT.key}
          value={
            inputField.key === PRIMARY_INPUT_FIELDS.PLANT.key
              ? demoState?.selectedDemoPlant?.plantName
              : value
          }
          options={options}
          error={error}
          onChange={onChangeHandler}
        />
      )}
      {error && <FieldsErrorIndicator errorMessage={errorMessage} />}
    </div>
  );
};

const PrimaryFields = () => {
  return (
    <>
      <FlexBox isOptionsRow>
        <InputComponent inputField={PRIMARY_INPUT_FIELDS.PLANT} />
        <FlexBox isOptionsGrow>
          <InputComponent
            inputField={PRIMARY_INPUT_FIELDS.AREA}
            siblingInputField={PRIMARY_INPUT_FIELDS.LINE}
          />
        </FlexBox>
        <InputComponent
          inputField={PRIMARY_INPUT_FIELDS.LINE}
          siblingInputField={PRIMARY_INPUT_FIELDS.ZONE}
        />
      </FlexBox>
      <FlexBox isOptionsRow>
        <InputComponent
          inputField={PRIMARY_INPUT_FIELDS.ZONE}
          siblingInputField={PRIMARY_INPUT_FIELDS.CELL}
        />
        <FlexBox isOptionsGrow>
          <InputComponent
            inputField={PRIMARY_INPUT_FIELDS.CELL}
            siblingInputField={PRIMARY_INPUT_FIELDS.ASSET}
          />
        </FlexBox>
        <InputComponent inputField={PRIMARY_INPUT_FIELDS.ASSET} />
      </FlexBox>
      <DividerLine />
      <FlexBox isOptionsRow>
        <InputComponent inputField={PRIMARY_INPUT_FIELDS.SELECT_EVENT} />
        <FlexBox isOptionsGrow>
          <InputComponent inputField={PRIMARY_INPUT_FIELDS.START_TIME} />
        </FlexBox>
        <InputComponent inputField={PRIMARY_INPUT_FIELDS.DURATION} />
      </FlexBox>
    </>
  );
};

InputComponent.propTypes = {
  inputField: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  siblingInputField: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
  }),
};

export default PrimaryFields;
