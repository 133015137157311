import { Box, Divider, styled, Typography } from '@mui/material';

const Container = styled(Box)(({ theme }) => ({
  background: theme.palette.background.configLighterDark,
  color: 'white',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: '1rem',
  overflowY: 'auto',
}));

const Heading = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: '1.5rem',
  lineHeight: '2rem',
}));

const HeadingDivider = styled(Divider)(({ theme }) => ({
  border: `.0316rem solid ${theme.customColors.dividerGrey}`,
  margin: '.5rem 0',
}));

export { Container, Heading, HeadingDivider };
