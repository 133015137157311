import {
  EVENT_FORM,
  EVENT_FORM_TYPES,
  EVENT_TYPE_OPTIONS,
  FIELD_PRIORITY,
  PRIMARY_INPUT_FIELDS,
  VALIDATION_MESSAGES,
  SECONDARY_INPUT_FIELDS,
  EVENT_TYPE_KEYS,
  TABLE_OF_PARAMETER_CONSTANTS,
} from '../constants';
import { demoActions } from '../contexts/demo/actions';
import { useDemoContext } from '../contexts/demo/reducer';
import {
  getEventByPlant,
  getMissingProperty,
  isSlotsOverlapping,
  parseHHMMtoMins,
} from '../utils/helpers';

/**
 * @returns event form validation functions
 */
const useEventFormCheck = () => {
  const { demoState, demoDispatch } = useDemoContext();
  /**
   * @returns {boolean} whether its a edit form or not
   */
  const isEditForm = () => demoState.eventForm.type === EVENT_FORM_TYPES.EDIT;
  /**
   * @param {string} fieldPriority - FIELD_PRIORITY value
   * @param {string} fieldKey - PRIMARY_INPUT_FIELDS | SECONDARY_INPUT_FIELDS - key
   * @param {string} property - State field object's property
   *
   * @returns respective value
   */
  const getFieldInfo = ({ fieldPriority, fieldKey, property }) =>
    demoState.eventForm[fieldPriority][fieldKey][property];

  /**
   * @param {string} fieldPriority - FIELD_PRIORITY value
   * @param {string} fieldKey - PRIMARY_INPUT_FIELDS | SECONDARY_INPUT_FIELDS - key
   * @param {string} errorMessage - respective error message
   *
   * dispatch error setting action
   */
  const setFieldError = ({ fieldPriority, fieldKey, errorMessage }) => {
    demoDispatch(
      demoActions.setEventFormFieldError(fieldPriority, fieldKey, errorMessage)
    );
  };

  const checkField = {
    /**
     ** General checking which suits all
     */
    /**
     * @param {string} fieldPriority - FIELD_PRIORITY value
     * @param {string} fieldKey - PRIMARY_INPUT_FIELDS | SECONDARY_INPUT_FIELDS - key
     *
     * @return {boolean} is field already having error
     */
    isAlreadyInError: ({ fieldPriority, fieldKey }) => {
      return getFieldInfo({ fieldPriority, fieldKey, property: 'error' });
    },
    /**
     * @param {string} fieldPriority - FIELD_PRIORITY value
     * @param {string} fieldKey - PRIMARY_INPUT_FIELDS | SECONDARY_INPUT_FIELDS - key
     *
     * @return {boolean} is field empty
     */
    isEmpty: ({ fieldPriority, fieldKey }) => {
      if (!getFieldInfo({ fieldPriority, fieldKey, property: 'value' })) {
        setFieldError({
          fieldPriority,
          fieldKey,
          errorMessage: VALIDATION_MESSAGES.REQUIRED_FIELDS,
        });
        return true;
      }
      return false;
    },
    /**
     * @param {array} data - Table of Parameters List
     *
     * @return {boolean} is field doesn't have one row filled
     */
    tableOfParameterError: (data) => {
      const requireValidation = data.map((d) => {
        if (
          d.sv.value === '' ||
          d.pv.value === '' ||
          d.riskLevel.value === ''
        ) {
          return true;
        }
        return false;
      });
      const checker = requireValidation.filter((value) => value === false);
      if (checker.length === 0) {
        demoDispatch(
          demoActions.setTableOfParameterError(
            TABLE_OF_PARAMETER_CONSTANTS.ERRORS_VALIDATION.ATLEAST_ONE_ROW
          )
        );
        return true;
      }
      demoDispatch(demoActions.setTableOfParameterError(''));
      return false;
    },
    /**
     * @param {array} data - Table of Parameters List
     *
     * @return {boolean} is field having numerical value error
     */
    tableOfParameterNumericError: (tableData) => {
      const check = tableData.filter(
        (data) => data.pv.error || data.sv.error || data.riskLevel.error
      );
      return check.length > 0;
    },
    /**
     * @param {array} data - Table of Parameters List
     *
     * @return {boolean} is field have unfilled value
     */
    tableOfParameterRowError: (tableData) => {
      const errorChecker = tableData.map((data) => {
        const allFilled =
          data.pv.value !== '' &&
          data.sv.value !== '' &&
          data.riskLevel.value !== '';
        if (
          (data.pv.value !== '' ||
            data.sv.value !== '' ||
            data.riskLevel.value !== '') &&
          !allFilled
        ) {
          if (data.pv.value === '') {
            demoDispatch(
              demoActions.updateTriggerSOCValues({
                parameter: data.parameterName,
                fieldKey: EVENT_TYPE_KEYS.PV,
                error: true,
                errorMessage:
                  TABLE_OF_PARAMETER_CONSTANTS.ERRORS_VALIDATION
                    .VALUE_HEIGHLIGHTED_FIELD,
                value: data.pv.value,
              })
            );
          }
          if (data.sv.value === '') {
            demoDispatch(
              demoActions.updateTriggerSOCValues({
                parameter: data.parameterName,
                fieldKey: EVENT_TYPE_KEYS.SV,
                error: true,
                errorMessage:
                  TABLE_OF_PARAMETER_CONSTANTS.ERRORS_VALIDATION
                    .VALUE_HEIGHLIGHTED_FIELD,
                value: data.sv.value,
              })
            );
          }
          if (data.riskLevel.value === '') {
            demoDispatch(
              demoActions.updateTriggerSOCValues({
                parameter: data.parameterName,
                fieldKey: EVENT_TYPE_KEYS.RISK_LEVEL,
                error: true,
                errorMessage:
                  TABLE_OF_PARAMETER_CONSTANTS.ERRORS_VALIDATION
                    .VALUE_HEIGHLIGHTED_FIELD,
                value: data.riskLevel.value,
              })
            );
          }
          return true;
        }
        return false;
      });
      return (
        errorChecker.includes(true) ||
        checkField.tableOfParameterNumericError(tableData)
      );
    },

    /**
     * @param {string} fieldPriority - FIELD_PRIORITY value
     * @param {string} fieldKey - PRIMARY_INPUT_FIELDS | SECONDARY_INPUT_FIELDS - key
     * @param {string} regexExp - REGEX expression
     * @param {string} regexErrMsg - REGEX respective error message
     *
     * @return {boolean} is field empty
     */
    format: ({ fieldPriority, fieldKey, regexExp, regexErrMsg }) => {
      const fieldValue = getFieldInfo({
        fieldPriority,
        fieldKey,
        property: 'value',
      });
      if (
        fieldKey === EVENT_TYPE_KEYS.QUALITY ||
        fieldKey === EVENT_TYPE_KEYS.PERFORMANCE ||
        fieldKey === EVENT_TYPE_KEYS.AVAILABILITY
      ) {
        if (fieldValue[0] === '-' && !/[^0-9]/.test(fieldValue.slice(1))) {
          // value is numerical but a negative number, so proceed to regex validation
        } else if (/[^0-9]/.test(fieldValue)) {
          // value is not numerical
          setFieldError({
            fieldPriority,
            fieldKey,
            errorMessage: EVENT_FORM.ERROR_MESSAGE.NON_NUMERICAL_VALUE,
          });
          return true;
        }
      }
      if (!regexExp.test(fieldValue)) {
        setFieldError({ fieldPriority, fieldKey, errorMessage: regexErrMsg });
        return true;
      }
      return false;
    },
    /**
     * @param {string} fieldPriority - FIELD_PRIORITY value
     * @param {string} fieldKey - PRIMARY_INPUT_FIELDS | SECONDARY_INPUT_FIELDS - key
     * @param {string} regexExp - REGEX expression
     * @param {string} regexErrMsg - REGEX respective error message
     * @param {array} addOns - keys to test more than basic ['isEmpty', 'format']
     *
     * do all the basic check metioned
     */
    doMultipleChecks: ({
      fieldPriority,
      fieldKey,
      regexExp,
      regexErrMsg,
      addOns,
    }) => {
      const checksList = ['isEmpty', 'format'];
      if (addOns) checksList.push(...addOns);

      const checksListSize = checksList.length;
      let checksListPointer = 0;
      let continueCheck = true;

      while (continueCheck) {
        if (checkField.isAlreadyInError({ fieldPriority, fieldKey })) {
          continueCheck = false;
          break;
        }

        const checkResult = checkField[checksList[checksListPointer]]({
          fieldPriority,
          fieldKey,
          regexExp,
          regexErrMsg,
        });

        checksListPointer += 1;
        continueCheck = checksListPointer < checksListSize && !checkResult;

        if (checksListPointer === checksListSize && checkResult)
          checksListPointer += 1;
      }

      return checksListPointer !== checksListSize;
    },
    /**
     * Checking Theoretical Max Value For Throughput and WIP Only
     */
    checkTheoreticalMaxValue: (
      fieldPriority,
      fieldKey,
      selectedEventName,
      regexExp,
      regexErrMsg
    ) => {
      const inputValue = getFieldInfo({
        fieldPriority: FIELD_PRIORITY.SECONDARY,
        fieldKey: SECONDARY_INPUT_FIELDS[selectedEventName].key,
        property: 'value',
      });
      // Check if Positive Number Value Only
      if (
        checkField.format({ fieldPriority, fieldKey, regexExp, regexErrMsg })
      ) {
        return true;
      }
      // Check if Empty
      if (checkField.isEmpty({ fieldPriority, fieldKey })) {
        return true;
      }
      if (selectedEventName === EVENT_TYPE_KEYS.THROUGHPUT) {
        const maxValue = getFieldInfo({
          fieldPriority: FIELD_PRIORITY.SECONDARY,
          fieldKey: SECONDARY_INPUT_FIELDS[selectedEventName].key,
          property: 'maxTheoretical',
        });
        if (inputValue > maxValue.value) {
          setFieldError({
            fieldPriority,
            fieldKey,
            errorMessage: EVENT_FORM.ERROR_MESSAGE.THRESHOLD_MAX_VALUE,
          });
          return true;
        }
      }
      if (selectedEventName === EVENT_TYPE_KEYS.WIP) {
        const maxValue = 100000;

        if (inputValue > maxValue) {
          setFieldError({
            fieldPriority,
            fieldKey,
            errorMessage: EVENT_FORM.ERROR_MESSAGE.WIP_MAX_VALUE,
          });
          return true;
        }
      }
      return false;
    },
    /**
     ** Specific Checking for the fields
     *
     ** START_TIME
     */
    /**
     * check if start time exceeding demo total durations
     */
    start_time_exceeding_demo_duration: () => {
      const fieldPriority = FIELD_PRIORITY.PRIMARY;
      const fieldKey = PRIMARY_INPUT_FIELDS.START_TIME.key;

      const demoDuration = demoState.selectedDemoDataFromAPI.duration;
      const startTimeinMins = parseHHMMtoMins(
        getFieldInfo({ fieldPriority, fieldKey, property: 'value' })
      );

      if (startTimeinMins >= demoDuration) {
        setFieldError({
          fieldPriority,
          fieldKey,
          errorMessage:
            EVENT_FORM.ERROR_MESSAGE.START_TIME_EXCEEDING_DEMO_DEURATION,
        });
        return true;
      }
      return false;
    },
    /**
     ** DUARATION
     */
    /**
     * check duration addition to startTime exceeding demo duration
     */
    duration_exceeding_demo_duration: () => {
      const fieldPriority = FIELD_PRIORITY.PRIMARY;
      const fieldKey = PRIMARY_INPUT_FIELDS.DURATION.key;

      const demoDuration = demoState.selectedDemoDataFromAPI.duration;
      const startTimeinMins = parseHHMMtoMins(
        getFieldInfo({
          fieldPriority,
          fieldKey: PRIMARY_INPUT_FIELDS.START_TIME.key,
          property: 'value',
        })
      );
      const duration = getFieldInfo({
        fieldPriority,
        fieldKey,
        property: 'value',
      });

      if (startTimeinMins + parseInt(duration, 10) > demoDuration) {
        setFieldError({
          fieldPriority,
          fieldKey,
          errorMessage:
            EVENT_FORM.ERROR_MESSAGE.DURATION_EXCEEDING_DEMO_DURATION,
        });
        return true;
      }
      return false;
    },
    /**
     ** Time Slot Overlapping check
     *
     * @returns {string} VALIDATION_SIGNAL
     *
     * if no event present for the entity return NO_MATCH signal
     *
     * if no overlapping then return accurate position to fit in Integer
     * else return ERROR signal
     */
    time_slot_overlapping: (selectedItem) => {
      const fieldPriority = FIELD_PRIORITY.PRIMARY;
      /**
       * if already other errors existing return back
       */
      if (
        checkField.isAlreadyInError({
          fieldPriority,
          fieldKey: PRIMARY_INPUT_FIELDS.SELECT_EVENT.key,
        }) ||
        checkField.isAlreadyInError({
          fieldPriority,
          fieldKey: PRIMARY_INPUT_FIELDS.START_TIME.key,
        }) ||
        checkField.isAlreadyInError({
          fieldPriority,
          fieldKey: PRIMARY_INPUT_FIELDS.DURATION.key,
        })
      )
        return EVENT_FORM.VALIDATION_SIGNAL.ERROR;

      const eventName = getFieldInfo({
        fieldPriority,
        fieldKey: PRIMARY_INPUT_FIELDS.SELECT_EVENT.key,
        property: 'value',
      });
      const startTime = parseHHMMtoMins(
        getFieldInfo({
          fieldPriority,
          fieldKey: PRIMARY_INPUT_FIELDS.START_TIME.key,
          property: 'value',
        })
      );
      const duration = parseInt(
        getFieldInfo({
          fieldPriority,
          fieldKey: PRIMARY_INPUT_FIELDS.DURATION.key,
          property: 'value',
        }),
        10
      );

      const eventNameDefault = getFieldInfo({
        fieldPriority,
        fieldKey: PRIMARY_INPUT_FIELDS.SELECT_EVENT.key,
        property: 'defaultValue',
      });
      const startTimeDefault = parseHHMMtoMins(
        getFieldInfo({
          fieldPriority,
          fieldKey: PRIMARY_INPUT_FIELDS.START_TIME.key,
          property: 'defaultValue',
        })
      );
      const durationDefault = parseInt(
        getFieldInfo({
          fieldPriority,
          fieldKey: PRIMARY_INPUT_FIELDS.DURATION.key,
          property: 'defaultValue',
        }),
        10
      );

      const [eventType, hierarchyType] = [
        EVENT_TYPE_OPTIONS[eventName]?.eventType,
        EVENT_TYPE_OPTIONS[eventName]?.hierarchyType,
      ];

      const events = getEventByPlant(demoState);

      const entityId = getFieldInfo({
        fieldPriority,
        fieldKey: hierarchyType,
        property: 'value',
      });
      /**
       * if Edit Form values never changed
       */
      const isEventNameSame = eventName === eventNameDefault;
      const isStartTimeSame = startTime === startTimeDefault;
      const isDurationSame = duration === durationDefault;

      let isEntityIdSame = false;

      if (isEditForm()) {
        const splitedEntityId = selectedItem.split('|');
        const eventData = splitedEntityId.pop().split('-');
        splitedEntityId.push(eventData.shift());
        isEntityIdSame = entityId === splitedEntityId.join('|');
      }

      const isSameEventofEntity = isEntityIdSame && isEventNameSame;

      if (
        isEditForm() &&
        isSameEventofEntity &&
        isStartTimeSame &&
        isDurationSame
      )
        return EVENT_FORM.VALIDATION_SIGNAL.ONLY_CHANGE_EVENT_VALUE;

      /**
       * check whether the event for enitity already present
       * if not send back signal to create
       */
      const missingProperty = getMissingProperty(demoState);

      /**
       * if its a new event for that slot return
       */
      if (missingProperty !== EVENT_FORM.VALIDATION_SIGNAL.OK)
        return missingProperty;

      const endTime = startTime + duration;

      const allSlots = events[entityId][eventType][eventName] ?? [];

      /**
       * if Edit Form value changed, then remove the default value of it in checking array
       */
      if (isEditForm()) {
        const endTimeDefault = startTimeDefault + durationDefault;
        const editSlotsIdx = allSlots.findIndex(
          (slot) =>
            slot.startmin === startTimeDefault && slot.endmin === endTimeDefault
        );
        /**
         * same time slot & same event means exclude that to overlap checking to override it
         * else leave it check for overlap
         */
        if (editSlotsIdx >= 0 && isSameEventofEntity)
          allSlots.splice(editSlotsIdx, 1);
      }

      let idx = 0;
      /**
       * iterate through all slots
       */

      const selectedEvent =
        demoState.eventForm.PrimaryFields.SELECT_EVENT.value;
      while (idx < allSlots.length) {
        const { startmin, endmin } = allSlots[idx];
        /**
         * if event overlap happening return back
         */
        if (isSlotsOverlapping(startTime, endTime, startmin, endmin)) {
          setFieldError({
            fieldPriority: FIELD_PRIORITY.PRIMARY,
            fieldKey: PRIMARY_INPUT_FIELDS.DURATION.key,
            errorMessage:
              selectedEvent !== EVENT_TYPE_KEYS.FAULT_CODES
                ? EVENT_FORM.ERROR_MESSAGE.EVENTS_OVERLAPPING
                : EVENT_FORM.ERROR_MESSAGE.FAULT_CODE_ALREADY_SELECTED,
          });
          return EVENT_FORM.VALIDATION_SIGNAL.ERROR;
        }
        idx += 1;
      }

      return EVENT_FORM.VALIDATION_SIGNAL.OK;
    },
    /**
     * @param {string} selectedItem - selected items id
     *
     * while editing if event switched to new slot then remove the old one
     */
    needDeletion: (selectedItem) => {
      if (!selectedItem) return false;

      const fieldPriority = FIELD_PRIORITY.PRIMARY;

      const eventName = getFieldInfo({
        fieldPriority,
        fieldKey: PRIMARY_INPUT_FIELDS.SELECT_EVENT.key,
        property: 'value',
      });
      const eventNameDefault = getFieldInfo({
        fieldPriority,
        fieldKey: PRIMARY_INPUT_FIELDS.SELECT_EVENT.key,
        property: 'defaultValue',
      });

      const hierarchyType = EVENT_TYPE_OPTIONS[eventName]?.hierarchyType;

      const entityId = getFieldInfo({
        fieldPriority,
        fieldKey: hierarchyType,
        property: 'value',
      });
      const entityIdDefault = getFieldInfo({
        fieldPriority,
        fieldKey: hierarchyType,
        property: 'defaultValue',
      });

      if (eventName !== eventNameDefault || entityId !== entityIdDefault) {
        demoDispatch(demoActions.deleteEventFromState(selectedItem));
      }

      return false;
    },
  };

  return { checkField, getFieldInfo };
};

export default useEventFormCheck;
