import { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { styled, Tooltip } from '@mui/material';
import RowActions from './rowActions';
import { BodyRowGrid, GridItem, TableContentLabel } from './styledPeers';
import TEST_IDS from '../../constants/testIds';

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  zIndex: theme.zIndex.tooltip + 1,
  '& .MuiTooltip-tooltip': {
    maxHeight: '2.5rem',
    maxWidth: '350px',
    border: `1px solid ${theme.customColors.wrapperGrey}`,
    backgroundColor: theme.customColors.dullDark,
    padding: 6,
  },
}));

const TableRow = ({ demo, pos }) => {
  const [showActions, setShowActions] = useState(false);
  return (
    <BodyRowGrid
      container
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}
      data-testid={`${TEST_IDS.DEMOS_TABLE_BODY_ROW_CONTAINER}-${pos}`}
    >
      <GridItem xs={0.1} />
      <GridItem xs={2}>
        <StyledTooltip
          title={`${demo.demoName}`}
          placement="bottom-start"
          disableHoverListener={demo.demoName.length < 21}
        >
          <TableContentLabel
            sx={
              demo.demoName.length >= 21
                ? {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: '80%',
                  }
                : {
                    whiteSpace: 'wrap',
                    wordBreak: 'break-all',
                  }
            }
          >
            {demo.demoName}
          </TableContentLabel>
        </StyledTooltip>
      </GridItem>
      <GridItem xs={1.7}>
        <TableContentLabel>{demo.duration} mins</TableContentLabel>
      </GridItem>
      <GridItem xs={1.5}>
        <TableContentLabel>
          {moment(demo.createdAt).format('MM/DD/YYYY')}
        </TableContentLabel>
      </GridItem>
      <GridItem xs={2}>
        <TableContentLabel>{demo.createdBy}</TableContentLabel>
      </GridItem>
      <GridItem style={{ width: '15.65rem' }}>
        <StyledTooltip
          title={`${demo.plantNames.join(',')}`}
          placement="bottom-start"
          disableHoverListener={demo.plantNames.join(',').length < 41}
        >
          <TableContentLabel
            sx={
              demo.plantNames.join(',').length >= 41
                ? {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: '15.65rem',
                  }
                : {
                    whiteSpace: 'wrap',
                    wordBreak: 'break-all',
                  }
            }
          >
            {demo.plantNames.join(',')}
          </TableContentLabel>
        </StyledTooltip>
      </GridItem>
      <GridItem xs={2}>
        <RowActions showActions={showActions} demo={demo} />
      </GridItem>
    </BodyRowGrid>
  );
};
TableRow.propTypes = {
  demo: PropTypes.shape({
    demoName: PropTypes.string,
    duration: PropTypes.number,
    createdAt: PropTypes.number,
    createdBy: PropTypes.string,
    plantNames: PropTypes.shape([]),
  }),
  pos: PropTypes.number,
};

export default TableRow;
