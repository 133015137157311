import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useDemoContext } from '../../../contexts/demo/reducer';
import { demoActions } from '../../../contexts/demo/actions';
import { useToastContext } from '../../../contexts/toastContext';
import { InputLabel } from './styledPeers';

import {
  EVENT_TYPE_KEYS,
  FIELD_PRIORITY,
  PRIMARY_INPUT_FIELDS,
  TOAST_REDUCER_CONSTANTS,
  MESSAGE_STRINGS,
  SECONDARY_STATIC_FIELDS,
  EVENT_TYPE_OPTIONS,
} from '../../../constants';

import Select from '../../../components/Select';
import Loader from '../../../components/Loader'; // this is for loading the fault code options
import FieldsErrorIndicator from './fieldsErrorIndicator';
import { getFaultCodeList } from '../../../utils/apiHelpers';
import { getEntityIdOfSelectedPlantHierarchy } from '../../../utils/helpers';

const FaultIncludedEC = () => {
  const { demoState, demoDispatch } = useDemoContext();
  const { toastDispatch } = useToastContext();

  const { options, error, value, errorMessage } =
    demoState.eventForm[FIELD_PRIORITY.SECONDARY][EVENT_TYPE_KEYS.FAULT_CODES];
  const { value: eventName } =
    demoState.eventForm[FIELD_PRIORITY.PRIMARY][
      PRIMARY_INPUT_FIELDS.SELECT_EVENT.key
    ];
  const [hierarchyType] = [EVENT_TYPE_OPTIONS[eventName].hierarchyType];
  const { value: entityHierarchy } =
    demoState.eventForm[FIELD_PRIORITY.PRIMARY][hierarchyType];
  const { selectedPlantHierarchy } = demoState;

  const entityId = getEntityIdOfSelectedPlantHierarchy(
    selectedPlantHierarchy,
    entityHierarchy
  );
  const { isFetching: faultCodeFetching, isError: faultCodesError } = useQuery({
    queryKey: [eventName],
    queryFn: async () => getFaultCodeList(entityId),
    onSuccess: (response) => {
      if (eventName === EVENT_TYPE_KEYS.FAULT_CODES) {
        demoDispatch(demoActions.updateECEventFields(response));
      }
    },
    onError: () => {
      toastDispatch({
        type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
        payload: {
          message: MESSAGE_STRINGS['Toast.message.FAULTCODEERROR'],
        },
      });
    },
    enabled: !!eventName,
    retry: false,
    refetchOnWindowFocus: false,
  });

  if (faultCodesError) {
    return null;
  }

  if (faultCodeFetching) {
    return <Loader />;
  }

  const onChangeHandler = (e, fieldKey) => {
    demoDispatch(
      demoActions.updateSecondaryEventFields(fieldKey, e.target.value)
    );
  };

  return (
    <>
      <div style={{ width: 'min-content' }} data-testid="fault-ec-select">
        <InputLabel>{SECONDARY_STATIC_FIELDS.EC_FAULT_CODES}</InputLabel>
        <Select
          options={options}
          error={error}
          onChange={(e) => onChangeHandler(e, EVENT_TYPE_KEYS.FAULT_CODES)}
          value={value}
        />
      </div>
      {error && <FieldsErrorIndicator errorMessage={errorMessage} />}
    </>
  );
};

export default FaultIncludedEC;
