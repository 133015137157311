const MESSAGE_STRINGS = {
  /**
   ** COMPONENTS
   *
   ** TOAST
   */
  'Toast.message.SUCCESSFUL_CHANGE':
    'A new demo with changes has been created!',
  'Toast.message.SUCCESS': 'Your changes were saved Successfully!',
  'Toast.message.ERROR': 'Oops, something went wrong. Please try again later!',
  'Toast.message.WARNING': 'Oops, something went wrong!',
  'Toast.message.INFO': 'Please wait',
  /**
   ** COMPONENTS
   *
   ** Popover
   */
  'Data.not.available': 'No Data Available',

  /**
   ** COMPONENTS
   *
   ** Notification Modal
   */
  'NotificationModal.title': 'Confirm!',
  'NotificationModal.button.save': 'Save',
  'NotificationModal.button.discard': 'Discard',
  'NotificationModal.customText': 'You have unsaved changes.',
  'NotificationModal.noteText': 'Do you want to save or discard your changes?',
};

export default MESSAGE_STRINGS;
