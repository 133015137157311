/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, ButtonBase } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import SaveAsDemo from './saveAsDemo';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import Select from '../../components/Select';
import {
  BUTTON_LABELS,
  EVENT_FORM_TYPES,
  QUERY_KEYS,
  ADD_DEMO_FIELDS,
  ADD_DEMO_VALIDATIONS_CONSTANTS,
  VALIDATION_MESSAGES,
  TOAST_REDUCER_CONSTANTS,
  MODAL_TYPES,
  NOTIFICATION_LABEL,
} from '../../constants';
import TEST_IDS from '../../constants/testIds';
import { demoActions } from '../../contexts/demo/actions';
import { useDemoContext } from '../../contexts/demo/reducer';
import { saveAsDemo, saveAllEvents } from '../../utils/apiHelpers';
import { useToastContext } from '../../contexts/toastContext';
import EventForm from './Event/form';
import { BreadCrumbsTypo, FlexBox, HeaderButtonTypo } from './styledPeers';
import NotificationModal from '../../components/NotificationModal';
import MESSAGE_STRINGS from '../../constants/en-us';
import { useRxjsState } from '../../utils/useRxjsState';
import {
  updateRxjsState,
  updateDDSEventState,
} from '../../utils/rxjsStateNext';

const EditorHeader = ({ selectedItem }) => {
  const queryClient = useQueryClient();
  const [showUnsavedEditsModal, setShowUnsavedEditsModal] = useState(false);
  const { demoState, demoDispatch } = useDemoContext();
  const { toastDispatch } = useToastContext();
  const [deleteEventShow, setDeleteEventShow] = useState(false);
  const { rxjsState } = useRxjsState();

  const goToDemosList = () => {
    const ddsNavigateAwayConfiguration = rxjsState?.ddsNavigateAwayConfig;

    if (ddsNavigateAwayConfiguration?.isUnsavedChange) {
      updateDDSEventState(
        { isUserNavigating: true, location: '/demo_data_simulator' },
        { isUnsavedChange: true }
      );
    } else {
      demoDispatch(demoActions.selectDemo(null));
      demoDispatch(demoActions.selectedDemoDataFromAPI(null));
      demoDispatch(demoActions.setPlantHierarchy(null));
      demoDispatch(
        demoActions.selectDemoPlant({ plantId: '', plantName: '', options: [] })
      );
      queryClient.invalidateQueries([QUERY_KEYS.GET_DEMO_LIST]);
    }
  };
  const eventFormShowHandler = () => {
    demoDispatch(demoActions.eventFormShow(true, EVENT_FORM_TYPES.ADD));
  };

  const isDuplicateDemoName = (newDemoName) => {
    const result = demoState.demos.find(
      (e) => e.demoName.toLowerCase() === newDemoName.toLowerCase()
    );
    if (result) return true;
    return false;
  };

  const showValidation = (field, value) => {
    if (ADD_DEMO_FIELDS.DEMO_NAME === field) {
      if (value.length === 0) {
        demoDispatch(
          demoActions.setSaveAsFormErrorValidation(
            field,
            true,
            VALIDATION_MESSAGES.REQUIRED_FIELDS
          )
        );
      } else if (value.indexOf(' ') >= 0) {
        demoDispatch(
          demoActions.setSaveAsFormErrorValidation(
            field,
            true,
            VALIDATION_MESSAGES.REMOVE_SPACES
          )
        );
      } else if (!ADD_DEMO_VALIDATIONS_CONSTANTS.DEMO_NAME_REGEX.test(value)) {
        demoDispatch(
          demoActions.setSaveAsFormErrorValidation(
            field,
            true,
            VALIDATION_MESSAGES.INVALID
          )
        );
      } else if (isDuplicateDemoName(value)) {
        demoDispatch(
          demoActions.setSaveAsFormErrorValidation(
            field,
            true,
            VALIDATION_MESSAGES.UNIQUE_DEMONAME_VAIDATION
          )
        );
      } else {
        demoDispatch(
          demoActions.setSaveAsFormErrorValidation(field, false, '')
        );
      }
    } else if (ADD_DEMO_FIELDS.DURATION === field) {
      if (value.length === 0) {
        demoDispatch(
          demoActions.setSaveAsFormErrorValidation(
            field,
            true,
            VALIDATION_MESSAGES.REQUIRED_FIELDS
          )
        );
      } else if (
        ADD_DEMO_VALIDATIONS_CONSTANTS.REGEX_ALPHABET_SPECIAL_CHARS.test(value)
      ) {
        demoDispatch(
          demoActions.setSaveAsFormErrorValidation(
            field,
            true,
            VALIDATION_MESSAGES.DUARTION_NUMERICAL_VALIDATION
          )
        );
      } else if (value < ADD_DEMO_VALIDATIONS_CONSTANTS.DURATION_MIN_MINUTE) {
        demoDispatch(
          demoActions.setSaveAsFormErrorValidation(
            field,
            true,
            VALIDATION_MESSAGES.DURATION_MIN_VALIDATION
          )
        );
      } else if (value > ADD_DEMO_VALIDATIONS_CONSTANTS.DURATION_MAX_MINUTE) {
        demoDispatch(
          demoActions.setSaveAsFormErrorValidation(
            field,
            true,
            VALIDATION_MESSAGES.DURATION_MAX_VALIDATION
          )
        );
      } else {
        demoDispatch(
          demoActions.setSaveAsFormErrorValidation(field, false, '')
        );
      }
    } else if (ADD_DEMO_FIELDS.PLANTS === field) {
      if (value.length === 0) {
        demoDispatch(
          demoActions.setSaveAsFormErrorValidation(
            field,
            true,
            VALIDATION_MESSAGES.REQUIRED_FIELDS
          )
        );
      } else if (
        value.length > ADD_DEMO_VALIDATIONS_CONSTANTS.PLANTS_MAX_COUNT
      ) {
        demoDispatch(
          demoActions.setSaveAsFormErrorValidation(
            field,
            true,
            VALIDATION_MESSAGES.EXCEED_PLANT_MAX_COUNT
          )
        );
      } else {
        demoDispatch(
          demoActions.setSaveAsFormErrorValidation(field, false, '')
        );
      }
    }
  };

  const { mutate: saveAsDemoMutation, isLoading: isCreatingDemo } = useMutation(
    (data) => saveAsDemo(data),
    {
      onSuccess: () => {
        demoDispatch(demoActions.eventSaveAsFormShow(false)); // Closing Modal
        demoDispatch(demoActions.resetSaveAsForm('saveAsForm')); // Reset Form
        goToDemosList();
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
          payload: {
            message: MESSAGE_STRINGS['Toast.message.SUCCESSFUL_CHANGE'],
          },
        });
      },
      onError: () => {
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
          payload: {
            message: MESSAGE_STRINGS['Toast.message.ERROR'],
          },
        });
      },
    }
  );

  const onSave = (duration, selectedPlant) => {
    const isError =
      demoState.saveAsForm.primaryFields.demoName.error ||
      demoState.saveAsForm.primaryFields.duration.error ||
      demoState.saveAsForm.primaryFields.plants.error;
    if (isError) {
      demoDispatch(demoActions.setFormDirtyState('saveAsForm', true));
    } else {
      const saveDetails = {
        existingDemoName: demoState.selectedDemo.demoName,
        newDemoName: demoState.saveAsForm.primaryFields.demoName.value,
        existingDemoDuration: demoState.selectedDemoDataFromAPI.duration,
        newDemoDuration: parseInt(duration, 10),
        plantIds: selectedPlant.map((e) => e.key),
      };
      saveAsDemoMutation(saveDetails);
    }
  };

  const eventSaveAsHandler = (status) => {
    if (demoState.isEventsDirty) {
      setShowUnsavedEditsModal(true);
    } else {
      setShowUnsavedEditsModal(false);
      demoDispatch(demoActions.eventSaveAsFormShow(status));
      if (!status) demoDispatch(demoActions.resetSaveAsForm('saveAsForm')); // Reset Form
    }
  };

  const plantChangeHandler = (e) => {
    demoDispatch(
      demoActions.selectDemoPlant({
        plantId: e.target.value,
        plantName: demoState.selectedDemoPlant.options.filter(
          (o) => o.value === e.target.value
        )[0].label,
      })
    );
    demoDispatch(demoActions.resetEventFormFields());
  };

  const { mutate: saveAllEventsFn, isLoading: isSavingAllEvents } = useMutation(
    {
      mutationFn: (data) => saveAllEvents(data),
      onSuccess: (response) => {
        demoDispatch(demoActions.setEventsDirtyness(false));
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
          payload: {
            message: response.message,
          },
        });
      },
      onError: (error) => {
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
          payload: {
            message: error.message ?? MESSAGE_STRINGS['Toast.message.WARNING'],
          },
        });
      },
    }
  );

  const saveHandler = () => {
    if (demoState.isEventsDirty) {
      setShowUnsavedEditsModal(false);
      demoDispatch(demoActions.setEventsDirtyness(false));
      saveAllEventsFn(demoState.selectedDemoDataFromAPI);
      demoDispatch(demoActions.saveEvents(demoState.selectedDemoDataFromAPI));
    }
    const ddsLogoutConfiguration = rxjsState?.ddsLogoutConfig;
    updateRxjsState({
      ddsLogoutConfig: {
        ...ddsLogoutConfiguration,
        areAnyUnsavedChanges: false,
      },
    });
    const ddsNavigateAwayConfiguration = rxjsState?.ddsNavigateAwayConfig;
    updateRxjsState({
      ddsNavigateAwayConfig: {
        ...ddsNavigateAwayConfiguration,
        isUnsavedChange: false,
      },
    });
  };

  const discardHandler = () => {
    if (demoState.isEventsDirty) {
      setShowUnsavedEditsModal(false);
      demoDispatch(demoActions.setEventsDirtyness(false));
      demoDispatch(demoActions.discardEvents());
    }
  };

  /**
   * CLose the edit event form and reset the fields of form
   */
  const closeHandler = () => {
    demoDispatch(demoActions.eventFormShow(false));
    demoDispatch(demoActions.resetEventFormFields());
  };

  /**
   * Hide the delete event confirmer
   */
  const closeDeleteEventHandler = () => {
    setDeleteEventShow(false);
  };

  /**
   * Delete the event from state
   */
  const deleteEventFromState = () => {
    // Delete event from state
    demoDispatch(demoActions.deleteEventFromState(selectedItem));
    // to show modal on navigating away
    const ddsLogoutConfiguration = rxjsState?.ddsLogoutConfig;
    updateRxjsState({
      ddsLogoutConfig: {
        ...ddsLogoutConfiguration,
        areAnyUnsavedChanges: true,
      },
    });
    const ddsNavigateAwayConfiguration = rxjsState?.ddsNavigateAwayConfig;
    updateRxjsState({
      ddsNavigateAwayConfig: {
        ...ddsNavigateAwayConfiguration,
        isUnsavedChange: true,
      },
    });

    // Reset form values
    closeHandler();
    // CLose delete event confirmer modal
    closeDeleteEventHandler();
  };

  /**
   * Open Delete event confirmer
   */
  const openDeleteEventHandler = () => {
    setDeleteEventShow(true);
  };

  const isLoading = isSavingAllEvents;

  return (
    <Box sx={{ marginBottom: '.5rem' }}>
      {isLoading && <Loader />}
      <FlexBox>
        <ButtonBase
          onClick={goToDemosList}
          data-testid={TEST_IDS.DEMO_EDITOR_LIST_LINK}
        >
          <BreadCrumbsTypo isTakeHome>Demo Data Simulator</BreadCrumbsTypo>
        </ButtonBase>
        <Box
          component="span"
          sx={{
            margin: '0 .5rem',
            color: (theme) => theme.palette.text.lightUnitGrey,
          }}
        >
          /
        </Box>
        <BreadCrumbsTypo>{demoState.selectedDemo.demoName}</BreadCrumbsTypo>
      </FlexBox>
      <FlexBox sx={{ justifyContent: 'flex-end' }}>
        <ButtonBase sx={{ marginRight: '1rem' }}>
          <HeaderButtonTypo
            sx={{
              '&:hover': { color: (theme) => theme.palette.text.saveGreen },
            }}
          >
            {BUTTON_LABELS.CLEAR_ALL}
          </HeaderButtonTypo>
        </ButtonBase>
        <Button
          onClick={() => eventFormShowHandler()}
          width="7.25rem"
          height="2.25rem"
          sx={{ marginLeft: '1rem' }}
          data-testid={TEST_IDS.DEMO_EDITOR_ADD_BUTTON}
        >
          <HeaderButtonTypo>{BUTTON_LABELS.ADD_EVENT}</HeaderButtonTypo>
        </Button>
        <Button
          onClick={() => eventSaveAsHandler(true)}
          width="7.25rem"
          height="2.25rem"
          sx={{ marginLeft: '1rem' }}
          data-testid={TEST_IDS.DEMO_EDITOR_SAVE_AS_BUTTON}
        >
          <HeaderButtonTypo>{BUTTON_LABELS.SAVE_AS}</HeaderButtonTypo>
        </Button>
        <Button
          isApply
          width="7.25rem"
          height="2.25rem"
          sx={{ marginLeft: '1rem' }}
          onClick={() => saveHandler()}
          data-testid={TEST_IDS.DEMO_EDITOR_SAVE_BUTTON}
        >
          <HeaderButtonTypo>{BUTTON_LABELS.SAVE}</HeaderButtonTypo>
        </Button>
      </FlexBox>
      <FlexBox>
        <BreadCrumbsTypo sx={{ marginRight: '.5rem', color: '#BFBFBF' }}>
          Plant
        </BreadCrumbsTypo>
        <Select
          value={demoState.selectedDemoPlant.plantName}
          options={demoState.selectedDemoPlant.options}
          onChange={plantChangeHandler}
        />
      </FlexBox>
      {demoState.eventForm.show && (
        <EventForm
          openDeleteEventHandler={openDeleteEventHandler}
          closeHandler={closeHandler}
          selectedItem={selectedItem}
        />
      )}
      {demoState.saveAsForm.show && (
        <SaveAsDemo
          closer={() => eventSaveAsHandler(false)}
          onSave={onSave}
          showValidation={showValidation}
          isCreatingDemo={isCreatingDemo}
        />
      )}
      {showUnsavedEditsModal && (
        <NotificationModal
          customIcon={MODAL_TYPES.CONFIRM}
          notificationType={NOTIFICATION_LABEL.TYPE}
          notificationText={NOTIFICATION_LABEL.SAVE_TEXT}
          notificationConfirmation={NOTIFICATION_LABEL.SAVE_CONFIRMATION}
          handleSave={saveHandler}
          handleClose={discardHandler}
          buttonSave={BUTTON_LABELS.SAVE}
          buttonCancel={BUTTON_LABELS.DISCARD}
        />
      )}
      {deleteEventShow && (
        <NotificationModal
          modal={deleteEventShow}
          handleClose={closeDeleteEventHandler}
          handleSave={deleteEventFromState}
          notificationType={NOTIFICATION_LABEL.TYPE}
          notificationText={NOTIFICATION_LABEL.EVENT_DELETE}
          notificationConfirmation={NOTIFICATION_LABEL.DELETE_CONFIRMATION}
          buttonSave={BUTTON_LABELS.YES}
          buttonCancel={BUTTON_LABELS.NO}
        />
      )}
    </Box>
  );
};

EditorHeader.propTypes = {
  selectedItem: PropTypes.string,
};

export default EditorHeader;
