import { useState } from 'react';
import { Backdrop } from '@mui/material';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import {
  SaveAsDemoBox,
  FlexBox,
  ModalHeaderBox,
  HeadingTypo,
  OptionsBox,
} from './Event/styledPeers';
import { InputComponent } from '../DemosTable/inputComponent';
import Button from '../../components/Button';
import TEST_IDS from '../../constants/testIds';
import {
  HEADING_LABELS,
  SAVE_AS_DEMO_INPUT_LABELS,
  QUERY_KEYS,
  BUTTON_LABELS,
} from '../../constants';
import { useDemoContext } from '../../contexts/demo/reducer';
import { demoActions } from '../../contexts/demo/actions';
import { getAllConfiguredPlants } from '../../utils/apiHelpers';
import Loader from '../../components/Loader';

const SaveAsDemo = ({ closer, onSave, showValidation, isCreatingDemo }) => {
  const { demoState, demoDispatch } = useDemoContext();

  const [duration, setDuration] = useState(
    demoState?.selectedDemoDataFromAPI?.duration
  );

  const [selectedPlant, setSelectedPlant] = useState(
    demoState?.selectedDemoDataFromAPI?.plants.map((e) => ({
      key: e.plantId,
      name: e.plantName,
    }))
  );

  // Queries
  const { isFetching: isPlantsFetching } = useQuery(
    [QUERY_KEYS.GET_DDS_PLANTS],
    () => getAllConfiguredPlants(),
    {
      onSuccess: (response) => {
        const plantOptions = response.plants.map((e) => ({
          key: e.plantId,
          name: e.plantName,
        }));
        demoDispatch(demoActions.setSaveAsDemoPlantsOptions(plantOptions));
      },
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const inputChangeHandler = (event, field) => {
    demoDispatch(
      demoActions.changeSaveAsDemoFormValues(field, event.target.value)
    );
    showValidation(field, event.target.value);
  };

  const inputChangeHandlerDuration = (event, field) => {
    setDuration(event.target.value);
    demoDispatch(
      demoActions.changeSaveAsDemoFormValues(field, event.target.value)
    );
    showValidation(field, event.target.value);
  };

  const inputChangeHandlerPlant = (event, field) => {
    const result = [];
    // removing duplicates from selected plants
    event.target.value.forEach((item) => {
      const duplicate = result.find((resObj) => resObj.key === item.key);
      if (duplicate) {
        duplicate.Count += duplicate.Count;
      } else {
        result.push({ key: item.key, name: item.name, Count: 1 });
      }
    });
    const selectedPlants = result.filter((e) => e.Count !== 2);
    setSelectedPlant(selectedPlants);
    demoDispatch(
      demoActions.changeSaveAsDemoFormValues(field, event.target.value)
    );
    showValidation(field, selectedPlants);
  };

  const saveAsDemo = () => {
    return (
      <SaveAsDemoBox data-testid={TEST_IDS.SAVE_AS_DEMO_MODAL}>
        <ModalHeaderBox>
          <HeadingTypo>{HEADING_LABELS.SAVE_AS_DEMO}</HeadingTypo>
        </ModalHeaderBox>
        <OptionsBox>
          <FlexBox isOptionsRow>
            <InputComponent
              label={SAVE_AS_DEMO_INPUT_LABELS.DEMO_NAME}
              value={demoState.saveAsForm.primaryFields.demoName.value}
              placeholder={
                demoState.saveAsForm.primaryFields.demoName.placeholder
              }
              isText
              onChange={(e) => inputChangeHandler(e, 'demoName')}
              validationCondition={
                demoState.saveAsForm.primaryFields.demoName.error &&
                demoState.saveAsForm.isDirty
              }
              validationMessage={
                demoState.saveAsForm.primaryFields.demoName.errorMessage
              }
              datatestid={TEST_IDS.SAVE_AS_DEMO_DEMONAME_INPUT}
            />
            <FlexBox isOptionsGrow>
              <InputComponent
                label={SAVE_AS_DEMO_INPUT_LABELS.DURATION}
                value={duration}
                placeholder={
                  demoState.saveAsForm.primaryFields.duration.placeholder
                }
                isText
                onChange={(e) => inputChangeHandlerDuration(e, 'duration')}
                validationCondition={
                  demoState.saveAsForm.primaryFields.duration.error &&
                  demoState.saveAsForm.isDirty
                }
                validationMessage={
                  demoState.saveAsForm.primaryFields.duration.errorMessage
                }
                datatestid={TEST_IDS.SAVE_AS_DEMO_DURATION_INPUT}
              />
            </FlexBox>
            <InputComponent
              label={SAVE_AS_DEMO_INPUT_LABELS.PLANT}
              options={demoState.saveAsForm.primaryFields.plants.options || []}
              value={selectedPlant || []}
              onChange={(e) => inputChangeHandlerPlant(e, 'plants')}
              validationCondition={
                demoState.saveAsForm.primaryFields.plants.error &&
                demoState.saveAsForm.isDirty
              }
              validationMessage={
                demoState.saveAsForm.primaryFields.plants.errorMessage
              }
              datatestid={TEST_IDS.SAVE_AS_DEMO_PLANTS_INPUT}
              addDemoPlantSelect
            />
          </FlexBox>
        </OptionsBox>
        <FlexBox sx={{ justifyContent: 'flex-end', padding: '1.5rem' }}>
          <Button
            width="7.25rem"
            height="2.25rem"
            sx={{ marginLeft: '1rem' }}
            onClick={closer}
            data-testid={TEST_IDS.SAVE_AS_DEMO_CANCEL_BUTTON}
          >
            {BUTTON_LABELS.CANCEL}
          </Button>
          <Button
            isApply
            width="7.25rem"
            height="2.25rem"
            sx={{ marginLeft: '1rem' }}
            onClick={() => onSave(duration, selectedPlant)}
            data-testid={TEST_IDS.SAVE_AS_DEMO_SAVE_BUTTON}
          >
            {BUTTON_LABELS.SAVE}
          </Button>
        </FlexBox>
      </SaveAsDemoBox>
    );
  };
  return (
    <>
      {(isCreatingDemo || isPlantsFetching) && <Loader />}
      <Backdrop open sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}>
        {saveAsDemo()}
      </Backdrop>
    </>
  );
};

SaveAsDemo.propTypes = {
  closer: PropTypes.func,
  onSave: PropTypes.func,
  showValidation: PropTypes.func,
  isCreatingDemo: PropTypes.bool,
};

export default SaveAsDemo;
