import { globalstate$ } from '@smf/ui-util-app';

export const updateRxjsState = (data) => {
  const state = globalstate$.getValue();
  globalstate$.next({ ...state, ...data });
};

/**
 * Function to Update Global state when user tries to navigate away from FactoryGeneral Page
 * @param {Object} actionObj Object to update FactoryGeneralEditState
 */
export const updateFactoryGeneralEditState = (actionObj = {}) => {
  const state = globalstate$.getValue();
  globalstate$.next({
    ...state,
    factoryGeneralEditState: {
      ...state.factoryGeneralEditState,
      action: {
        ...state.factoryGeneralEditState.action,
        ...actionObj,
      },
    },
  });
};

export const updateDDSEventState = (actionObj = {}, editObj = {}) => {
  const state = globalstate$.getValue();
  globalstate$.next({
    ...state,
    ddsNavigateAwayConfig: {
      ...state.ddsNavigateAwayConfig,
      ...editObj,
      action: {
        ...state.ddsNavigateAwayConfig.action,
        ...actionObj,
      },
    },
  });
};
