import { Checkbox, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import DownArrowIcon from '../../assets/img/downArrowIcon.svg';
import UncheckedIcon from '../../assets/img/uncheckedIcon.svg';
import CheckedIcon from '../../assets/img/checkedIcon.svg';
import { MenuProps, StyledSelect } from '../styledPeers';

const SmfCheckbox = ({ checked, onChange, dataTestId }) => {
  return (
    <Checkbox
      disableRipple
      checkedIcon={<CheckedIcon />}
      icon={<UncheckedIcon />}
      checked={checked ?? false}
      onChange={onChange}
      data-testid={dataTestId}
    />
  );
};

const Select = ({
  defaultValue = [],
  disabled = false,
  children,
  options = [],
  value = [],
  onChange,
  addDemoPlantSelect,
  ...props
}) => {
  const noValueSelected = value?.length === 0;

  return (
    <StyledSelect
      IconComponent={(p) => <DownArrowIcon height={32} width={12} {...p} />}
      MenuProps={{ ...MenuProps }}
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
      disabled={disabled}
      displayEmpty
      multiple
      renderValue={(selected) =>
        noValueSelected ? 'Select' : selected?.map((e) => e.name).join(', ')
      }
      {...props}
    >
      {options.map((option) => (
        <MenuItem
          key={option.key}
          value={option}
          data-testid={`opt-${option.key}`}
          disabled={
            addDemoPlantSelect
              ? value?.length >= 4 &&
                value?.map((e) => e.name).indexOf(option.name) < 0
              : disabled
          }
        >
          <SmfCheckbox
            checked={value?.map((e) => e.name).indexOf(option.name) > -1}
          />
          {option.name}
        </MenuItem>
      ))}
      {children}
    </StyledSelect>
  );
};

SmfCheckbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  dataTestId: PropTypes.string,
};

Select.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  addDemoPlantSelect: PropTypes.bool,
};

export default Select;
