import { useQuery } from '@tanstack/react-query';
import DemosTable from '../DemosTable';
import { HEADING_LABELS, QUERY_KEYS } from '../../constants';
import { getDemoList } from '../../utils/apiHelpers';
import Loader from '../../components/Loader';
import { Container, Heading, HeadingDivider } from './styledPeers';
import TEST_IDS from '../../constants/testIds';
import DemoEditor from '../DemoEditor';
import { useDemoContext } from '../../contexts/demo/reducer';
import { demoActions } from '../../contexts/demo/actions';

const Simulator = () => {
  const { demoState, demoDispatch } = useDemoContext();
  const { isFetching: isDemosListFetching } = useQuery(
    [QUERY_KEYS.GET_DEMO_LIST],
    () => getDemoList(),
    {
      onSuccess: (response) => {
        demoDispatch(demoActions.setApiData(response));
      },
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const isLoading = isDemosListFetching;

  return (
    <Container data-testid={TEST_IDS.SIMULATOR_CONTAINER}>
      {isLoading && <Loader />}
      <Heading data-testid={TEST_IDS.SIMULATOR_HEADING}>
        {demoState.selectedDemo
          ? demoState.selectedDemo.demoName
          : HEADING_LABELS.DEMO_DATA_SIMULATOR}
      </Heading>
      <HeadingDivider />
      {demoState.selectedDemo ? (
        <DemoEditor />
      ) : (
        <DemosTable demosList={demoState.demos || []} />
      )}
    </Container>
  );
};

export default Simulator;
