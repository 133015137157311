import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { Box, Backdrop } from '@mui/material';
import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader,
  CustomHeader,
} from 'react-calendar-timeline/lib';
import 'react-calendar-timeline/lib/Timeline.css';
import './reactCalendarTimelineCustomStyles.css';
import { EditorBox } from './styledPeers';
import {
  DISABLED_GROUP_TYPES,
  DDS_EDITOR_SIDE_HEADER,
  DDS_HIERARCHY_LEVEL,
  TOAST_REDUCER_CONSTANTS,
  MESSAGE_STRINGS,
  QUERY_KEYS,
  EVENT_TYPE_KEYS,
  NOTIFICATION_LABEL,
} from '../../constants';
import { useDemoContext } from '../../contexts/demo/reducer';
import { demoActions } from '../../contexts/demo/actions';
import DownArrow from '../../assets/img/downArrow.svg';
import RightArrow from '../../assets/img/rightArrow.svg';
import EditorHeader from './header';
import {
  getEntityHierarchy,
  getAllPlantsByDemoId,
} from '../../utils/apiHelpers';
import { useToastContext } from '../../contexts/toastContext';
import Loader from '../../components/Loader';
import {
  parseEventsToItems,
  getEventByPlant,
  plantsOptionMaker,
  parseItems,
  getMovedObject,
  getResizedObject,
} from '../../utils/helpers';
import TEST_IDS from '../../constants/testIds';
import Modal from '../../components/Modal';
import { useRxjsState } from '../../utils/useRxjsState';
import { updateRxjsState } from '../../utils/rxjsStateNext';

const keys = {
  groupIdKey: 'id',
  groupTitleKey: 'title',
  groupRightTitleKey: 'rightTitle',
  itemIdKey: 'id',
  itemTitleKey: 'title',
  itemDivTitleKey: 'title',
  itemGroupKey: 'group',
  itemTimeStartKey: 'start',
  itemTimeEndKey: 'end',
  groupLabelKey: 'title',
};

export const DemoEditor = () => {
  const { demoState, demoDispatch } = useDemoContext();
  const { toastDispatch } = useToastContext();
  const [groups, setGroups] = useState([]);
  const [openGroups, setOpenGroups] = useState({});
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] =
    useState(false);
  const { rxjsState } = useRxjsState();

  // To Check for the logout modal to be opened
  useEffect(() => {
    const logoutConfig = rxjsState?.ddsLogoutConfig;
    if (logoutConfig) {
      setIsLogoutModalOpen(
        logoutConfig?.isLogoutBtnClicked && logoutConfig?.areAnyUnsavedChanges
      );
    }
  }, [rxjsState]);

  useEffect(() => {
    const navAwayConfig = rxjsState?.ddsNavigateAwayConfig;
    if (navAwayConfig) {
      setIsUnsavedChangesModalOpen(
        navAwayConfig?.isUnsavedChange && navAwayConfig?.action.isUserNavigating
      );
    }
  }, [rxjsState]);

  // TimeLine Variables
  const screenDuration =
    demoState.selectedDemo && demoState.selectedDemo.duration < 15
      ? demoState.selectedDemo.duration
      : 15;
  const screenRangeStartTime = moment().startOf('day');
  const screenRangeEndTime = moment()
    .startOf('day')
    .add(screenDuration, 'minute');

  const lowerTimeRange = moment().startOf('day');
  const upperTimeRange = demoState.selectedDemo.duration
    ? moment().startOf('day').add(demoState.selectedDemo.duration, 'minute')
    : moment().startOf('day').add(4, 'hours');

  const defaultTimeRange = upperTimeRange - lowerTimeRange;

  // API Calling : GET_ENTITY_HIERARCHY
  const { isFetching: isHierarchyFetching } = useQuery({
    queryKey: [
      QUERY_KEYS.GET_ENTITY_HIERARCHY,
      demoState.selectedDemoPlant.plantId,
    ],
    queryFn: () =>
      getEntityHierarchy(
        demoState.selectedDemoPlant.plantId,
        DDS_HIERARCHY_LEVEL
      ),
    onSuccess: (response) => {
      demoDispatch(demoActions.setPlantHierarchy(response));
      demoDispatch(demoActions.resetEventFormFields());
    },
    onError: () => {
      toastDispatch({
        type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
        payload: {
          message: MESSAGE_STRINGS['Toast.message.ERROR'],
        },
      });
    },
    enabled:
      !!demoState.selectedDemoPlant && !!demoState.selectedDemoPlant.plantId,
    retry: false,
    refetchOnWindowFocus: false,
  });

  // API Calling : GET_ALL_PLANTS_BY_DEMO_ID
  const { isFetching: isAllPlantsByDemoIdFetching } = useQuery({
    queryKey: [QUERY_KEYS.GET_ALL_PLANTS_BY_DEMO_ID],
    queryFn: () => getAllPlantsByDemoId(demoState.selectedDemo.demoName),
    onSuccess: (res) => {
      demoDispatch(demoActions.selectedDemoDataFromAPI(res));
      demoDispatch(
        demoActions.selectDemoPlant({
          plantName: res.plants[0].plantName,
          plantId: res.plants[0].plantId,
          options: plantsOptionMaker(res.plants),
        })
      );
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  /**
   * @param {Object} group - group: Heirarchy Level
   *
   * Function to Create Nested View based on Hirarchy
   */
  const generateNestedTreeView = (group) => {
    if (group.entityChildren && group.entityChildren.length > 0) {
      return (
        <Box
          data-testid={`expandable-hierarchy-box-${group.entityHierarchy}`}
          onClick={() => {
            setOpenGroups({
              ...openGroups,
              [`${group.entityId}`]: !openGroups[`${group.entityId}`],
            });
          }}
          style={{
            cursor: 'pointer',
            paddingLeft: (group.hierarchyLevel - 1) * 15,
          }}
        >
          {openGroups[group.entityId] ? <DownArrow /> : <RightArrow />}{' '}
          <span style={{ paddingLeft: 5 }}>
            {group.entityNumber} - {group.entityName}
          </span>
        </Box>
      );
    }
    return (
      <div
        data-testid={`hierarchy-box-${group.entityHierarchy}`}
        style={{
          paddingLeft: (group.hierarchyLevel - 1) * 15 + 20,
          fontWeight: 400,
        }}
      >
        {group.entityNumber} - {group.entityName}
      </div>
    );
  };

  const pushChildGroup = (styledGroups, entities) => {
    entities.forEach((item) => {
      if (openGroups[`${item.parentEntityId}`] || item.hierarchyLevel === 2) {
        styledGroups.push({
          id: item.entityHierarchy,
          title: generateNestedTreeView(item),
          bgColor: '#f7807e',
          entityType: item.entityType,
        });
        if (item.entityChildren && item.entityChildren.length > 0) {
          pushChildGroup(styledGroups, item.entityChildren);
        }
      }
    });
  };

  const flatGroups = (entities) => {
    const styledGroups = [];
    styledGroups.push({
      id: entities.entityHierarchy,
      title: `${entities.entityType}: ${entities.entityName}`,
      bgColor: '#f7807e',
      entityType: entities.entityType,
    });
    pushChildGroup(styledGroups, entities.entityChildren);
    return styledGroups;
  };
  const getEventSpecificValues = (item) => {
    switch (item.eventName) {
      case EVENT_TYPE_KEYS.TRIGGER_SOC_ASSET:
        return {
          title: EVENT_TYPE_KEYS.TRIGGER_SOC,
          recipeId: item?.recipeId,
          recipeName: item?.recipeName,
          parameters: item?.parameters,
        };
      case EVENT_TYPE_KEYS.FAULT_CODES:
        return { title: `${item.faultCode}` };
      default:
        return { title: `${item.eventName} is ${item.value}%` };
    }
  };
  /**
   * @param {array} itms - array of Events
   * @return {array} itms - array of Events with new Attributes
   *
   * Function to Add Other Attribute to Item/Event
   */
  const makeItems = (itms) => {
    const result = [];
    itms.forEach((itm) => {
      /**
       * TODO: debug on not proper rerendering
       */
      if (itm.doSelect) {
        setSelectedItem((prevState) => [...prevState, itm.id]);
      }
      result.push({
        id: itm.id,
        group: itm.entityHierarchy,
        start: moment().startOf('day').add(itm.startmin, 'minute'),
        end: moment().startOf('day').add(itm.endmin, 'minute'),
        canMove: true,
        canResize: true,
        className: '',
        bgColor: itm.bgColor,
        eventName: itm.eventName,
        value: itm?.value === 0 ? 0 : itm?.value || '',
        entityHierarchy: itm?.entityHierarchy,
        eventType: itm?.eventType,
        ...getEventSpecificValues(itm),
      });
    });
    return result;
  };

  useEffect(() => {
    let timer;
    if (demoState.selectedPlantHierarchy) {
      setGroups(flatGroups(demoState.selectedPlantHierarchy));
      setItems(makeItems(parseEventsToItems(getEventByPlant(demoState))));
      /**
       * TODO: if items more than 2 or got edited recently then not rerendering properly - debug any memo causing this
       * temp solution: doing this auto select & deselect to rerender items with updated value
       */
      timer = setTimeout(() => {
        setSelectedItem([]);
      }, 5);
    }
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    openGroups,
    demoState.selectedPlantHierarchy,
    demoState.selectedDemoDataFromAPI,
  ]);

  /**
   * @param {object} actionItem - moved/resized event Object
   *
   * Change the context of moved/resized event Object
   */
  const changeItemContext = (actionItem) => {
    const modifiedItems = items.map((item) =>
      item.id === actionItem.id ? actionItem : item
    );

    setItems(modifiedItems);
    const plantPosition = demoState.selectedDemoDataFromAPI.plants.findIndex(
      (plant) => plant.plantId === demoState.selectedDemoPlant.plantId
    );
    demoDispatch(
      demoActions.setEvents(plantPosition, parseItems(modifiedItems))
    );
  };

  /**
   * @param {number} itemId - Event Identifier
   * @param {number} dragTime - Move/Drag Distance
   * @param {number} newGroupOrder - New group key/index
   *
   * Callback Function : Called After Event Dragging/Movement
   */
  const handleItemMove = (itemId, dragTime, newGroupOrder) => {
    const movedObject = getMovedObject(
      itemId,
      dragTime,
      newGroupOrder,
      groups,
      items,
      upperTimeRange,
      lowerTimeRange
    );
    if (movedObject !== null) {
      changeItemContext(movedObject);
      const ddsLogoutConfiguration = rxjsState?.ddsLogoutConfig;
      updateRxjsState({
        ddsLogoutConfig: {
          ...ddsLogoutConfiguration,
          areAnyUnsavedChanges: true,
        },
      });
      const ddsNavigateAwayConfiguration = rxjsState?.ddsNavigateAwayConfig;
      updateRxjsState({
        ddsNavigateAwayConfig: {
          ...ddsNavigateAwayConfiguration,
          isUnsavedChange: true,
        },
      });
    }
  };

  /**
   * @param {number} itemId - Event Identifier
   * @param {number} time - Resize Distance
   * @param {string} edge - Resize corner : left/right
   *
   * Callback Function : Called After Event Resize
   */
  const handleItemResize = (itemId, time, edge) => {
    const resizedObject = getResizedObject(
      itemId,
      time,
      edge,
      items,
      upperTimeRange,
      lowerTimeRange
    );
    if (resizedObject !== null) {
      changeItemContext(resizedObject);
      const ddsLogoutConfiguration = rxjsState?.ddsLogoutConfig;
      updateRxjsState({
        ddsLogoutConfig: {
          ...ddsLogoutConfiguration,
          areAnyUnsavedChanges: true,
        },
      });
      const ddsNavigateAwayConfiguration = rxjsState?.ddsNavigateAwayConfig;
      updateRxjsState({
        ddsNavigateAwayConfig: {
          ...ddsNavigateAwayConfiguration,
          isUnsavedChange: true,
        },
      });
    }
  };

  const createNewEventHandler = (groupId, time) => {
    const entityIds = groupId.split('|');
    /**
     * 3 - Line | 5 - Cell | 6 - Asset
     */
    if ([3, 5, 6].includes(entityIds.length)) {
      demoDispatch(
        demoActions.autoFillEditEventForm(
          entityIds,
          moment(time).format('HH:mm')
        )
      );
    }
  };

  const editEventHandler = (...params) => {
    const [itemId, , startTime] = params;
    const splitedEntityId = itemId.split('|');
    const eventData = splitedEntityId.pop().split('-');
    const selectedEventData = items.filter((data) => data.id === itemId); // break
    splitedEntityId.push(eventData.shift());
    demoDispatch(
      demoActions.autoFillEditEventForm(
        splitedEntityId,
        moment(startTime).format('HH:mm'),
        eventData,
        selectedEventData
      )
    );
  };

  const addSelectItemHandler = (id) => {
    setSelectedItem([id]);
  };

  const clearSelectItemHandler = () => {
    setSelectedItem([]);
  };

  const itemRenderer = ({
    item,
    itemContext,
    getItemProps,
    getResizeProps,
  }) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();

    return (
      <div
        {...getItemProps({
          style: {
            background: item.bgColor,
            color: '#FFFFFF',
            /**
             * clearing default border & borderRightWidth to override them
             */
            border: '',
            borderRightWidth: '',
            borderStyle: 'solid',
            borderColor: itemContext.selected ? '#FFC107' : item.bgColor,
            borderWidth: itemContext.selected
              ? '.0625rem .19rem .0625rem .0625rem'
              : '.0625rem',
            borderRadius: '25px',
            margin: '0rem 0.1rem 0.2rem 0',
          },
        })}
        data-testid="event-item"
      >
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}
        <div
          style={{
            height: itemContext.dimensions.height,
            paddingLeft: 14,
          }}
        >
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontSize: '0.875rem',
            }}
          >
            {itemContext.title}
          </div>
        </div>
        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
      </div>
    );
  };

  // Loader Condition
  const isLoading = isHierarchyFetching || isAllPlantsByDemoIdFetching;

  return (
    <>
      {isLoading && <Loader />}
      <EditorHeader selectedItem={selectedItem[0]} />
      <EditorBox data-testid={TEST_IDS.DEMO_EDITOR}>
        {groups.length > 0 && (
          <Timeline
            horizontalLineClassNamesForGroup={(group) =>
              DISABLED_GROUP_TYPES.includes(group.entityType)
                ? ['disable-timeline']
                : ['']
            }
            stackItems
            groups={groups}
            items={items}
            keys={keys}
            sidebarWidth={350}
            itemTouchSendsClick={false}
            itemHeightRatio={0.88}
            fixedHeader="fixed"
            itemsSorted
            fullUpdate
            showCursorLine
            canMove
            canResize="both"
            lineHeight={48}
            dragSnap={moment.duration(1, 'minute').asMilliseconds()}
            defaultTimeStart={screenRangeStartTime}
            defaultTimeEnd={screenRangeEndTime}
            onItemMove={handleItemMove}
            onItemResize={handleItemResize}
            itemRenderer={itemRenderer}
            minZoom={defaultTimeRange}
            maxZoom={defaultTimeRange}
            onTimeChange={(_start, _end, updateScrollCanvas) => {
              if (_start > lowerTimeRange && _end < upperTimeRange)
                updateScrollCanvas(_start, _end);
            }}
            onCanvasDoubleClick={createNewEventHandler}
            onItemDoubleClick={editEventHandler}
            selected={selectedItem}
            onItemClick={addSelectItemHandler}
            onItemSelect={addSelectItemHandler}
            onItemDeselect={clearSelectItemHandler}
          >
            <TimelineHeaders className="sticky">
              <SidebarHeader>
                {({ getRootProps }) => {
                  return (
                    <div
                      className="react-header-name"
                      style={{ fontsize: '12px' }}
                      {...getRootProps()}
                    >
                      {DDS_EDITOR_SIDE_HEADER}
                    </div>
                  );
                }}
              </SidebarHeader>
              {/* Header */}
              <CustomHeader unit="day">
                {({ getRootProps }) => {
                  return (
                    <div {...getRootProps()}>
                      <div className="rct-dateHeader rct-dateHeader-primary">
                        Total Duration of Demo
                      </div>
                    </div>
                  );
                }}
              </CustomHeader>
              <DateHeader unit="minute" labelFormat="H:mm" />
            </TimelineHeaders>
          </Timeline>
        )}

        <Backdrop
          open={isLogoutModalOpen || isUnsavedChangesModalOpen}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Modal
            modalHeading={NOTIFICATION_LABEL.TYPE}
            message1={NOTIFICATION_LABEL.UNSAVED_LOGOUT}
            message2={NOTIFICATION_LABEL.CONTINUE_EDIT}
            buttonTitle1={NOTIFICATION_LABEL.DISCARD_CHANGES}
            buttonTitle2={NOTIFICATION_LABEL.CONTINUE_LOGOUT}
            logout={isLogoutModalOpen}
            navAway={isUnsavedChangesModalOpen}
          />
        </Backdrop>
      </EditorBox>
    </>
  );
};

export default DemoEditor;
