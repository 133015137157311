import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import Loader from '../../../components/Loader';
import Select from '../../../components/Select';
import {
  SOCGridItem,
  SOCTableContainer,
  SOCHeaderBox,
  SOCMainHeading,
  SOCTableBox,
  SOCTableContentLabel,
  SOCBodyBox,
  SOCBodyRowGrid,
  SOCErrorText,
} from './styledPeers';
import TextField from '../../../components/TextField';
import {
  FIELD_PRIORITY,
  PRIMARY_INPUT_FIELDS,
  SOC_FIELDS,
  EVENT_TYPE_KEYS,
  TABLE_OF_PARAMETER_CONSTANTS,
  TOAST_REDUCER_CONSTANTS,
  MESSAGE_STRINGS,
  RISK_LEVEL_VALUES,
  EVENT_TYPE_OPTIONS,
} from '../../../constants';
import { demoActions } from '../../../contexts/demo/actions';
import { useDemoContext } from '../../../contexts/demo/reducer';
import { getTableOfParametersList } from '../../../utils/apiHelpers';
import { useToastContext } from '../../../contexts/toastContext';
import FieldsErrorIndicator from './fieldsErrorIndicator';
import { getEntityIdOfSelectedPlantHierarchy } from '../../../utils/helpers';

const TriggerSOCTable = () => {
  const { demoState, demoDispatch } = useDemoContext();
  const { toastDispatch } = useToastContext();

  const { value: recipeId } =
    demoState.eventForm[FIELD_PRIORITY.SOC][SOC_FIELDS.RECIPE_SELECTED.key];
  const editedRecipeId = demoState.eventForm[FIELD_PRIORITY.SOC]?.recipeSeleted;
  const { value: eventName } =
    demoState.eventForm[FIELD_PRIORITY.SOC].RECIPE_SELECTED;
  const { value: eventNameVal } =
    demoState.eventForm[FIELD_PRIORITY.PRIMARY][
      PRIMARY_INPUT_FIELDS.SELECT_EVENT.key
    ];

  const editAddBoolParameters =
    demoState.eventForm[FIELD_PRIORITY.SOC]?.editParameters;
  const [hierarchyType] = [EVENT_TYPE_OPTIONS[eventNameVal].hierarchyType];

  const { value: entityHierarchy } =
    demoState.eventForm[FIELD_PRIORITY.PRIMARY][hierarchyType];
  const { selectedPlantHierarchy } = demoState;

  const entityId = getEntityIdOfSelectedPlantHierarchy(
    selectedPlantHierarchy,
    entityHierarchy
  );
  const {
    isFetching: triggerSOCFetching,
    isError: triggerSOCError,
    data: socTableData,
  } = useQuery({
    queryKey: [eventName, recipeId],
    queryFn: async () => {
      const response = await getTableOfParametersList(entityId, recipeId);
      return response;
    },
    onError: () => {
      toastDispatch({
        type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
        payload: {
          message: MESSAGE_STRINGS['Toast.message.FHERROR'],
        },
      });
    },
    enabled: !!eventName,
    retry: false,
    refetchOnWindowFocus: false,
  });
  const errorBool = triggerSOCError;
  const socTableFetching = triggerSOCFetching;
  useEffect(() => {
    if (socTableData) {
      if (editAddBoolParameters && recipeId === editedRecipeId) {
        const modifiedSOCTableData = socTableData.map((itm) => ({
          ...itm,
          ...editAddBoolParameters.find(
            (item) => item.parameterName === itm.parameterName && item
          ),
        }));
        demoDispatch(demoActions.updateSocTableData(modifiedSOCTableData));
      } else {
        demoDispatch(demoActions.updateSocTableData(socTableData));
      }
    }
  }, [
    demoDispatch,
    socTableData,
    editAddBoolParameters,
    recipeId,
    editedRecipeId,
  ]);

  const tableData = demoState.eventForm[FIELD_PRIORITY.SOC]?.parameters;
  const tableError = demoState.eventForm[FIELD_PRIORITY.SOC]?.errorMessage;

  const onChangeHandler = (e, parameter, fieldKey) => {
    if (tableError?.length > 0)
      demoDispatch(demoActions.setTableOfParameterError(''));
    if (
      !TABLE_OF_PARAMETER_CONSTANTS.ERRORS_VALIDATION.REGEX_POSITIVE_NEGATIVE_NUMBERS.test(
        e.target.value
      ) &&
      e.target.value !== ''
    ) {
      demoDispatch(
        demoActions.updateTriggerSOCValues({
          parameter,
          fieldKey,
          error: true,
          errorMessage:
            TABLE_OF_PARAMETER_CONSTANTS.ERRORS_VALIDATION.VALUE_TO_BE_NUMERIC,
          value: e.target.value,
        })
      );
    } else {
      demoDispatch(
        demoActions.updateTriggerSOCValues({
          parameter,
          fieldKey,
          error: false,
          errorMessage: '',
          value: e.target.value,
        })
      );
    }
  };

  const onChangeRiskLevelHandler = (e, parameter, fieldKey) => {
    if (tableError?.length > 0)
      demoDispatch(demoActions.setTableOfParameterError(''));
    demoDispatch(
      demoActions.updateTriggerSOCValues({
        parameter,
        fieldKey,
        error: false,
        errorMessage: '',
        value: e.target.value,
      })
    );
  };
  if (errorBool) {
    return null;
  }
  if (socTableFetching) {
    return <Loader />;
  }
  return (
    <SOCTableContainer>
      <SOCHeaderBox>
        <SOCMainHeading>
          {TABLE_OF_PARAMETER_CONSTANTS.TABLE_HEADING}
        </SOCMainHeading>
      </SOCHeaderBox>

      {tableError?.length > 0 && (
        <SOCErrorText>
          <FieldsErrorIndicator iconSize={25} errorMessage={tableError} />
        </SOCErrorText>
      )}
      <SOCTableBox>
        <Grid
          container
          sx={{
            backgroundColor: (theme) => theme.customColors.blackishGrey,
            border: (theme) =>
              `.0625rem solid ${theme.customColors.wrapperGrey}`,
            height: '3.5rem',
            borderRadius: '.3rem .3rem 0 0',
          }}
        >
          <SOCGridItem xs={0.1} />
          {TABLE_OF_PARAMETER_CONSTANTS.TABLE_HEADERS.map((heading) =>
            heading === 'Parameter' ? (
              <SOCGridItem xs={2.3} key={heading}>
                <SOCTableContentLabel>{heading}</SOCTableContentLabel>
              </SOCGridItem>
            ) : (
              <SOCGridItem xs={1.6} key={heading}>
                <SOCTableContentLabel>{heading}</SOCTableContentLabel>
              </SOCGridItem>
            )
          )}
        </Grid>
        <SOCBodyBox>
          {tableData?.map((item, idx = 0) => (
            <SOCBodyRowGrid
              container
              key={item.entityParameterId}
              style={{
                height:
                  item.pv.error || item.sv.error || item.riskLevel.error
                    ? '6.5rem'
                    : '3rem',
              }}
            >
              <SOCGridItem xs={0.1} />
              <SOCGridItem xs={2.3}>
                <SOCTableContentLabel>
                  {item.parameterName}
                </SOCTableContentLabel>
              </SOCGridItem>
              <SOCGridItem xs={1.6}>
                <SOCTableContentLabel>
                  {item.setpointUpperLimit}
                </SOCTableContentLabel>
              </SOCGridItem>
              <SOCGridItem xs={1.6}>
                <SOCTableContentLabel>
                  {item.setpointLowerLimit}
                </SOCTableContentLabel>
              </SOCGridItem>
              <SOCGridItem xs={1.6}>
                <SOCTableContentLabel>
                  {item.setpointTarget}
                </SOCTableContentLabel>
              </SOCGridItem>
              <SOCGridItem xs={1.6}>
                <div style={{ height: '3rem', padding: '0.4rem 0' }}>
                  <TextField
                    style={{ width: '8rem' }}
                    value={item.pv.value}
                    error={item.pv.error}
                    inputProps={{
                      maxLength: 6,
                    }}
                    onChange={(e) =>
                      onChangeHandler(e, item.parameterName, EVENT_TYPE_KEYS.PV)
                    }
                    data-testid={`soc-pv-${idx}`}
                  />
                  {item.pv.error && (
                    <FieldsErrorIndicator
                      iconSize={
                        item.pv.errorMessage ===
                        TABLE_OF_PARAMETER_CONSTANTS.ERRORS_VALIDATION
                          .VALUE_HEIGHLIGHTED_FIELD
                          ? 25
                          : 14
                      }
                      errorMessage={item.pv.errorMessage}
                    />
                  )}
                </div>
              </SOCGridItem>
              <SOCGridItem xs={1.6}>
                <div
                  style={{
                    height: '3rem',
                    padding: '0.4rem 0',
                  }}
                >
                  <TextField
                    style={{ width: '8rem' }}
                    value={item.sv.value}
                    error={item.sv.error}
                    inputProps={{
                      maxLength: 6,
                    }}
                    onChange={(e) =>
                      onChangeHandler(e, item.parameterName, EVENT_TYPE_KEYS.SV)
                    }
                    data-testid={`soc-sv-${idx}`}
                  />
                  {item.sv.error && (
                    <FieldsErrorIndicator
                      iconSize={
                        item.sv.errorMessage ===
                        TABLE_OF_PARAMETER_CONSTANTS.ERRORS_VALIDATION
                          .VALUE_HEIGHLIGHTED_FIELD
                          ? 25
                          : 14
                      }
                      errorMessage={item.sv.errorMessage}
                    />
                  )}
                </div>
              </SOCGridItem>
              <SOCGridItem xs={1.6}>
                <div
                  style={{
                    height: '3rem',
                    padding: '0.4rem 0',
                    width: '8rem',
                  }}
                >
                  <Select
                    style={{ width: '8rem' }}
                    options={RISK_LEVEL_VALUES}
                    onChange={(e) =>
                      onChangeRiskLevelHandler(
                        e,
                        item.parameterName,
                        EVENT_TYPE_KEYS.RISK_LEVEL
                      )
                    }
                    value={item.riskLevel.value}
                    error={item.riskLevel.error}
                  />
                  {item.riskLevel.error && (
                    <FieldsErrorIndicator
                      iconSize={25}
                      errorMessage={item.riskLevel.errorMessage}
                    />
                  )}
                </div>
              </SOCGridItem>
            </SOCBodyRowGrid>
          ))}
        </SOCBodyBox>
      </SOCTableBox>
    </SOCTableContainer>
  );
};

export default TriggerSOCTable;
