import { cloneDeep } from 'lodash';
import moment from 'moment';
import {
  EVENT_FORM,
  EVENT_TYPE_COLOR,
  EVENT_TYPE_OPTIONS,
  FIELD_PRIORITY,
  HEIRARCHY_INPUT_FIELDS,
  INPUT_TYPE,
  PRIMARY_INPUT_FIELDS,
  SECONDARY_INPUT_FIELDS,
  THRESHOLD_TYPES,
  SOC_TABLE_OF_PARAMETERS,
  EVENT_TYPE_KEYS,
  SOC_FIELDS,
} from '../constants';

export const sortDemoByDateDesc = (a, b) => {
  if (a.createdAt < b.createdAt) return 1;
  if (a.createdAt > b.createdAt) return -1;
  return 0;
};

/**
 * @param {array} children - plant's children
 * @returns array of options for select component
 *
 ** To generate options for select dropdown of Plant
 */
const plantsOptionMaker = (children) => {
  const options = [];
  children.forEach((plant) => {
    options.push({ value: plant.plantId, label: plant.plantName });
  });
  return options;
};

/**
 * @param {object} inputField - PRIMARY_INPUT_FIELDS value
 * @param {boolean} ignoreError - PRIMARY_INPUT_FIELDS value
 * @returns obj contains default fields conditionally based on param
 */
const generateDefaultProperties = (inputField, ignoreError = false) => ({
  key: inputField.key,
  type: inputField.type,
  defaultValue: '',
  value: '',
  ...(!ignoreError && { error: false, errorMessage: '' }),
  ...(inputField.type === INPUT_TYPE.SELECT && {
    options: [],
  }),
});

/**
 * @param {object} inputFields - PRIMARY_INPUT_FIELDS value
 * @param {object} currentFormState - current form state if its not fresh
 * @returns obj with all hierarchy input fields default properties
 */
const generateInitFieldProperties = (inputFields, currentFormState) => {
  const result = {};
  Object.values(inputFields).forEach((inputField) => {
    result[inputField.key] = {
      ...(currentFormState && {
        error: currentFormState[inputField.key].error,
        errorMessage: currentFormState[inputField.key].errorMessage,
      }),
      ...generateDefaultProperties(inputField, !!currentFormState),
    };
  });
  return result;
};

/**
 * @param {string} fieldKey - PRIMARY_INPUT_FIELDS key value
 * @returns bool positive if we need to compute event options
 *
 * TODO: For now ignored Asset as for OEE till Cell level only we can acheive
 */
const isSelectEventParent = (fieldKey) =>
  [
    HEIRARCHY_INPUT_FIELDS.CELL.key,
    HEIRARCHY_INPUT_FIELDS.LINE.key,
    HEIRARCHY_INPUT_FIELDS.ASSET.key,
  ].includes(fieldKey);

/**
 * @param {array} children - entityChildren of hierarchy
 * @returns {array} options compatable to select component
 */
const hierarchyOptionsMaker = (children) => {
  const options = [];
  children.forEach((childEntity) => {
    options.push({
      value: childEntity.entityHierarchy,
      label: `${childEntity.entityNumber} - ${childEntity.entityName}`,
    });
  });
  return options;
};

/**
 * @param {string} elderSiblingKey - (PRIMARY_INPUT_FIELDS | INPUT_FIELDS) key value
 * @param {string} elderSiblingId - ID - unique value
 * @param {object} hierarchyData - hierarchy value
 * @returns array of options to choose.
 *
 ** If params expect 1st is undefined then its for select event
 *
 ** If elder is plant then pick areas & return else with the queue do the breadth-first search & return
 */
const computePrimaryOptions = (
  elderSiblingKey,
  elderSiblingId,
  hierarchyData
) => {
  if (
    isSelectEventParent(elderSiblingKey) &&
    !elderSiblingId &&
    !hierarchyData
  ) {
    return Object.values(EVENT_TYPE_OPTIONS).filter((o) => {
      /**
       *  For Asset Level Keeping a Check So that ,
       *  it will have only OEE Related Events.
       *  TODO: We can add Other Asset Related Events in Future
       */
      return o.hierarchyType === elderSiblingKey;
    });
  }

  if (elderSiblingKey === HEIRARCHY_INPUT_FIELDS.PLANT.key) {
    return hierarchyOptionsMaker(hierarchyData.entityChildren);
  }

  const queue = [...hierarchyData.entityChildren];

  while (queue.length > 0) {
    const entity = queue.shift();
    if (entity.entityHierarchy === elderSiblingId) {
      return hierarchyOptionsMaker(entity.entityChildren);
    }
    if (entity.entityChildren.length > 0) {
      queue.push(...entity.entityChildren);
    }
  }

  return [];
};
const updateSOCTable = (list, parameter, field, error, errorMessage, value) => {
  const newList = list.map((data) => {
    const prevState = data[field];
    if (data.parameterName === parameter) {
      // Return Updated Data
      return {
        ...data,
        [field]: { ...prevState, value, error, errorMessage },
      };
    }
    return data;
  });

  return newList;
};
/**
 * @param {string} fieldKey - PRIMARY_INPUT_FIELDS key value
 * @param {object} currentState - current event select state
 * @param {boolean} persistErrorState - falg to persist error state
 * @returns new Select event field object
 *
 ** If options need to compute then send back with options
 ** Else reset it
 */
const updateSelectEvent = (
  fieldKey,
  currentState,
  persistErrorState = false
) => {
  if (isSelectEventParent(fieldKey)) {
    return {
      [PRIMARY_INPUT_FIELDS.SELECT_EVENT.key]: {
        ...currentState,
        value: '',
        options: computePrimaryOptions(fieldKey),
      },
    };
  }
  /**
   * Removed Line from Below as We will have Factory Level Events
   * for Line Level
   */
  if (
    [
      HEIRARCHY_INPUT_FIELDS.PLANT.key,
      HEIRARCHY_INPUT_FIELDS.AREA.key,
      HEIRARCHY_INPUT_FIELDS.ZONE.key,
    ].includes(fieldKey)
  ) {
    return {
      [PRIMARY_INPUT_FIELDS.SELECT_EVENT.key]: {
        error: currentState.error,
        errorMessage: currentState.errorMessage,
        ...generateDefaultProperties(
          PRIMARY_INPUT_FIELDS.SELECT_EVENT,
          persistErrorState
        ),
      },
    };
  }

  return null;
};

/**
 * @param {array} entityIdSeperated - selected entityId in pipe separated
 * @param {object} hierarchyData - selected plant hierarchy
 *
 * compute values & options for the hierarchy
 *
 * in last iteration do update select event & if it's not Asset then compute sibling options
 */
const computeHierarchyForAutoFill = (
  entityIdSeperated,
  hierarchyData,
  primaryEventForm
) => {
  const entityIdLevel = entityIdSeperated.length;
  const entityIds = [];
  let entityIdsIdx = 1;
  while (entityIdsIdx <= entityIdLevel) {
    entityIds.push(entityIdSeperated.slice(0, entityIdsIdx).join('|'));
    entityIdsIdx += 1;
  }

  const result = {};
  const queue = [hierarchyData];
  let hierarchyLevel = 1;

  while (hierarchyLevel < entityIdLevel) {
    const parentData = queue.shift();
    const childEntityId = entityIds[hierarchyLevel];
    const childData = parentData.entityChildren.filter(
      (child) => child.entityHierarchy === childEntityId
    )[0];
    const childStateKey = childData.entityType.toUpperCase();

    result[childStateKey] = {
      ...primaryEventForm[childStateKey],
      value: childEntityId,
      defaultValue: childEntityId,
      options: hierarchyOptionsMaker(parentData.entityChildren),
    };

    if (hierarchyLevel === entityIdLevel - 1) {
      const selectEventKey = PRIMARY_INPUT_FIELDS.SELECT_EVENT.key;
      result[selectEventKey] = updateSelectEvent(
        childStateKey,
        primaryEventForm[selectEventKey]
      )[selectEventKey];

      if (childData.entityChildren.length > 0) {
        const siblingFieldKey =
          childData.entityChildren[0].entityType.toUpperCase();
        result[siblingFieldKey] = {
          ...primaryEventForm[siblingFieldKey],
          options: hierarchyOptionsMaker(childData.entityChildren),
        };
      }
    }
    queue.push(childData);
    hierarchyLevel += 1;
  }
  return result;
};

/**
 * @param {object} affectedFieldKey - one of PRIMARY_INPUT_FIELDS whose elder changed
 * @param {bool} cleanAll - to notify only hierarchy need to cleaned or all
 * @param {object} currentFormState - current form state if its not fresh
 * @returns obj of input fields default properties
 *
 ** From the param key take the next set of inputs & reset them
 */
const primaryFieldsCleaner = (
  affectedFieldKey,
  cleanAll = false,
  currentFormState = undefined
) => {
  const fieldsToClean = {};
  let crossedAffectedFieldName = false;

  Object.entries(
    cleanAll ? PRIMARY_INPUT_FIELDS : HEIRARCHY_INPUT_FIELDS
  ).forEach((fieldEntry) => {
    if (!crossedAffectedFieldName) {
      crossedAffectedFieldName = affectedFieldKey === fieldEntry[1].key;
      return;
    }
    fieldsToClean[fieldEntry[0]] = { ...fieldEntry[1] };
  });

  return generateInitFieldProperties(fieldsToClean, currentFormState);
};

/**
 * @param {string} HHMM - HH:MM format string
 * @returns {number} in minutes
 */
const parseHHMMtoMins = (HHMM) => {
  const ip = HHMM.split(':');
  return +ip[0] * 60 + +ip[1];
};

const objValueMapper = (key) => {
  return Object.values(THRESHOLD_TYPES).find((d) => d.key === key).label;
};

/**
 * @param {string} key - PRIMARY_INPUT_FIELDS updating key
 * @param {string} value - input value
 * @param {object} zoneState - current zone field state
 *
 * if line event selected & zone is having required error then remove it
 */
const clearZoneRequiredOnLineEvent = (key, value, zoneState) => {
  if (
    key === PRIMARY_INPUT_FIELDS.SELECT_EVENT.key &&
    (EVENT_TYPE_OPTIONS[value]?.hierarchyType ?? undefined) ===
      PRIMARY_INPUT_FIELDS.LINE.key &&
    zoneState.error
  ) {
    return {
      [PRIMARY_INPUT_FIELDS.ZONE.key]: {
        ...zoneState,
        error: false,
        errorMessage: '',
      },
    };
  }

  return null;
};

/**
 * @param {*} fieldKey - PRIMARY_INPUT_FIELDS key value
 * @param {*} eventFormState - reducer state object eventForm property
 * @returns {object} error message cleared duration property
 *
 * if overlapping error happened & start time changing then clearing the duration error state
 */
const clearDurationOverlappingError = (fieldKey, eventFormState) => {
  const durationProperty =
    eventFormState[FIELD_PRIORITY.PRIMARY][PRIMARY_INPUT_FIELDS.DURATION.key];
  if (
    [
      PRIMARY_INPUT_FIELDS.SELECT_EVENT.key,
      PRIMARY_INPUT_FIELDS.START_TIME.key,
    ].includes(fieldKey) &&
    (durationProperty.errorMessage ===
      EVENT_FORM.ERROR_MESSAGE.EVENTS_OVERLAPPING ||
      durationProperty.errorMessage ===
        EVENT_FORM.ERROR_MESSAGE.FAULT_CODE_ALREADY_SELECTED)
  ) {
    return {
      [PRIMARY_INPUT_FIELDS.DURATION.key]: {
        ...durationProperty,
        error: false,
        errorMessage: '',
      },
    };
  }
  return false;
};

/**
 * @param {string} primaryFieldKey - PRIMARY_INPUT_FIELDS - key value
 * @returns bool whether secondary fields need to be cleared or not
 */
const needToClearSecondaryFields = (primaryFieldKey) =>
  ![
    PRIMARY_INPUT_FIELDS.START_TIME.key,
    PRIMARY_INPUT_FIELDS.DURATION.key,
  ].includes(primaryFieldKey);

/**
 * @param {*} demoState - reducer state object
 * @returns selected plants event property from state object
 */
const getEventByPlant = (demoState) =>
  demoState.selectedDemoDataFromAPI.plants.filter(
    (plant) => plant.plantId === demoState.selectedDemoPlant.plantId
  )[0].events;

/**
 * @param {object} obj - object to be checked
 * @param {string} prop - property name to be checked
 * @returns bool whether property was present or not
 */
const objHasProp = (obj, prop) => obj[prop] !== undefined;

/**
 * @param {number} newSlotStart - new slot start min
 * @param {number} newSlotEnd - new slot end min
 * @param {number} oldSlotStart - existing slot start min
 * @param {number} oldSlotEnd - existing slot start min
 * @returns {boolean} flag
 *
 * `oldSlotStart + 1` & `oldSlotEnd - 1` we're doing as seconds we're calculation by our own
 * so start_time 2 means 00:02:00 & duration 2 means 00:04:59
 */
const isSlotsOverlapping = (
  newSlotStart,
  newSlotEnd,
  oldSlotStart,
  oldSlotEnd
) => {
  return (
    Math.max(newSlotStart, oldSlotStart) < Math.min(newSlotEnd, oldSlotEnd)
  );
};

const getMissingProperty = (demoState) => {
  const fieldPriority = FIELD_PRIORITY.PRIMARY;

  const events = getEventByPlant(demoState);

  const { value: eventName } =
    demoState.eventForm[fieldPriority][PRIMARY_INPUT_FIELDS.SELECT_EVENT.key];

  const [eventType, hierarchyType] = [
    EVENT_TYPE_OPTIONS[eventName]?.eventType,
    EVENT_TYPE_OPTIONS[eventName]?.hierarchyType,
  ];

  const { value: entityHierarchy } =
    demoState.eventForm[fieldPriority][hierarchyType];

  if (!objHasProp(events, entityHierarchy))
    return EVENT_FORM.VALIDATION_SIGNAL.NO_MATCH_ENTITY_ID;

  if (!objHasProp(events[entityHierarchy], eventType))
    return EVENT_FORM.VALIDATION_SIGNAL.NO_MATCH_EVENT_TYPE;

  if (!objHasProp(events[entityHierarchy][eventType], eventName))
    return EVENT_FORM.VALIDATION_SIGNAL.NO_MATCH_EVENT_NAME;

  return EVENT_FORM.VALIDATION_SIGNAL.OK;
};
const getTriggerSOCValues = (demoState, startTime, endTime) => {
  const triggerSOCDataExtract =
    demoState.eventForm[FIELD_PRIORITY.SOC].parameters;
  const recipeValue =
    demoState.eventForm[FIELD_PRIORITY.SOC][SOC_FIELDS.RECIPE_SELECTED.key];
  const tableData = triggerSOCDataExtract?.map((i) => ({
    parameterName: i.parameterName,
    pv: parseInt(i.pv.value, 10),
    sv: parseInt(i.sv.value, 10),
    riskLevel: i.riskLevel.value,
    entityParameterId: i.entityParameterId,
  }));
  const tableDataFiltered = tableData?.filter((i) => i.riskLevel !== '');
  const options = recipeValue?.options.filter(
    (i) => i.value === recipeValue.value
  );
  return {
    startmin: startTime,
    endmin: endTime,
    parameters: tableDataFiltered,
    recipeName: options[0].recipeName,
    recipeId: parseInt(recipeValue.value, 10),
  };
};

let entityId;
/**
 * @param {object} selectedPlantHierarchy - selectedPlantHierarchy
 * @param {string} hierarchyTypeValue - hierarchyType
 */
const getEntityIdOfSelectedPlantHierarchy = (
  selectedPlantHierarchy,
  hierarchyTypeValue
) => {
  Object.keys(selectedPlantHierarchy).forEach((key) => {
    if (selectedPlantHierarchy[key] === hierarchyTypeValue) {
      entityId = selectedPlantHierarchy?.entityId;
    }
    if (
      typeof selectedPlantHierarchy[key] === 'object' &&
      selectedPlantHierarchy[key] !== null
    ) {
      getEntityIdOfSelectedPlantHierarchy(
        selectedPlantHierarchy[key],
        hierarchyTypeValue
      );
    }
  });
  return entityId;
};

/**
 * @param {object} demoState - state
 * @param {string} validationSignal - VALIDATION_SIGNAL
 * @returns array with event inserted
 */
const insertEventToPlant = (demoState, validationSignal) => {
  const allPlants = cloneDeep(demoState.selectedDemoDataFromAPI.plants);
  const { eventForm } = demoState;
  const selectedPlantId = demoState.selectedDemoPlant.plantId;

  const selectedPlantIdx = allPlants.findIndex(
    (plant) => plant.plantId === selectedPlantId
  );

  const selectedPlant = allPlants[selectedPlantIdx];
  const { events } = selectedPlant;
  const { value: eventName } =
    eventForm[FIELD_PRIORITY.PRIMARY][PRIMARY_INPUT_FIELDS.SELECT_EVENT.key];
  const [eventType, hierarchyType] = [
    EVENT_TYPE_OPTIONS[eventName].eventType,
    EVENT_TYPE_OPTIONS[eventName].hierarchyType,
  ];
  const { value: entityHierarchy } =
    eventForm[FIELD_PRIORITY.PRIMARY][hierarchyType];
  const { selectedPlantHierarchy } = demoState;

  getEntityIdOfSelectedPlantHierarchy(selectedPlantHierarchy, entityHierarchy);
  const startTime = parseHHMMtoMins(
    eventForm[FIELD_PRIORITY.PRIMARY][PRIMARY_INPUT_FIELDS.START_TIME.key].value
  );
  const endTime =
    startTime +
    parseInt(
      eventForm[FIELD_PRIORITY.PRIMARY][PRIMARY_INPUT_FIELDS.DURATION.key]
        .value,
      10
    );
  const value =
    eventForm[FIELD_PRIORITY.SECONDARY][SECONDARY_INPUT_FIELDS[eventName]?.key]
      ?.value;

  let eventTimeSlot;
  switch (eventType) {
    case EVENT_TYPE_KEYS.SOC:
      if (eventName === EVENT_TYPE_KEYS.TRIGGER_SOC_ASSET) {
        eventTimeSlot = getTriggerSOCValues(demoState, startTime, endTime);
      }
      break;
    case EVENT_TYPE_KEYS.EC:
      eventTimeSlot = {
        startmin: startTime,
        endmin: endTime,
        faultCode: eventForm[FIELD_PRIORITY.SECONDARY][
          SECONDARY_INPUT_FIELDS[eventName]?.key
        ]?.options?.find((d) => d.value === value)?.label,
        value,
      };
      break;
    default:
      eventTimeSlot = {
        startmin: startTime,
        endmin: endTime,
        value: parseInt(value, 10),
      };
      break;
  }
  const missingProperty = getMissingProperty(demoState);
  if (missingProperty === EVENT_FORM.VALIDATION_SIGNAL.NO_MATCH_ENTITY_ID)
    events[entityHierarchy] = {
      [eventType]: { [eventName]: [eventTimeSlot] },
      entityId,
    };

  if (missingProperty === EVENT_FORM.VALIDATION_SIGNAL.NO_MATCH_EVENT_TYPE)
    events[entityHierarchy] = {
      ...events[entityHierarchy],
      [eventType]: { [eventName]: [eventTimeSlot] },
      entityId,
    };

  if (missingProperty === EVENT_FORM.VALIDATION_SIGNAL.NO_MATCH_EVENT_NAME)
    events[entityHierarchy] = {
      ...events[entityHierarchy],
      [eventType]: {
        ...events[entityHierarchy][eventType],
        [eventName]: [eventTimeSlot],
        entityId,
      },
    };

  if (
    validationSignal === EVENT_FORM.VALIDATION_SIGNAL.ONLY_CHANGE_EVENT_VALUE ||
    validationSignal === EVENT_FORM.VALIDATION_SIGNAL.OK
  ) {
    const allSlots = [];
    allSlots.push(...events[entityHierarchy][eventType][eventName]);
    const idxToReplace = allSlots.findIndex(
      (slot) => slot.startmin === startTime && slot.endmin === endTime
    );
    if (validationSignal === EVENT_FORM.VALIDATION_SIGNAL.OK) {
      allSlots.push(eventTimeSlot);
    }
    if (
      validationSignal ===
        EVENT_FORM.VALIDATION_SIGNAL.ONLY_CHANGE_EVENT_VALUE &&
      idxToReplace >= 0
    ) {
      allSlots.splice(idxToReplace, 1, eventTimeSlot);
    }
    events[entityHierarchy] = {
      ...events[entityHierarchy],
      [eventType]: {
        ...events[entityHierarchy][eventType],
        [eventName]: allSlots.sort((a, b) => {
          if (a.startmin < b.startmin) return -1;
          if (a.startmin > b.startmin) return 1;
          return 0;
        }),
      },
      entityId,
    };
  }

  selectedPlant.events = { ...events };

  allPlants.splice(selectedPlantIdx, 1, selectedPlant);
  return allPlants;
};

const getFaultCodes = (data) => {
  const newArray = data?.map((item) => ({
    ...item,
    value: `${item.faultCodeNumber}`,
    label: `${item.faultCode}`,
  }));
  return newArray;
};

/**
 * @param {object} events - selected plant events
 * @returns {array} items generated out for chart
 */
const parseEventsToItems = (events) => {
  const result = [];
  Object.keys(events).forEach((entityHierarchy) => {
    Object.keys(events[entityHierarchy]).forEach((eventType) => {
      Object.keys(events[entityHierarchy][eventType]).forEach((eventName) => {
        const eventSlots = events[entityHierarchy][eventType][eventName];
        const doSelect = eventSlots.length > 2;
        if (typeof eventSlots !== 'number') {
          eventSlots?.forEach((eve, id) => {
            result.push({
              id: `${entityHierarchy}-${eventType}-${eventName}-${id}`,
              ...eve,
              entityHierarchy,
              eventType,
              eventName,
              bgColor: EVENT_TYPE_COLOR[eventName],
              /**
               * TODO: if items more than 2 then not rerendering properly - debug any memo causing this
               * temp solution: doing this automatic selection to rerender items with updated value
               */
              ...(doSelect && { doSelect }),
            });
          });
        }
      });
    });
  });
  return result;
};

/**
 * @param {object} momentTimeObject - Time Object
 * @returns {number} -minutes
 */
const convertToMinutes = (momentTimeObject) => {
  return momentTimeObject.hours() * 60 + momentTimeObject.minutes();
};
const setEvents = (
  eventType,
  startmin,
  endmin,
  value,
  title,
  recipeName,
  recipeId,
  parameters
) => {
  switch (eventType) {
    case EVENT_TYPE_KEYS.EC:
      return { startmin, endmin, faultCode: title, value };
    case EVENT_TYPE_KEYS.SOC:
      return {
        startmin,
        endmin,
        parameters,
        recipeName,
        recipeId,
      };
    default:
      return { startmin, endmin, value };
  }
};
/**
 * @param {array} items - items from chart
 * @returns {object} events
 */
const parseItemsToEvents = (items) => {
  const result = {};
  items.forEach(
    ({
      entityHierarchy,
      eventType,
      eventName,
      startmin,
      endmin,
      value,
      id,
      title,
      recipeName,
      recipeId,
      parameters,
    }) => {
      const slotObj = setEvents(
        eventType,
        startmin,
        endmin,
        value,
        title,
        recipeName,
        recipeId,
        parameters
      );
      if (!objHasProp(result, entityHierarchy)) {
        result[entityHierarchy] = {
          [eventType]: { [eventName]: [slotObj] },
        };
        return;
      }
      if (!objHasProp(result[entityHierarchy], eventType)) {
        result[entityHierarchy] = {
          ...result[entityHierarchy],
          [eventType]: { [eventName]: [slotObj] },
        };
        return;
      }
      if (!objHasProp(result[entityHierarchy][eventType], eventName)) {
        result[entityHierarchy] = {
          ...result[entityHierarchy],
          [eventType]: {
            ...result[entityHierarchy][eventType],
            [eventName]: [slotObj],
          },
        };
        return;
      }
      const idSplit = id.split('-');
      const positionToPush = idSplit[idSplit.length - 1];
      result[entityHierarchy][eventType][eventName].splice(
        positionToPush,
        0,
        slotObj
      );
    }
  );
  return result;
};

/**
 * @param {array} items - items from chart
 * @returns {object} events
 *
 * Wrapper funtion of parseItemsToEvents
 */
const parseItems = (items) => {
  const modifiedItems = items.map((item) => ({
    ...item,
    startmin: convertToMinutes(item.start),
    endmin: convertToMinutes(item.end),
  }));
  return parseItemsToEvents(modifiedItems);
};

/**
 * Remove key from state if no event preent for that key
 * @param {*} requiredPlant
 * @param {*} assetId
 * @param {*} eventData
 */
const removeKeyIfNoEventPresent = (requiredPlant, assetId, eventData) => {
  const plantData = requiredPlant;
  // Remove the object key if no events are available for that key
  if (plantData.events[assetId][eventData[0]][eventData[1]].length === 0) {
    delete plantData.events[assetId][eventData[0]][eventData[1]];
  }
  // Remove the object key if no events are available for that key
  if (Object.keys(plantData.events[assetId][eventData[0]]).length === 0) {
    delete plantData.events[assetId][eventData[0]];
  }
  // Remove the object key if no events are available for that key
  if (Object.keys(plantData.events[assetId]).length === 0) {
    delete plantData.events[assetId];
  }
  return plantData;
};

/**
 * Delete selected event from demo state
 *
 * @param {*} plants list of plants in demo state
 * @param {*} selectedPlantId plant id of the event to be deleted
 * @param {*} eventToBeDeleted event to be deleted
 * @returns
 */
const deleteEventFromState = (plants, selectedPlantId, eventToBeDeleted) => {
  // Fetch the required plant based on plant id
  const requiredPlantIdx = plants.findIndex(
    (demo) => demo.plantId === selectedPlantId
  );

  // eventToBeDeleted will be something like "272d91a9-62ec-4aa4-b9dc-341937552718|AR1|L1|Z1|C101-OEE-Quality-0"

  // Entity id in array form eg 272d91a9-62ec-4aa4-b9dc-341937552718|AR1|L1|Z1
  const splitedEntityId = eventToBeDeleted.split('|');

  // Event data eg C101-OEE-Quality-0
  const eventData = splitedEntityId.pop().split('-');

  // Splitted entity id now becomes 272d91a9-62ec-4aa4-b9dc-341937552718|AR1|L1|Z1|C101
  // and eventData becomes OEE-Quality-0
  splitedEntityId.push(eventData.shift());

  // Asset id becomes Splitted entity id now becomes 272d91a9-62ec-4aa4-b9dc-341937552718|AR1|L1|Z1|C101 in string form
  const assetId = splitedEntityId.join('|');

  // Remove the event to be deleted
  plants[requiredPlantIdx].events[assetId][eventData[0]][eventData[1]].splice(
    eventData[2],
    1
  );

  // Remove the object key if no events are available for that key
  const updatedPlant = removeKeyIfNoEventPresent(
    plants[requiredPlantIdx],
    assetId,
    eventData
  );

  plants.splice(requiredPlantIdx, 1, updatedPlant);

  return plants;
};

/**
 * @param {object} actionItem - moved/resized event Object
 * @param {array} items - list of items/events in Editor
 * @return boolean true (i.e. collision occur) , false (i.e. no collision)
 *
 * Function to Check wheather collision occur due to movement/dragging of event with same eventName & group
 */
const checkCollision = (actionItem, items) => {
  const sameLevelEventItems = items.filter(
    (item) =>
      item.id !== actionItem.id &&
      actionItem.group === item.group &&
      actionItem.eventName === item.eventName
  );
  const colItem = sameLevelEventItems.find((itm) =>
    isSlotsOverlapping(
      actionItem.start.hours() * 60 + actionItem.start.minutes(),
      actionItem.end.hours() * 60 + actionItem.end.minutes(),
      itm.start.hours() * 60 + itm.start.minutes(),
      itm.end.hours() * 60 + itm.end.minutes()
    )
  );
  if (colItem) return true;
  return false;
};

/**
 * @param {object} actionItem - moved/resized event Object
 * @param {object} upperTimeRange - upper Demo-Duration range (moment)
 * @param {object} lowerTimeRange - lower Demo-Duration range (moment)
 * @return boolean true (i.e. lies within Demo Duration Range) , false (i.e. doesn't lies within Demo Duration Range)
 *
 * Function to Check moved/resized event lies in demoDuration
 */
const withInTheRange = (actionItem, upperTimeRange, lowerTimeRange) => {
  if (
    upperTimeRange.isAfter(actionItem.start) &&
    actionItem.start.isAfter(lowerTimeRange.add(-1, 'seconds')) &&
    upperTimeRange.add(1, 'seconds').isAfter(actionItem.end) &&
    actionItem.end.isAfter(lowerTimeRange)
  )
    return true;
  return false;
};
const setTableData = (list) => {
  const newArr = list.map((value) => ({
    ...value,
    pv: {
      ...SOC_TABLE_OF_PARAMETERS.PV_TRIGGER_SOC,
      value: value?.pv?.toString() || '',
    },
    sv: {
      ...SOC_TABLE_OF_PARAMETERS.SV_TRIGGER_SOC,
      value: value?.sv?.toString() || '',
    },
    riskLevel: {
      ...SOC_TABLE_OF_PARAMETERS.RISK_LEVEL_FOR_TABLE_OF_PARAMETERS,
      value: value?.riskLevel || '',
    },
  }));
  return newArr;
};

/**
 * @param {number} itemId - Event Identifier
 * @param {number} dragTime - Move/Drag Distance
 * @param {number} newGroupOrder - New group key/index
 * @param {array} groups - list of group/levels in Editor
 * @param {array} items - list of items/events in Editor
 * @param {object} upperTimeRange - upper Demo-Duration range (moment)
 * @param {object} lowerTimeRange - lower Demo-Duration range (moment)
 *
 * @return {object} (i.e. Success : Event Object with new start and end time after drag) , null (i.e. Item is not movable)
 *
 * getMovedObject Function : return MovedObject if Item can moved to specified timeline
 */
const getMovedObject = (
  itemId,
  dragTime,
  newGroupOrder,
  groups,
  items,
  upperTimeRange,
  lowerTimeRange
) => {
  const group = groups[newGroupOrder];
  let actionItem = items.find((item) => item.id === itemId);
  if (actionItem.group !== group.id) return null;
  actionItem = {
    ...actionItem,
    start: moment(dragTime),
    end: moment(dragTime + (actionItem.end - actionItem.start)),
  };
  if (!withInTheRange(actionItem, upperTimeRange, lowerTimeRange)) return null;
  if (checkCollision(actionItem, items)) return null;
  return actionItem;
};

/**
 * @param {number} itemId - Event Identifier
 * @param {number} time - Resize Distance
 * @param {string} edge - Resize corner : left/right
 * @param {array} items - list of items/events in Editor
 * @param {object} upperTimeRange - upper Demo-Duration range (moment)
 * @param {object} lowerTimeRange - lower Demo-Duration range (moment)
 *
 * @return {object} (i.e. Success : Event Object with new start and end time after drag) , null (i.e. Item is not resizable)
 *
 * getResizedObject Function : return resizedObject if Item can resize till specified timeline
 */
const getResizedObject = (
  itemId,
  time,
  edge,
  items,
  upperTimeRange,
  lowerTimeRange
) => {
  let actionItem = items.find((item) => item.id === itemId);
  actionItem = {
    ...actionItem,
    start: edge === 'left' ? moment(time) : actionItem.start,
    end: edge === 'left' ? actionItem.end : moment(time),
  };
  if (moment.duration(actionItem.end.diff(actionItem.start)).asMinutes() <= 0)
    return null;
  if (!withInTheRange(actionItem, upperTimeRange, lowerTimeRange)) return null;
  if (checkCollision(actionItem, items)) return null;
  return actionItem;
};

export {
  plantsOptionMaker,
  generateDefaultProperties,
  generateInitFieldProperties,
  isSelectEventParent,
  updateSelectEvent,
  computePrimaryOptions,
  computeHierarchyForAutoFill,
  primaryFieldsCleaner,
  parseHHMMtoMins,
  objValueMapper,
  clearZoneRequiredOnLineEvent,
  clearDurationOverlappingError,
  needToClearSecondaryFields,
  getEventByPlant,
  objHasProp,
  isSlotsOverlapping,
  insertEventToPlant,
  parseEventsToItems,
  parseItemsToEvents,
  parseItems,
  deleteEventFromState,
  getMissingProperty,
  checkCollision,
  withInTheRange,
  getMovedObject,
  getResizedObject,
  hierarchyOptionsMaker,
  convertToMinutes,
  setTableData,
  updateSOCTable,
  getFaultCodes,
  getEntityIdOfSelectedPlantHierarchy,
};
