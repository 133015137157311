import { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import PlayIcon from '../../assets/img/playIcon.svg';
import PlayIconInverted from '../../assets/img/playIconInverted.svg';
import EditPenIcon from '../../assets/img/editPen.svg';
import EditPenIconInverted from '../../assets/img/editPenInverted.svg';
import DustbinIcon from '../../assets/img/dustbinIcon.svg';
import { deleteDemo, getDemoList } from '../../utils/apiHelpers';
import DustbinIconInverted from '../../assets/img/dustbinIconInverted.svg';
import { RowActionsContainer } from './styledPeers';
import TEST_IDS from '../../constants/testIds';
import { useDemoContext } from '../../contexts/demo/reducer';
import { demoActions } from '../../contexts/demo/actions';
import NotificationModal from '../../components/NotificationModal';
import { useToastContext } from '../../contexts/toastContext';
import {
  NOTIFICATION_LABEL,
  BUTTON_LABELS,
  TOAST_REDUCER_CONSTANTS,
  MESSAGE_STRINGS,
} from '../../constants';

const RowActions = ({ demo, showActions }) => {
  const [isPlayIconInverted, setPlayIconInverted] = useState(false);
  const [isEditIconInverted, setEditIconInverted] = useState(false);
  const [isDeleteIconInverted, setDeleteIconInverted] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const { demoDispatch } = useDemoContext();

  const { toastDispatch } = useToastContext();
  const { mutate: getDemosList } = useMutation({
    mutationFn: () => getDemoList(),
    onSuccess: (response) => {
      demoDispatch(demoActions.setApiData(response));
    },
    refetchOnWindowFocus: false,
    retry: false,
  });

  const { mutate: deleteDemoHandler } = useMutation({
    mutationFn: (data) => deleteDemo(data),
    onSuccess: (response) => {
      toastDispatch({
        type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
        payload: {
          message: response.message,
        },
      });
      getDemosList();
    },
    onError: (error) => {
      toastDispatch({
        type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
        payload: {
          message: error.message ?? MESSAGE_STRINGS['Toast.message.WARNING'],
        },
      });
    },
  });

  const clickDeleteHandler = () => {
    setDeleteModal(!deleteModal);
  };

  const editDemo = () => {
    demoDispatch(demoActions.selectDemo(demo));
  };
  const onClickDeleteYes = () => {
    deleteDemoHandler(demo.demoName);
    setDeleteModal(!deleteModal);
  };

  const startDemo = () => {
    demoDispatch(demoActions.setDemoStartConfirmer(demo.demoName));
  };

  const buttons = [
    {
      type: 'play',
      isInverted: isPlayIconInverted,
      setInvertion: setPlayIconInverted,
      icon: (
        <PlayIcon
          data-testid={`${TEST_IDS.DEMOS_TABLE_ROW_ACTION_ICON}-play`}
        />
      ),
      invertedIcon: (
        <PlayIconInverted
          data-testid={`${TEST_IDS.DEMOS_TABLE_ROW_ACTION_INVERTED_ICON}-play`}
        />
      ),
      onClick: startDemo,
    },
    {
      type: 'edit',
      isInverted: isEditIconInverted,
      setInvertion: setEditIconInverted,
      icon: (
        <EditPenIcon
          data-testid={`${TEST_IDS.DEMOS_TABLE_ROW_ACTION_ICON}-edit`}
        />
      ),
      invertedIcon: (
        <EditPenIconInverted
          data-testid={`${TEST_IDS.DEMOS_TABLE_ROW_ACTION_INVERTED_ICON}-edit`}
        />
      ),
      onClick: editDemo,
    },
    {
      type: 'delete',
      isInverted: isDeleteIconInverted,
      setInvertion: setDeleteIconInverted,
      icon: (
        <DustbinIcon
          data-testid={`${TEST_IDS.DEMOS_TABLE_ROW_ACTION_ICON}-delete`}
        />
      ),
      invertedIcon: (
        <DustbinIconInverted
          data-testid={`${TEST_IDS.DEMOS_TABLE_ROW_ACTION_INVERTED_ICON}-delete`}
        />
      ),
      onClick: clickDeleteHandler,
    },
  ];
  return (
    <RowActionsContainer
      data-testid={TEST_IDS.DEMOS_TABLE_ROW_ACTIONS_CONTAINER}
    >
      {showActions &&
        buttons.map((button) => (
          <IconButton
            key={button.type}
            onClick={() => button.onClick(button.type)}
            onMouseEnter={() => button.setInvertion(true)}
            onMouseLeave={() => button.setInvertion(false)}
            sx={{ margin: '0 .5rem' }}
            data-testid={`${TEST_IDS.DEMOS_TABLE_ROW_ACTION}-${button.type}`}
          >
            {button.isInverted ? button.invertedIcon : button.icon}
          </IconButton>
        ))}
      {deleteModal && (
        <NotificationModal
          modal={deleteModal}
          handleClose={() => setDeleteModal(!deleteModal)}
          handleSave={onClickDeleteYes}
          notificationType={NOTIFICATION_LABEL.TYPE}
          notificationText={NOTIFICATION_LABEL.DELETE_TEXT}
          notificationConfirmation={NOTIFICATION_LABEL.DELETE_CONFIRMATION}
          buttonSave={BUTTON_LABELS.YES}
          buttonCancel={BUTTON_LABELS.NO}
        />
      )}
    </RowActionsContainer>
  );
};

RowActions.propTypes = {
  demo: PropTypes.shape({
    demoName: PropTypes.string,
    duration: PropTypes.number,
    createdAt: PropTypes.number,
    createdBy: PropTypes.string,
    plantNames: PropTypes.shape([]),
  }),
  showActions: PropTypes.bool,
};

export default RowActions;
