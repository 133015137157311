import { Select as MuiSelect, styled, Tooltip } from '@mui/material';

export const fontStyles = {
  fontWeight: 400,
  fontSize: '.875rem',
  lineHeight: '1.19rem',
};

export const StyledSelect = styled(MuiSelect)(({ theme }) => ({
  ...fontStyles,
  width: '17rem',
  height: '2rem',
  borderRadius: '1.25rem',
  overflow: 'hidden',

  '& .MuiSelect-iconOutlined': {
    marginRight: '0.5em',
    top: 'inherit',
  },

  '& .MuiOutlinedInput-notchedOutline ': {
    border: `.0625rem solid ${theme.palette.border.lightWhiteGrey}`,
  },

  '&.Mui-focused .MuiOutlinedInput-notchedOutline ': {
    border: `.0625rem solid ${theme.palette.selected.greenSelect}`,
  },

  '&.Mui-disabled .MuiOutlinedInput-notchedOutline ': {
    border: `.0625rem solid ${theme.palette.border.darkGrey}`,
  },

  '& .MuiSelect-iconOutlined.Mui-disabled': {
    '& path': { fill: theme.palette.background.darkGrey },
  },

  '.Mui-disabled': { color: theme.palette.text.darkGrey },

  '& .MuiInputBase-input': {
    padding: '.5rem 1rem',
    height: '100%',
    width: '100%',

    '&.MuiSelect-select': {
      backgroundColor: theme.palette.background.layoutBackgroundColor,
    },

    '&.Mui-disabled': {
      WebkitTextFillColor: 'inherit',
    },
  },
}));

export const MenuProps = {
  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
  transformOrigin: { vertical: 'top', horizontal: 'left' },
  sx: { maxHeight: '15rem' },
  PaperProps: {
    sx: {
      '& .MuiList-root': { padding: 0 },
      '& .MuiMenuItem-root': { ...fontStyles },
      '& .MuiMenuItem-root:hover, .MuiMenuItem-root.Mui-selected, .MuiMenuItem-root.Mui-selected:hover':
        {
          backgroundColor: (theme) => theme.palette.background.gunmetalGrey,
        },
    },
  },
};

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  zIndex: theme.zIndex.tooltip + 1,
  '& .MuiTooltip-tooltip': {
    maxWidth: '25.25rem',
    border: `1px solid ${theme.customColors.wrapperGrey}`,
    backgroundColor: theme.customColors.dullDark,
    padding: 6,
    whitespace: 'no-wrap',
  },
}));
