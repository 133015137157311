/* eslint-disable import/prefer-default-export */
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import ErrorExclamationIcon from '../../assets/img/errorExclamation.svg';

export const ValidationMessage = ({ condition, message }) => {
  return (
    <Typography
      sx={{
        color: (theme) => theme.customColors.white,
        fontSize: '0.75em',
        marginTop: '0.5em',
        paddingTop: '0.4375em',
        lineHeight: '1.333334',
        height: '0.75em',
        width: '17.063rem',
      }}
      varient="caption"
    >
      <span
        style={{
          display: 'inline-block',
          width: '2em',
          verticalAlign: 'top',
        }}
      >
        {condition && <ErrorExclamationIcon />}
      </span>
      {condition && (
        <span style={{ display: 'inline-block', width: 'calc(90% - 2em)' }}>
          {message}
        </span>
      )}
    </Typography>
  );
};

ValidationMessage.propTypes = {
  condition: PropTypes.bool,
  message: PropTypes.string,
};
