import PropTypes from 'prop-types';
import { FlexBox } from '../styledPeers';
import { ErrorMessageTypo } from './styledPeers';
import ErrorIcon from '../../../assets/img/errorExclamation.svg';

const FieldsErrorIndicator = ({ errorMessage, iconSize = 14 }) => (
  <FlexBox sx={{ marginTop: '.5rem' }}>
    <ErrorIcon height={iconSize} width={iconSize} />
    <ErrorMessageTypo>{errorMessage}</ErrorMessageTypo>
  </FlexBox>
);

FieldsErrorIndicator.propTypes = {
  errorMessage: PropTypes.string,
  iconSize: PropTypes.number,
};

export default FieldsErrorIndicator;
