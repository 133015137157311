import { alpha, Box, Grid, styled, Typography } from '@mui/material';

const DemoTableContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
}));

const HeaderBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
}));

const MainHeading = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: '1rem',
  lineHeight: '1.4rem',
}));

const GridItem = styled((props) => <Grid item {...props} />)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const TableBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '.5rem 0',
  height: '100%',
}));

const TableContentLabel = styled(Typography, {
  shouldForwardProp: (p) => p !== 'isHeader',
})(({ theme, isHeader }) => ({
  color: theme.palette.text[isHeader ? 'lightUnitGrey' : 'primary'],
  fontWeight: 400,
  fontSize: '.875rem',
  lineHeight: isHeader ? '1.19rem' : '1.25rem',
}));

const BodyBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.blackGrey,
  border: `.0625rem solid ${theme.customColors.wrapperGrey}`,
  borderWidth: '0 .0625rem',
  flexGrow: 1,
}));

const BodyRowGrid = styled(Grid)(({ theme }) => ({
  borderColor: theme.customColors.wrapperGrey,
  borderStyle: 'solid',
  borderWidth: '0 0 .0625rem',
  height: '3rem',
  '&:hover': {
    backgroundColor: alpha(theme.customColors.white, 0.08),
  },
}));

const FooterBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.customColors.blackishGrey,
  border: `.0625rem solid ${theme.customColors.wrapperGrey}`,
  borderRadius: '0 0 .3rem .3rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: '3rem',
  padding: '.5rem',
}));

const ResultsCountLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 400,
  fontSize: '.875rem',
  lineHeight: '1.25rem',
  marginRight: '.5rem',
}));

const RowActionsContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: '100%',
}));

const FlexBox = styled(Box, {
  shouldForwardProp: (p) => !['isOptionsRow', 'isOptionsGrow'].includes(p),
})(({ isOptionsRow, isOptionsGrow }) => ({
  display: 'flex',
  alignItems: 'center',
  ...(isOptionsRow && {
    width: '100%',
    padding: '.75rem 0',
    ':first-of-type': { paddingTop: 0 },
    ':last-of-type': { paddingBottom: 0 },
  }),
  ...(isOptionsGrow && { flex: 1, justifyContent: 'center' }),
}));

const AddDemoBox = styled(Box)(({ theme }) => ({
  width: '56.25rem',
  borderRadius: '.5rem',
  backgroundColor: theme.palette.background.blackGrey,
}));

const ModalHeaderBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '4rem',
  borderBottom: `.0625rem solid ${theme.customColors.wrapperGrey}`,
  padding: '1.125rem 1.5rem',
}));

const HeadingTypo = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: '1.25rem',
  lineHeight: '1.7rem',
}));

const OptionsBox = styled(Box)(() => ({ padding: '1.5rem' }));

const InputLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.lightUnitGrey,
  fontWeight: 400,
  fontSize: '.875rem',
  lineHeight: '1.19rem',
  marginBottom: '.5rem',
}));
const ResetFiltersLabel = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isEnabled',
})(({ theme, isEnabled = false }) => {
  let color = theme.palette.text.darkGrey;
  if (isEnabled) color = theme.palette.text.infoColor;
  return {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '0.875rem',
    lineHeight: '1.19rem',
    color,
  };
});

export {
  DemoTableContainer,
  HeaderBox,
  MainHeading,
  GridItem,
  TableBox,
  TableContentLabel,
  BodyBox,
  BodyRowGrid,
  FooterBox,
  ResultsCountLabel,
  RowActionsContainer,
  AddDemoBox,
  FlexBox,
  ModalHeaderBox,
  HeadingTypo,
  OptionsBox,
  InputLabel,
  ResetFiltersLabel,
};
