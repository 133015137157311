const TEST_IDS = {
  /**
   ** COMPONENTS
   *
   ** LOADER
   */
  LOADER: 'circular-loader',
  /**
   ** DFilter
   */
  DEMO_FILTER: 'smf_dfilter',
  FILTER_ALL: 'filter-item-all',
  FILTER_SEARCH: 'filter-searchField',
  FILTER_SEARCH_ICON: 'filter-searchIcon',

  /**
   ** FilterPopover
   */
  FILTER_POPOVER: 'smf_popover_demo_filter',
  /**
   ** CONFIRMER
   */
  CONFIRMER_BOX: 'Confirmer-Box',
  CONFIRMER_YES_BUTTON: 'Confirmer-Yes-Button',
  CONFIRMER_NO_BUTTON: 'Confirmer-No-Button',
  /**
   ** CONTAINERS
   *
   ** SIMULATOR
   */

  SIMULATOR_CONTAINER: 'simulator-container',
  SIMULATOR_HEADING: 'simulator-heading',
  /**
   ** DEMOS TABLE
   */
  DEMOS_TABLE_CONTAINER: 'demos-table-container',
  DEMOS_TABLE_HEADING: 'demos-table-heading',
  DEMOS_TABLE_ADD_BUTTON: 'demos-table-add-button',
  DEMOS_TABLE_BODY_CONTAINER: 'demos-table-body-container',
  DEMOS_TABLE_BODY_ROW_CONTAINER: 'demos-table-body-row-container',
  DEMOS_TABLE_RESULTS_COUNT: 'demos-table-results-count',
  DEMOS_TABLE_ROW_ACTIONS_CONTAINER: 'demos-table-row-actions-container',
  DEMOS_TABLE_ROW_ACTION: 'demos-table-row-action',
  DEMOS_TABLE_ROW_ACTION_ICON: 'demos-table-row-action-icon',
  DEMOS_TABLE_ROW_ACTION_INVERTED_ICON: 'demos-table-row-action-inverted-icon',
  DEMOS_TABLE_HEADER_ASC_SORT_ICON: 'demos-table-header-asc-sort-icon',
  DEMOS_TABLE_HEADER_DESC_SORT_ICON: 'demos-table-header-desc-sort-icon',

  DEMOS_TABLE_HEADER_SORT_BUTTON: 'demos-table-header-desc-sort-button',
  DEMOS_TABLE_HEADER_FILTER_ICON: 'demos-table-header-filter-icon',
  DEMOS_TABLE_HEADER_FILTER_BUTTON: 'demos-table-header-filter-button',

  /**
   ** DEMO EDITOR
   */
  DEMO_EDITOR: 'demo-editor',
  DEMO_EDITOR_SAVE_BUTTON: 'demo-editor-save-button',
  DEMO_EDITOR_SAVE_AS_BUTTON: 'demo-editor-save-as-button',
  DEMO_EDITOR_LIST_LINK: 'demo-editor-list-link',
  DEMO_EDIT_GANTT_EVENT_ITEM: 'event-item',
  DEMO_EDITOR_ADD_BUTTON: 'demo-editor-add-button',
  /**
   ** ADD DEMO FORM
   */
  ADD_DEMO_MODAL: 'add-demo-modal',
  ADD_DEMO_SAVE_BUTTON: 'add-demo-save-button',
  ADD_DEMO_CANCEL_BUTTON: 'add-demo-cancel-button',
  ADD_DEMO_DEMONAME_INPUT: 'add-demo-demoname-input',
  ADD_DEMO_DURATION_INPUT: 'add-demo-duration-input',
  ADD_DEMO_PLANTS_INPUT: 'add-demo-plants-input',
  ADD_OEE_FIELD: 'add-oee-field',

  /**
   ** SAVE AS DEMO FORM
   */
  SAVE_AS_DEMO_MODAL: 'save-as-demo-modal',
  SAVE_AS_DEMO_SAVE_BUTTON: 'save-as-demo-save-button',
  SAVE_AS_DEMO_CANCEL_BUTTON: 'save-as-demo-cancel-button',
  SAVE_AS_DEMO_DEMONAME_INPUT: 'save-as-demo-demoname-input',
  SAVE_AS_DEMO_DURATION_INPUT: 'save-as-demo-duration-input',
  SAVE_AS_DEMO_PLANTS_INPUT: 'save-as-demo-plants-input',

  /**
   ** UNSAVED EDITS LOGOUT
   */
  DISCARD_BUTTON: 'discard-button',
  CONTINUE_BUTTON: 'continue-button',
};

export default TEST_IDS;
