/* eslint-disable import/prefer-default-export */
/* eslint-disable consistent-return */
import { globalstate$ } from '@smf/ui-util-app';
import { useEffect, useState } from 'react';

// eslint-disable-line consistent-return
export const useRxjsState = () => {
  const [rxjsState, setRxjsState] = useState({});

  useEffect(() => {
    if (globalstate$) {
      const subscription = globalstate$.subscribe((globalstate) => {
        setRxjsState(globalstate);
      });

      return () => {
        if (subscription) {
          subscription.unsubscribe();
        }
      };
    }
  }, []);

  return { rxjsState };
};
