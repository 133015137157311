import {
  Box,
  Grid,
  Modal,
  styled,
  Tooltip,
  tooltipClasses,
  Typography,
} from '@mui/material';

const FlexBox = styled(Box, {
  shouldForwardProp: (p) => !['isOptionsRow', 'isOptionsGrow'].includes(p),
})(({ isOptionsRow, isOptionsGrow }) => ({
  display: 'flex',
  alignItems: 'center',
  ...(isOptionsRow && {
    width: '100%',
    padding: '.75rem 0',
    ':first-of-type': { paddingTop: 0 },
    ':last-of-type': { paddingBottom: 0 },
  }),
  ...(isOptionsGrow && { flex: 1, justifyContent: 'center' }),
}));

const BreadCrumbsTypo = styled(Typography, {
  shouldForwardProp: (p) => p !== 'isTakeHome',
})(({ theme, isTakeHome }) => ({
  fontWeight: 400,
  fontSize: '.75rem',
  lineHeight: '1rem',
  color: theme.palette.text[isTakeHome ? 'saveGreen' : 'lightUnitGrey'],
}));

const HeaderButtonTypo = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: '.875rem',
  lineHeight: '1rem',
}));

const PopupModal = styled(Modal)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const PopupBox = styled(Box)(() => ({
  background: '#202020',
  minWidth: '20rem',
  borderRadius: '.25rem',
  padding: '2rem',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '#888888',
  display: 'flex',
  alignItems: 'center',
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const ButtonContainerGrid = styled(Grid)(() => ({
  paddingRight: '1.5625rem',
  paddingBottom: '1rem',
  paddingTop: '.1875rem',
}));

const EditorBox = styled(Box)(() => ({
  touchAction: 'none',
  passive: 'false',
  '& .disable-timeline.rct-hl-even': {
    background: '#53565A !important',
  },
  '& .disable-timeline.rct-hl-odd': {
    background: '#53565A !important',
  },
  '& .rct-dateHeader': {
    background: '#232629',
    color: '#BFBFBF',
  },
  '& .react-calendar-timeline': {
    borderLeft: '1px solid #40444A',
    borderRight: '1px solid #40444A',
  },
  '& .react-calendar-timeline .rct-header-root': {
    background: '#232629',
    color: '#FFFFFF',
    borderTop: '1px solid #40444A',
    borderBottom: 'unset',
    minHeight: '6.5rem',
  },
  '& .react-calendar-timeline .rct-header-root div': {},
  '& .react-calendar-timeline .rct-dateHeader': {
    borderLeft: '1px solid #40444A',
    borderBottom: '1px solid #40444A',
    color: '#FFFFFF',
    fontSize: '14px',
    fontWeight: '400',
  },
  '& .react-header-name': {
    color: '#BFBFBF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    fontWeight: '400',
    borderRight: '1px solid #40444A',
    borderBottom: '1px solid #40444A',
  },
  '& .react-calendar-timeline .rct-sidebar': {
    borderRight: '1px solid #40444A',
    color: '#FFFFFF',
    fontSize: '14px',
    fontWeight: '700',
  },
  '& .react-calendar-timeline .rct-sidebar .rct-sidebar-row': {
    borderBottom: '1px solid #40444A',
  },
  '& .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even':
    {
      background: '#202020',
      borderBottom: '1px solid #40444A',
      paddingLeft: '16px',
    },
  '& .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd ':
    {
      background: '#202020',
      paddingLeft: '16px',
    },
  '& .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd': {
    background: '#202020',
    borderBottom: '1px solid #40444A',
  },
  '& .react-calendar-timeline .rct-horizontal-lines .rct-hl-even': {
    background: '#202020',
    borderBottom: '1px solid #40444A',
  },
  '& .react-calendar-timeline .rct-dateHeader-primary': {
    borderLeft: '1px solid #40444A',
    borderRight: '1px solid #40444A',
    color: `#BFBFBF`,
  },
  '& .react-calendar-timeline .rct-dateHeader-secondary': {
    background: '#202020',
  },
  '& .react-calendar-timeline .rct-calendar-header': {
    color: '#BFBFBF',
    fontSize: '14px',
    fontWeight: '400',
    borderWidth: '0px',
    minHeight: '6.5rem',
  },
  '& .rct-calendar-header div:nth-of-type(1)': {
    minHeight: '3.5rem',
    background: '#232629',
  },
  '& .rct-calendar-header div:nth-of-type(2)': {
    minHeight: '3rem',
  },
  '& .react-calendar-timeline .rct-vertical-lines .rct-vl': {
    borderLeft: '1px solid #40444A',
  },
  '& .rct-item': {
    borderRadius: '0px',
  },
}));

const StyledDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '62%',
  justifyContent: 'space-between',
}));

export {
  FlexBox,
  BreadCrumbsTypo,
  HeaderButtonTypo,
  PopupModal,
  ButtonContainerGrid,
  BootstrapTooltip,
  PopupBox,
  EditorBox,
  StyledDiv,
};
