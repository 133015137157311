/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
import { InputLabel } from './styledPeers';
import Select from '../../components/MultiSelect';
import TextField from '../../components/TextField';
import { ValidationMessage } from '../../components/InlineError';

export const InputComponent = ({
  label,
  defaultValue,
  disabled = false,
  isText = false,
  placeholder = '',
  options,
  value,
  onChange,
  validationCondition,
  validationMessage,
  datatestid,
  addDemoPlantSelect = false,
}) => (
  <div>
    <InputLabel>{label}</InputLabel>
    {isText ? (
      <TextField
        disabled={disabled}
        defaultValue={defaultValue}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        data-testid={datatestid}
      />
    ) : (
      <Select
        disabled={disabled}
        options={options}
        value={value}
        onChange={onChange}
        data-testid={datatestid}
        addDemoPlantSelect={addDemoPlantSelect}
      />
    )}
    <ValidationMessage
      condition={validationCondition}
      message={validationMessage}
    />
  </div>
);

InputComponent.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  isText: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  ]),
  onChange: PropTypes.func,
  validationCondition: PropTypes.bool,
  validationMessage: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  datatestid: PropTypes.string,
  addDemoPlantSelect: PropTypes.bool,
};
