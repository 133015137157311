import { Backdrop, styled, Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Button from '../Button';
import ConfirmationIcon from '../../assets/img/confirmIcon.svg';

export const FlexBox = styled(Box, {
  shouldForwardProp: (p) => !['isOptionsRow', 'isOptionsGrow'].includes(p),
})(({ isOptionsRow, isOptionsGrow }) => ({
  display: 'flex',
  alignItems: 'center',
  ...(isOptionsRow && {
    width: '100%',
    padding: '.75rem 0',
    ':first-of-type': { paddingTop: 0 },
    ':last-of-type': { paddingBottom: 0 },
  }),
  ...(isOptionsGrow && { flex: 1, justifyContent: 'center' }),
}));

export const NotificationBox = styled(Box)(({ theme }) => ({
  width: '36rem',
  borderRadius: '.5rem',
  backgroundColor: theme.palette.background.blackGrey,
  color: 'white',
}));

export const ModalHeaderBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'left',
  padding: '1.125rem 1.5rem',
  borderBottom: `.0625rem solid ${theme.customColors.wrapperGrey}`,
}));

export const ModalTextBox = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  alignItems: 'left',
  padding: '1.125rem 4rem',
  fontWeight: 400,
  fontSize: '1rem',
  lineHeight: '1rem',
}));

export const ModalTextBoldBox = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  alignItems: 'left',
  padding: '1.125rem 4rem',
  fontWeight: 600,
  fontSize: '1rem',
  lineHeight: '1rem',
}));

export const HeadingTypo = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: '1.25rem',
  lineHeight: '1.7rem',
  paddingLeft: '0.5rem',
}));

const NotificationModal = ({
  notificationType = '',
  notificationText = '',
  notificationConfirmation = '',
  handleSave,
  handleClose,
  buttonCancel = '',
  buttonSave = '',
}) => {
  return (
    <Backdrop open sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}>
      <NotificationBox>
        <ModalHeaderBox>
          <ConfirmationIcon height={30} width={30} />
          <HeadingTypo>{notificationType}</HeadingTypo>
        </ModalHeaderBox>
        <ModalTextBox>{notificationText}</ModalTextBox>
        <ModalTextBox>{notificationConfirmation}</ModalTextBox>

        <FlexBox sx={{ justifyContent: 'flex-end', padding: '1.5rem' }}>
          <Button
            width="7.25rem"
            height="2.25rem"
            sx={{ marginLeft: '1rem' }}
            onClick={handleClose}
          >
            {buttonCancel}
          </Button>
          <Button
            isApply
            width="7.25rem"
            height="2.25rem"
            sx={{ marginLeft: '1rem' }}
            onClick={handleSave}
          >
            {buttonSave}
          </Button>
        </FlexBox>
      </NotificationBox>
    </Backdrop>
  );
};

NotificationModal.propTypes = {
  notificationType: PropTypes.string,
  notificationText: PropTypes.string,
  notificationConfirmation: PropTypes.string,
  handleSave: PropTypes.func,
  handleClose: PropTypes.func,
  buttonCancel: PropTypes.string,
  buttonSave: PropTypes.string,
};

export default NotificationModal;
