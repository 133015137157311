import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Paper,
  InputAdornment,
  Typography,
  TextField,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  styled,
} from '@mui/material';
import SearchIcon from '../../assets/img/searchIcon.svg';
import {
  CBM_CONSTANTS,
  BUTTON_LABELS,
  TABLE_HEADINGS,
  TABLE_COLUMNS,
} from '../../constants/index';
import MESSAGE_STRINGS from '../../constants/en-us';
import TEST_IDS from '../../constants/testIds';

const customStyles = {
  filtersListTypoStyle: {
    '& .MuiTypography-body2': {
      fontSize: '0.813em',
    },
  },
  dividerBox: {
    borderTop: (theme) => `.063em solid ${theme.customColors.darkGrey}`,
    height: '0.0625em',
    maxWidth: '90%',
    margin: 'auto',
    padding: '0 !important',
  },
};

const CustomListItemIcon = styled(ListItemIcon)(() => ({
  minWidth: 0,
  minHeight: 0,
}));

const IconSpan = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  backgroundColor: 'transparent',
  border: `.063em solid ${theme.palette.background.default}`,

  '&.Mui-focusVisible &': {
    outline: 'none',
    outlineOffset: 2,
  },

  'input:hover ~ &': {
    backgroundColor: 'transparent',
  },

  'input:disabled ~ &': {
    boxShadow: 'none',
  },
}));

const CheckedIconSpan = styled('span')(({ theme }) => ({
  borderRadius: '0.125em',
  backgroundColor: theme.palette.background.saveGreen,
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23171717'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.background.saveGreen,
  },
}));

const DFilter = (props) => {
  const {
    filtersListObj,
    filterOnChange,
    selectedDropDownValues,
    callBackClose,
    maxChar,
    filtersByName,
  } = props;
  const [checked, setChecked] = useState(selectedDropDownValues); // ? contains the selected values.
  const [filtersList, setFiltersList] = useState([]); // ? contains the list populating the UI.
  const [filterType, setFilterType] = useState('');
  const [searchText, setSearchText] = useState('');
  const [checkedAll, setCheckedAll] = useState(false);
  const [resetBtnEnable, setResetBtnEnable] = useState(false);
  const [applyBtnEnable, setApplyBtnEnable] = useState(false);
  // ? this has been added to enable the "All" realtime if all options are manually checked by the user without applying it yet.
  // ? remove this if it's not required.
  const isAllChecked = checked.length === filtersList.length;
  const onReset = () => {
    // Handling reset filter
    // setChecked([]);
    // filterOnChange(filtersByName || filterType, selectedDropDownValues);
    callBackClose(null);
  };

  const onApply = () => {
    // Handling apply filter
    filterOnChange(filtersByName || filterType, checked);
    callBackClose(null);
  };

  useEffect(() => {
    if (
      filtersListObj &&
      filtersListObj.filtersList &&
      filtersListObj.filtersList.length
    ) {
      setFiltersList(
        filtersListObj.filtersName === TABLE_COLUMNS.DURATION
          ? filtersListObj.filtersList.sort((a, b) => a - b)
          : filtersListObj.filtersList.sort()
      );
      setFilterType(filtersListObj.filtersName);
      if (selectedDropDownValues && selectedDropDownValues.length) {
        if (
          filtersListObj.filtersList.length === selectedDropDownValues.length
        ) {
          setCheckedAll(true);
        }
      }
    }
    if (selectedDropDownValues.length) {
      setApplyBtnEnable(true);
      setResetBtnEnable(true);
    }
  }, [filterType, filtersByName, filtersListObj, selectedDropDownValues]);

  const handleToggle = (type, filterObj, eve) => {
    // Handling checkboxes
    setApplyBtnEnable(true);
    let newChecked = [];
    if (filterObj === 'all') {
      if (eve.target.checked) {
        let allFilterslist = [];
        allFilterslist = filtersListObj.filtersList;
        allFilterslist.forEach((item) => {
          newChecked.push(item);
        });
        setCheckedAll(true);
      } else {
        setCheckedAll(false);
      }
    } else {
      const currentIndex = checked.indexOf(filterObj);
      newChecked = [...checked];
      if (currentIndex === -1) {
        newChecked.push(filterObj);
      } else {
        setCheckedAll(false);
        newChecked.splice(currentIndex, 1);
      }
    }
    setChecked(newChecked);
    setResetBtnEnable(newChecked.length);
    setApplyBtnEnable(newChecked.length);
  };
  const StyledCheckbox = (propsCheckbox) => (
    <Checkbox
      disableRipple
      color="default"
      checkedIcon={<CheckedIconSpan />}
      icon={<IconSpan />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...propsCheckbox}
    />
  );

  const getHeader = (filter) => {
    const match = TABLE_HEADINGS.find((item) => item.id === filter);
    return match !== undefined && match.heading;
  };

  return (
    <Paper elevation={0} data-testid={TEST_IDS.DEMO_FILTER}>
      <Grid
        container
        spacing={3}
        sx={{
          margin: '-12px',
          '& .MuiGrid-item': { padding: '12px' },
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'normal' }}>
            {CBM_CONSTANTS.FILTER}
          </Typography>
          <TextField
            id="searchfield"
            data-testid={TEST_IDS.FILTER_SEARCH}
            variant="filled"
            sx={{
              '& .MuiInputBase-input.MuiFilledInput-input': {
                padding: '0.3em 0.5em',
              },
            }}
            placeholder="Search"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ color: 'white' }}>
                  <SearchIcon />
                </InputAdornment>
              ),
              maxLength: maxChar || 128,
            }}
            onChange={(event) => setSearchText(event.target.value)}
          />
        </Grid>
        <Grid xs={12} item sx={customStyles.dividerBox} />
        <Grid
          item
          xs={12}
          sx={{
            maxHeight: '19em',
            height: '19em',
            overflowY: 'auto',
            padding: '0.25em !important',
            '& .MuiList-padding': {
              padding: '0',
            },
            '& .MuiListItem-gutters': {
              paddingLeft: '0',
              paddingRight: '0',
            },
          }}
        >
          <Typography
            variant="body2"
            gutterBottom
            sx={{ padding: '0.5em 0 1rem 0.5em', fontWeight: 'bold' }}
          >
            {getHeader(filterType)}
          </Typography>
          <List>
            {filtersList.length ? (
              <ListItem
                id="filter-item-all"
                data-testid={TEST_IDS.FILTER_ALL}
                key="all"
                role={undefined}
                dense
                button
                onClick={(event) => handleToggle(null, 'all', event)}
              >
                <CustomListItemIcon>
                  <StyledCheckbox
                    id="filter-checkbox-all"
                    data-testid="filer-checkbox-all"
                    checked={checkedAll || isAllChecked}
                  />
                </CustomListItemIcon>
                <ListItemText
                  id="checkbox-list-label-all"
                  data-testid="checkbox-list-label-all"
                  primary="All"
                  sx={customStyles.filtersListTypoStyle}
                />
              </ListItem>
            ) : (
              <Typography sx={{ paddingLeft: '0.5em', fontSize: '0.875rem' }}>
                {MESSAGE_STRINGS['data.not.available']}
              </Typography>
            )}
            {filtersList.map((value, idx) => {
              const idxKey = idx + 1;
              const labelId = `checkbox-list-label-$x{value}`;
              return (
                String(value)
                  .toLowerCase()
                  .includes(searchText && searchText.toLowerCase()) && (
                  <ListItem
                    id={`filter-item-${idxKey}`}
                    data-testid={`filter-item-${idxKey}`}
                    key={`${value}-${idxKey}`}
                    role={undefined}
                    dense
                    button
                    onClick={(event) => handleToggle(filterType, value, event)}
                  >
                    <CustomListItemIcon>
                      <StyledCheckbox
                        id={`filter-checkbox-${idxKey}`}
                        data-testid={`filter-checkbox-${idxKey}`}
                        checked={checked.indexOf(value) !== -1}
                      />
                    </CustomListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={
                        filtersListObj.filtersName === TABLE_COLUMNS.DURATION
                          ? `${value} mins`
                          : value
                      }
                      sx={customStyles.filtersListTypoStyle}
                    />
                  </ListItem>
                )
              );
            })}
          </List>
        </Grid>
        <Grid xs={12} item sx={customStyles.dividerBox} />
        <Grid
          xs={12}
          item
          sx={{
            padding: '1em',
            marginTop: '0.75em',
            '& .MuiButton-outlined.Mui-disabled': {
              border: (theme) =>
                `.063em solid ${theme.palette.border.lightRaven}`,
            },
            '& .MuiButton-root.Mui-disabled': {
              color: (theme) => theme.customColors.white,
              backgroundColor: (theme) => theme.customColors.darkGrey,
              opacity: 0.5,
            },
          }}
        >
          <Button
            id="reset"
            onClick={() => onReset()}
            variant="outlined"
            sx={{
              backgroundColor: 'transparent',
              color: 'inherit',
              width: '8em',
              height: '2.25em',
              borderRadius: '1.5em',
              textTransform: 'none',
              border: (theme) =>
                `.063em solid ${theme.palette.border.lightRaven}`,
              '&:hover': {
                borderColor: (theme) => theme.palette.border.lightRaven,
              },
            }}
            disabled={!resetBtnEnable}
            data-testid={`${TEST_IDS.DEMO_FILTER}-reset`}
          >
            {BUTTON_LABELS.CANCEL}
          </Button>
          <Button
            id="apply"
            data-testid={`${TEST_IDS.DEMO_FILTER}-apply`}
            onClick={() => onApply()}
            variant="outlined"
            sx={{
              color: (theme) => theme.customColors.black,
              backgroundColor: (theme) => theme.customColors.saveGreen,
              width: '8.25em',
              height: '2.25em',
              borderRadius: '1.5em',
              border: (theme) =>
                `.063em solid ${theme.palette.border.lightRaven}`,
              float: 'right',
              textTransform: 'none',
              '&.MuiButton-root:hover': {
                color: (theme) => theme.customColors.white,
                borderColor: (theme) => theme.customColors.saveGreen,
              },
            }}
            disabled={!applyBtnEnable}
          >
            {BUTTON_LABELS.APPLY}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

DFilter.propTypes = {
  filtersListObj: PropTypes.shape(),
  filterOnChange: PropTypes.func,
  selectedDropDownValues: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
  ]),
  callBackClose: PropTypes.func,
  maxChar: PropTypes.number,
  filtersByName: PropTypes.string,
};

export default DFilter;
