import { styled, TextField as MuiTextField } from '@mui/material';
import PropTypes from 'prop-types';

const StyledTextField = styled(MuiTextField, {
  shouldForwardProp: (p) => p !== 'isError',
})(({ theme, isError }) => ({
  width: '17.063rem',
  borderRadius: '.125rem',

  '& .MuiInputBase-input': {
    padding: '.75rem',
    fontWeight: 400,
    fontSize: '.875rem',
    lineHeight: '1.19rem',

    '&::placeholder': { color: theme.palette.border.lightRaven },

    '&.Mui-disabled': {
      WebkitTextFillColor: 'inherit',
    },
  },

  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.background.layoutBackgroundColor,
    height: '2rem',

    '& fieldset': {
      borderColor: theme.palette.border.lightWhiteGrey,
    },

    '&:hover fieldset': {
      borderColor: theme.palette.border[isError ? 'lavaRed' : 'primary'],
    },

    '&.Mui-focused fieldset': {
      border: `.0625rem solid ${theme.palette.selected.greenSelect}`,
    },

    '&.Mui-disabled fieldset': {
      borderColor: theme.palette.border.darkGrey,
    },
  },

  '.Mui-disabled': { color: theme.palette.text.darkGrey },
}));

const TextField = ({
  disabled = false,
  value = '',
  onChange = () => {},
  error = false,
  ...props
}) => {
  return (
    <StyledTextField
      disabled={disabled}
      value={value}
      onChange={onChange}
      placeholder="-"
      error={error}
      isError={error}
      {...props}
    />
  );
};

TextField.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
};

export default TextField;
