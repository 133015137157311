import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { Box, Backdrop } from '@mui/material';
import { globalWebsocket } from '@smf/ui-util-app';
import Button from '../../components/Button';
import TEST_IDS from '../../constants/testIds';
import TableRow from './row';
import ResetFiltersEnabledIcon from '../../assets/img/resetEnabled.svg';
import ResetFiltersDisabledIcon from '../../assets/img/resetDisabled.svg';
import TableHeader from './header';
import {
  BodyBox,
  DemoTableContainer,
  FooterBox,
  HeaderBox,
  MainHeading,
  ResultsCountLabel,
  TableBox,
  ResetFiltersLabel,
} from './styledPeers';
import {
  ADD_DEMO_FIELDS,
  ADD_DEMO_VALIDATIONS_CONSTANTS,
  BUTTON_LABELS,
  HEADING_LABELS,
  QUERY_KEYS,
  VALIDATION_MESSAGES,
  TABLE_HEADINGS,
  TABLE_COLUMNS,
  DEMO_START_CONFIMER,
} from '../../constants';
import { demoActions } from '../../contexts/demo/actions';
import { useDemoContext } from '../../contexts/demo/reducer';
import AddDemo from './addDemo';
import { createDemo } from '../../utils/apiHelpers';
import FilterPopOver from '../../components/FilterPopover';
import { useRxjsState } from '../../utils/useRxjsState';
import Loader from '../../components/Loader';
import Confirmer from '../../components/Confirmer';
import { updateRxjsState } from '../../utils/rxjsStateNext';

const DemosTable = ({ demosList = [] }) => {
  const { rxjsState } = useRxjsState();
  const initialFilterProps = {
    filtersList: [],
    filtersName: '',
  };
  const { demoState, demoDispatch } = useDemoContext();
  const [tableData, setTableData] = useState(demosList);
  const [isResetEnabled, setIsResetEnabled] = useState(false);
  const [demoNameFilter, setDemoNameFilter] = useState(null);
  const [demoLengthFilter, setDemoLengthFilter] = useState(null);
  const [demoDateFilter, setDemoDateFilter] = useState(null);
  const [demoCreatedByFilter, setDemoCreatedByFilter] = useState(null);
  const [demoPlantNameFilter, setDemoPlantNameFilter] = useState(null);

  const demoNamePopOpen = Boolean(demoNameFilter);
  const demoLengthPopOpen = Boolean(demoLengthFilter);
  const demoDatePopOpen = Boolean(demoDateFilter);
  const demoCreatedByPopOpen = Boolean(demoCreatedByFilter);
  const demoPlantNamePopOpen = Boolean(demoPlantNameFilter);

  const demoNameId = demoNamePopOpen ? 'demoName-popover' : undefined;
  const demoDateId = demoDatePopOpen ? 'demoDate-popover' : undefined;
  const demoCreatedById = demoCreatedByPopOpen
    ? 'demoCreatedBy-popover'
    : undefined;
  const demoLengthId = demoLengthPopOpen ? 'demoLength-popover' : undefined;
  const demoPlantNameId = demoPlantNamePopOpen
    ? 'demoPlant-popover'
    : undefined;
  const [demoNameFilterProps, setDemoNameFilterProps] = useState(() => ({
    ...initialFilterProps,
    filtersName: TABLE_HEADINGS[0].id,
  }));
  const [demoLengthFilterProps, setDemoLengthFilterProps] = useState(() => ({
    ...initialFilterProps,
    filtersName: TABLE_HEADINGS[1].id,
  }));
  const [demoDateFilterProps, setDemoDateFilterProps] = useState(() => ({
    ...initialFilterProps,
    filtersName: TABLE_HEADINGS[2].id,
  }));
  const [demoCreatedByFilterProps, setDemoCreatedByFilterProps] = useState(
    () => ({
      ...initialFilterProps,
      filtersName: TABLE_HEADINGS[3].id,
    })
  );
  const [demoPlantNameFilterProps, setDemoPlantNameFilterProps] = useState(
    () => ({
      ...initialFilterProps,
      filtersName: TABLE_HEADINGS[4].id,
    })
  );
  const uniqueValue = (data, filterType) => {
    const newArray = [];
    switch (filterType) {
      case TABLE_COLUMNS.CREATED_AT:
        data.map((item) =>
          newArray.push(moment(item.createdAt).format('MM/DD/YYYY'))
        );
        break;
      case TABLE_COLUMNS.PLANT_NAME:
        data.map((item) => newArray.push(...item.plantNames));
        break;
      default:
        data.map((item) => newArray.push(item[filterType]));
        break;
    }
    return [...new Set(newArray)];
  };
  useEffect(() => {
    setDemoNameFilterProps({
      ...demoNameFilterProps,
      filtersList: uniqueValue(tableData, TABLE_COLUMNS.DEMO_NAME),
    });
    setDemoLengthFilterProps({
      ...demoLengthFilterProps,
      filtersList: uniqueValue(tableData, TABLE_COLUMNS.DURATION),
    });
    setDemoDateFilterProps({
      ...demoDateFilterProps,
      filtersList: uniqueValue(tableData, TABLE_COLUMNS.CREATED_AT),
    });
    setDemoCreatedByFilterProps({
      ...demoCreatedByFilterProps,
      filtersList: uniqueValue(tableData, TABLE_COLUMNS.CREATED_BY),
    });
    setDemoPlantNameFilterProps({
      ...demoPlantNameFilterProps,
      filtersList: uniqueValue(tableData, TABLE_COLUMNS.PLANT_NAME),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);
  const uniquePlantData = () => {
    const newArray = [];
    tableData.map((item) => newArray.push(...item.plantNames));
    return [...new Set(newArray)];
  };
  const queryClient = useQueryClient();
  const { mutate: createDemoMutation, isLoading: isCreatingDemo } = useMutation(
    (data) => createDemo(data),
    {
      onSuccess: () => {
        const savedDemo = {
          demoName: demoState.addDemoForm.primaryFields.demoName.value,
          duration: parseInt(
            demoState.addDemoForm.primaryFields.duration.value,
            10
          ),
          plantId: demoState.addDemoForm.primaryFields.plants.value.map(
            (e) => e.key
          ),
        };
        queryClient.invalidateQueries([QUERY_KEYS.GET_DEMO_LIST]);
        demoDispatch(demoActions.addDemoFormShow(false)); // Closing Modal
        demoDispatch(demoActions.resetForm('addDemoForm')); // Reset Form
        demoDispatch(demoActions.selectDemo(savedDemo)); // Navigate to Editor
      },
    }
  );

  const addDemoFormShowHandler = (status) => {
    demoDispatch(demoActions.addDemoFormShow(status));
    if (!status) demoDispatch(demoActions.resetForm('addDemoForm')); // Reset Form
  };

  const onSave = () => {
    const isError =
      demoState.addDemoForm.primaryFields.demoName.error ||
      demoState.addDemoForm.primaryFields.duration.error ||
      demoState.addDemoForm.primaryFields.plants.error;
    if (isError) {
      demoDispatch(demoActions.setFormDirtyState('addDemoForm', true));
    } else {
      const saveDetails = {
        demoName: demoState.addDemoForm.primaryFields.demoName.value,
        duration: parseInt(
          demoState.addDemoForm.primaryFields.duration.value,
          10
        ),
        plantId: demoState.addDemoForm.primaryFields.plants.value.map(
          (e) => e.key
        ),
      };
      createDemoMutation(saveDetails);
    }
  };

  const isDuplicateDemoName = (newDemoName) => {
    const result = demoState.demos.find(
      (e) => e.demoName.toLowerCase() === newDemoName.toLowerCase()
    );
    if (result) return true;
    return false;
  };
  const filterOnChange = (type, checked) => {
    let newFilterData = [];
    switch (type) {
      case TABLE_COLUMNS.CREATED_AT:
        newFilterData = tableData.filter((array) =>
          checked.some(
            (filter) => filter === moment(array.createdAt).format('MM/DD/YYYY')
          )
        );
        setTableData(newFilterData);
        break;
      case TABLE_COLUMNS.PLANT_NAME:
        newFilterData = tableData.filter((array) =>
          checked.some((filter) => array[type].includes(filter))
        );
        setTableData(newFilterData);
        break;
      default:
        newFilterData = tableData.filter((array) =>
          checked.some((filter) => filter === array[type])
        );
        setTableData(newFilterData);
        break;
    }
  };
  useEffect(() => {
    if (tableData.length > 0 && tableData.length !== demosList.length) {
      setIsResetEnabled(true);
    } else {
      setIsResetEnabled(false);
    }
  }, [tableData, demosList]);
  const getPopoverContent = (type) => {
    switch (type) {
      case TABLE_HEADINGS[0].id:
        return (
          <FilterPopOver
            clickCallback={setDemoNameFilter}
            filterProps={demoNameFilterProps}
            onChangeFunc={filterOnChange}
            itemDropDownValues={[
              ...new Set(tableData.map((item) => item.demoName)),
            ]}
            idRef={demoNameId}
            openBool={demoNamePopOpen}
            itemAnchorEl={demoNameFilter}
            filtersByName={TABLE_HEADINGS[0].id}
          />
        );
      case TABLE_HEADINGS[1].id:
        return (
          <FilterPopOver
            clickCallback={setDemoLengthFilter}
            filterProps={demoLengthFilterProps}
            onChangeFunc={filterOnChange}
            itemDropDownValues={[
              ...new Set(tableData.map((item) => item.duration)),
            ]}
            idRef={demoLengthId}
            openBool={demoLengthPopOpen}
            itemAnchorEl={demoLengthFilter}
            filtersByName={TABLE_HEADINGS[1].id}
          />
        );
      case TABLE_HEADINGS[2].id:
        return (
          <FilterPopOver
            clickCallback={setDemoDateFilter}
            filterProps={demoDateFilterProps}
            onChangeFunc={filterOnChange}
            itemDropDownValues={[
              ...new Set(
                tableData.map((item) =>
                  moment(item.createdAt).format('MM/DD/YYYY')
                )
              ),
            ]}
            idRef={demoDateId}
            openBool={demoDatePopOpen}
            itemAnchorEl={demoDateFilter}
            filtersByName={TABLE_HEADINGS[2].id}
          />
        );
      case TABLE_HEADINGS[3].id:
        return (
          <FilterPopOver
            clickCallback={setDemoCreatedByFilter}
            filterProps={demoCreatedByFilterProps}
            onChangeFunc={filterOnChange}
            itemDropDownValues={[
              ...new Set(tableData.map((item) => item.createdBy)),
            ]}
            idRef={demoCreatedById}
            openBool={demoCreatedByPopOpen}
            itemAnchorEl={demoCreatedByFilter}
            filtersByName={TABLE_HEADINGS[3].id}
          />
        );
      case TABLE_HEADINGS[4].id:
        return (
          <FilterPopOver
            clickCallback={setDemoPlantNameFilter}
            filterProps={demoPlantNameFilterProps}
            onChangeFunc={filterOnChange}
            itemDropDownValues={uniquePlantData()}
            idRef={demoPlantNameId}
            openBool={demoPlantNamePopOpen}
            itemAnchorEl={demoPlantNameFilter}
            filtersByName={TABLE_HEADINGS[4].id}
          />
        );
      default:
        return null;
    }
  };

  const showValidation = (field, value) => {
    if (ADD_DEMO_FIELDS.DEMO_NAME === field) {
      if (value.length === 0) {
        demoDispatch(
          demoActions.setErrorValidation(
            field,
            true,
            VALIDATION_MESSAGES.REQUIRED_FIELDS
          )
        );
      } else if (value.indexOf(' ') >= 0) {
        demoDispatch(
          demoActions.setErrorValidation(
            field,
            true,
            VALIDATION_MESSAGES.REMOVE_SPACES
          )
        );
      } else if (!ADD_DEMO_VALIDATIONS_CONSTANTS.DEMO_NAME_REGEX.test(value)) {
        demoDispatch(
          demoActions.setErrorValidation(
            field,
            true,
            VALIDATION_MESSAGES.INVALID
          )
        );
      } else if (isDuplicateDemoName(value)) {
        demoDispatch(
          demoActions.setErrorValidation(
            field,
            true,
            VALIDATION_MESSAGES.UNIQUE_DEMONAME_VAIDATION
          )
        );
      } else {
        demoDispatch(demoActions.setErrorValidation(field, false, ''));
      }
    } else if (ADD_DEMO_FIELDS.DURATION === field) {
      if (value.length === 0) {
        demoDispatch(
          demoActions.setErrorValidation(
            field,
            true,
            VALIDATION_MESSAGES.REQUIRED_FIELDS
          )
        );
      } else if (
        ADD_DEMO_VALIDATIONS_CONSTANTS.REGEX_ALPHABET_SPECIAL_CHARS.test(value)
      ) {
        demoDispatch(
          demoActions.setErrorValidation(
            field,
            true,
            VALIDATION_MESSAGES.DUARTION_NUMERICAL_VALIDATION
          )
        );
      } else if (value < ADD_DEMO_VALIDATIONS_CONSTANTS.DURATION_MIN_MINUTE) {
        demoDispatch(
          demoActions.setErrorValidation(
            field,
            true,
            VALIDATION_MESSAGES.DURATION_MIN_VALIDATION
          )
        );
      } else if (value > ADD_DEMO_VALIDATIONS_CONSTANTS.DURATION_MAX_MINUTE) {
        demoDispatch(
          demoActions.setErrorValidation(
            field,
            true,
            VALIDATION_MESSAGES.DURATION_MAX_VALIDATION
          )
        );
      } else {
        demoDispatch(demoActions.setErrorValidation(field, false, ''));
      }
    } else if (ADD_DEMO_FIELDS.PLANTS === field) {
      if (value.length === 0) {
        demoDispatch(
          demoActions.setErrorValidation(
            field,
            true,
            VALIDATION_MESSAGES.REQUIRED_FIELDS
          )
        );
      } else if (
        value.length > ADD_DEMO_VALIDATIONS_CONSTANTS.PLANTS_MAX_COUNT
      ) {
        demoDispatch(
          demoActions.setErrorValidation(
            field,
            true,
            VALIDATION_MESSAGES.EXCEED_PLANT_MAX_COUNT
          )
        );
      } else {
        demoDispatch(demoActions.setErrorValidation(field, false, ''));
      }
    }
  };

  const handleSorting = (sortField, sortOrder) => {
    if (sortField) {
      const sorted = [...tableData].sort((a, b) => {
        if (!a[sortField]) return 1;
        if (!b[sortField]) return -1;
        if (!a[sortField] && !b[sortField] === null) return 0;
        return (
          a[sortField].toString().localeCompare(b[sortField].toString(), 'en', {
            numeric: true,
          }) * (sortOrder === 'asc' ? 1 : -1)
        );
      });
      setTableData(sorted);
    }
  };
  const onClickReset = () => {
    setTableData(demosList);
  };

  useEffect(() => {
    setTableData(demosList);
  }, [demosList]);

  const isAnyDemoPreparing =
    rxjsState.ddsDemoStatus &&
    Object.keys(rxjsState.ddsDemoStatus).find(
      (demo) => rxjsState.ddsDemoStatus[demo] === 'Preparing'
    );

  const loadDemo = (demoName) => {
    demoDispatch(demoActions.setDemoStartConfirmer(false));
    const channel = 'dds';
    const data = {
      action: 'ingestDemoValue',
      demoName,
      authorizationToken: rxjsState.sessionToken,
    };
    updateRxjsState({
      ddsDemoStatus: { ...rxjsState.ddsDemoStatus, [demoName]: 'Preparing' },
    });
    globalWebsocket.sendMessage({ ...data }, channel);
  };

  const NoHandler = () => {
    demoDispatch(demoActions.setDemoStartConfirmer(null));
  };

  return (
    <>
      {isAnyDemoPreparing && <Loader />}
      <DemoTableContainer data-testid={TEST_IDS.DEMOS_TABLE_CONTAINER}>
        <HeaderBox>
          <MainHeading data-testid={TEST_IDS.DEMOS_TABLE_HEADING}>
            {HEADING_LABELS.DEMOS}
          </MainHeading>
          <Button
            isApply
            height="2.25rem"
            width="5rem"
            sx={{ marginLeft: 'auto' }}
            data-testid={TEST_IDS.DEMOS_TABLE_ADD_BUTTON}
            onClick={() => addDemoFormShowHandler(true)}
          >
            {BUTTON_LABELS.ADD}
          </Button>
        </HeaderBox>
        {!isResetEnabled ? (
          <Box
            sx={{
              display: 'flex',
              marginTop: '0.5rem',
              marginLeft: 'auto',
              padding: '0.3rem',
              border: '1px solid grey',
              borderRadius: '1rem',
              cursor: 'not-allowed',
            }}
          >
            <ResetFiltersDisabledIcon height="1.25rem" width="1.25rem" />
            <ResetFiltersLabel sx={{ marginLeft: '11.25px' }}>
              {BUTTON_LABELS.RESET_FILTERS}
            </ResetFiltersLabel>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              marginTop: '0.5rem',
              marginLeft: 'auto',
              padding: '0.3rem',
              border: '1px solid grey',
              borderRadius: '1rem',
              cursor: 'pointer',
            }}
            onClick={onClickReset}
          >
            <ResetFiltersEnabledIcon height="1.25rem" width="1.25rem" />
            <ResetFiltersLabel isEnabled sx={{ marginLeft: '0.703rem' }}>
              {BUTTON_LABELS.RESET_FILTERS}
            </ResetFiltersLabel>
          </Box>
        )}
        <TableBox>
          <TableHeader
            handleSorting={handleSorting}
            getPopoverContent={getPopoverContent}
          />
          <BodyBox data-testid={TEST_IDS.DEMOS_TABLE_BODY_CONTAINER}>
            {tableData.map((demo, idx) => (
              <TableRow demo={demo} pos={idx} key={demo.demoName} />
            ))}
          </BodyBox>
          <FooterBox>
            <ResultsCountLabel data-testid={TEST_IDS.DEMOS_TABLE_RESULTS_COUNT}>
              {tableData.length} results
            </ResultsCountLabel>
          </FooterBox>
        </TableBox>
        {demoState.addDemoForm.show && (
          <AddDemo
            closer={() => addDemoFormShowHandler(false)}
            onSave={onSave}
            showValidation={showValidation}
            isCreatingDemo={isCreatingDemo}
          />
        )}
      </DemoTableContainer>
      {demoState.demoStartConfirmer && (
        <Backdrop open>
          <Confirmer
            message={DEMO_START_CONFIMER.MESSAGE}
            YesHandler={() => {
              loadDemo(demoState.demoStartConfirmer);
            }}
            NoHandler={NoHandler}
          />
        </Backdrop>
      )}
    </>
  );
};

DemosTable.propTypes = {
  demosList: PropTypes.arrayOf(
    PropTypes.shape({
      demoName: PropTypes.string,
      duration: PropTypes.number,
      createdAt: PropTypes.number,
      createdBy: PropTypes.string,
    })
  ),
};

export default DemosTable;
