import { dds } from '@smf/ui-util-app';

const getAllConfiguredPlants = async () => {
  return dds.getDdsPlants();
};

const createDemo = async (data) => {
  return dds.createDemo(data);
};

const saveAsDemo = async (data) => {
  return dds.saveAsDemo(data);
};

const getDemoList = async () => {
  const res = await dds.getDemoList();
  return res.demoList;
};

const deleteDemo = async (demoName) => {
  return dds.deleteDemo(demoName);
};

const getEntityHierarchy = async (entityId, level) => {
  const res = await dds.getDDSPlantHierarchy(entityId, level);
  return res.data;
};

const getAllPlantsByDemoId = async (demoName) => {
  return dds.getDemoDetails(demoName);
};

const getOeeThresholds = (entityId) => {
  return dds.getOeeThresholds(entityId);
};

const getFactoryHealthDeviationThreshold = async (entityId, utility) => {
  const res = await dds.getDDSFactoryHealthDeviation(entityId, utility);
  return res.data;
};

const saveAllEvents = (data) => {
  return dds.saveAllEvents(data);
};

const getRecipeList = async (plantId) => {
  const res = await dds.getSOCRecipes(plantId);
  return res.data;
};
const getTableOfParametersList = async (
  entityId,
  recipeId,
  demoName,
  startmin,
  endmin
) => {
  const res = await dds.getTableOfParameters(
    entityId,
    recipeId,
    demoName,
    startmin,
    endmin
  );
  return res.data;
};

const getFaultCodeList = async (entityId) => {
  const res = await dds.getFaultCodes(entityId);
  return res.data;
};

export {
  getEntityHierarchy,
  getAllConfiguredPlants,
  createDemo,
  getAllPlantsByDemoId,
  getDemoList,
  getOeeThresholds,
  getFactoryHealthDeviationThreshold,
  saveAllEvents,
  saveAsDemo,
  deleteDemo,
  getRecipeList,
  getTableOfParametersList,
  getFaultCodeList,
};
