import { Backdrop } from '@mui/material';
import PropTypes from 'prop-types';
import Button from '../../../components/Button';
import {
  BUTTON_LABELS,
  EVENT_TYPE_KEYS,
  EVENT_TYPE_OPTIONS,
  FIELD_PRIORITY,
  HEADING_LABELS,
  PRIMARY_INPUT_FIELDS,
  EVENT_FORM,
  SECONDARY_INPUT_FIELDS,
  EVENT_FORM_TYPES,
  SOC_FIELDS,
} from '../../../constants';
import { FlexBox } from '../styledPeers';
import {
  EventFormBox,
  HeaderBox,
  HeadingTypo,
  InputsWrapper,
} from './styledPeers';
import PrimaryFields from './primaryFields';
import SecondaryFields from './secondaryFields';
import { demoActions } from '../../../contexts/demo/actions';
import { useDemoContext } from '../../../contexts/demo/reducer';
import useEventFormCheck from '../../../hooks/useEventFormCheck';
import { useRxjsState } from '../../../utils/useRxjsState';
import { updateRxjsState } from '../../../utils/rxjsStateNext';

const EventForm = ({ openDeleteEventHandler, closeHandler, selectedItem }) => {
  const { demoState, demoDispatch } = useDemoContext();
  const { checkField, getFieldInfo } = useEventFormCheck();
  const { rxjsState } = useRxjsState();

  const addEventHandler = () => {
    const fieldPriority = FIELD_PRIORITY.PRIMARY;
    const fieldPrioritySOC = FIELD_PRIORITY.SOC;
    const tableData = demoState.eventForm[FIELD_PRIORITY.SOC].parameters;
    const eventName = demoState.eventForm.PrimaryFields.SELECT_EVENT.value;
    let isHierarchyError = false;
    let isRecipeSelectedError = false;
    let isTableofParameterError = false;
    let isTableofParameterRowError = false;
    let isFaultCodeSelectedError = false;
    let socTableEmptyError = false;

    /**
     ** Primary Fields Validations
     *
     ** Hierarchy
     */
    isHierarchyError =
      checkField.isEmpty({
        fieldPriority,
        fieldKey: PRIMARY_INPUT_FIELDS.AREA.key,
      }) || isHierarchyError;
    isHierarchyError =
      checkField.isEmpty({
        fieldPriority,
        fieldKey: PRIMARY_INPUT_FIELDS.LINE.key,
      }) || isHierarchyError;
    if (
      (EVENT_TYPE_OPTIONS[
        getFieldInfo({
          fieldPriority,
          fieldKey: PRIMARY_INPUT_FIELDS.SELECT_EVENT.key,
          property: 'value',
        })
      ]?.hierarchyType ?? undefined) !== PRIMARY_INPUT_FIELDS.LINE.key
    ) {
      isHierarchyError =
        checkField.isEmpty({
          fieldPriority,
          fieldKey: PRIMARY_INPUT_FIELDS.ZONE.key,
        }) || isHierarchyError;
    }
    switch (eventName) {
      case EVENT_TYPE_KEYS.TRIGGER_SOC_ASSET:
        // recipe selected Validation
        isRecipeSelectedError =
          checkField.isEmpty({
            fieldPriority: fieldPrioritySOC,
            fieldKey: SOC_FIELDS.RECIPE_SELECTED.key,
          }) || isRecipeSelectedError;
        socTableEmptyError = !tableData;
        // Table of Parameters at least one row validation
        isTableofParameterError =
          checkField.tableOfParameterError(tableData) ||
          isTableofParameterError;
        // Table of Parameter row level validaton
        isTableofParameterRowError =
          checkField.tableOfParameterRowError(tableData) ||
          isTableofParameterRowError;
        break;
      case EVENT_TYPE_KEYS.FAULT_CODES:
        isFaultCodeSelectedError =
          checkField.isEmpty({
            fieldPriority: FIELD_PRIORITY.SECONDARY,
            fieldKey: SECONDARY_INPUT_FIELDS[EVENT_TYPE_KEYS.FAULT_CODES].key,
          }) || isFaultCodeSelectedError;
        break;
      default:
        break;
    }

    /**
     ** Select Event
     */
    const isSelectEventError = checkField.isEmpty({
      fieldPriority,
      fieldKey: PRIMARY_INPUT_FIELDS.SELECT_EVENT.key,
    });
    /**
     ** Start Time
     */
    const isStartTimeError = checkField.doMultipleChecks({
      fieldPriority,
      fieldKey: PRIMARY_INPUT_FIELDS.START_TIME.key,
      regexExp: EVENT_FORM.REGEX.START_TIME.exp,
      regexErrMsg: EVENT_FORM.REGEX.START_TIME.message,
      addOns: ['start_time_exceeding_demo_duration'],
    });
    /**
     ** Duration
     */
    const isDurationError = checkField.doMultipleChecks({
      fieldPriority,
      fieldKey: PRIMARY_INPUT_FIELDS.DURATION.key,
      regexExp: EVENT_FORM.REGEX.DURATION.exp,
      regexErrMsg: EVENT_FORM.REGEX.DURATION.message,
      addOns: ['duration_exceeding_demo_duration'],
    });
    /**
     ** Start Time & Duration Overlapping
     */
    let slotsOverlapCheckSignal;
    let isTimeOverlapError = true;
    if (!isSelectEventError && !isStartTimeError && !isDurationError) {
      slotsOverlapCheckSignal = checkField.time_slot_overlapping(selectedItem);
      isTimeOverlapError =
        slotsOverlapCheckSignal === EVENT_FORM.VALIDATION_SIGNAL.ERROR;
    }
    /**
     ** Secondary Fields Validations
     */
    const selectedEventName = getFieldInfo({
      fieldPriority,
      fieldKey: PRIMARY_INPUT_FIELDS.SELECT_EVENT.key,
      property: 'value',
    });
    const selectedEventType = EVENT_TYPE_OPTIONS[selectedEventName]?.eventType;
    /**
     ** For OEE & FH - Thresholds
     */
    let isOeeFhError;
    let isFhError;

    if ([EVENT_TYPE_KEYS.FH].includes(selectedEventType)) {
      const fieldKey = SECONDARY_INPUT_FIELDS[selectedEventName].key;
      isFhError = checkField.checkTheoreticalMaxValue(
        FIELD_PRIORITY.SECONDARY,
        fieldKey,
        selectedEventName,
        EVENT_FORM.REGEX.FH_VALUE.exp,
        EVENT_FORM.REGEX.FH_VALUE.message
      );
    }
    if ([EVENT_TYPE_KEYS.OEE].includes(selectedEventType)) {
      isOeeFhError = checkField.doMultipleChecks({
        fieldPriority: FIELD_PRIORITY.SECONDARY,
        fieldKey: SECONDARY_INPUT_FIELDS[selectedEventName].key,
        regexExp: EVENT_FORM.REGEX.OEE_VALUE.exp,
        regexErrMsg: EVENT_FORM.REGEX.OEE_VALUE.message,
      });
    }
    if (
      isHierarchyError ||
      isSelectEventError ||
      isStartTimeError ||
      isDurationError ||
      isTimeOverlapError ||
      isOeeFhError ||
      isFhError ||
      isRecipeSelectedError ||
      isFaultCodeSelectedError ||
      socTableEmptyError ||
      isTableofParameterError ||
      isTableofParameterRowError
    )
      return;
    const ddsLogoutConfiguration = rxjsState?.ddsLogoutConfig;
    updateRxjsState({
      ddsLogoutConfig: {
        ...ddsLogoutConfiguration,
        areAnyUnsavedChanges: true,
      },
    });
    const ddsNavigateAwayConfiguration = rxjsState?.ddsNavigateAwayConfig;
    updateRxjsState({
      ddsNavigateAwayConfig: {
        ...ddsNavigateAwayConfiguration,
        isUnsavedChange: true,
      },
    });

    const needDeletionSignal = checkField.needDeletion(selectedItem);
    if (needDeletionSignal) slotsOverlapCheckSignal = needDeletionSignal;
    demoDispatch(
      demoActions.insertEventIntoState(slotsOverlapCheckSignal, selectedItem)
    );
    closeHandler();
  };

  const deleteHandler = () => {
    if (demoState.eventForm.type === EVENT_FORM_TYPES.EDIT) {
      // Hide the edit event modal
      demoDispatch(demoActions.eventFormShow(false));
      // Open the delete confirmation modal
      openDeleteEventHandler();
    } else {
      closeHandler();
    }
  };

  return (
    <Backdrop open sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <EventFormBox>
        <HeaderBox>
          <HeadingTypo>{HEADING_LABELS.EDIT_EVENT}</HeadingTypo>
        </HeaderBox>
        <InputsWrapper>
          <PrimaryFields />
          <SecondaryFields />
        </InputsWrapper>
        <FlexBox sx={{ justifyContent: 'flex-end', padding: '1.5rem' }}>
          <Button
            width="7.25rem"
            height="2.25rem"
            onClick={() => deleteHandler()}
          >
            {BUTTON_LABELS.DELETE}
          </Button>
          <Button
            width="7.25rem"
            height="2.25rem"
            sx={{ marginLeft: '1rem' }}
            onClick={() => closeHandler()}
          >
            {BUTTON_LABELS.CANCEL}
          </Button>
          <Button
            isApply
            width="7.5rem"
            height="2.25rem"
            sx={{ marginLeft: '1rem' }}
            onClick={() => addEventHandler()}
          >
            {BUTTON_LABELS.APPLY}
          </Button>
        </FlexBox>
      </EventFormBox>
    </Backdrop>
  );
};

EventForm.propTypes = {
  openDeleteEventHandler: PropTypes.func,
  closeHandler: PropTypes.func,
  selectedItem: PropTypes.string,
};

export default EventForm;
