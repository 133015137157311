import React from 'react';
import { Backdrop, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import TEST_IDS from '../../constants/testIds';

export default function Loader() {
  return (
    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer }} open>
      <Box
        sx={{
          color: (theme) => theme.palette.background.default,
          textAlign: 'center',
        }}
      >
        <CircularProgress color="inherit" data-testid={TEST_IDS.LOADER} />
      </Box>
    </Backdrop>
  );
}
