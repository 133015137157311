import { useQuery } from '@tanstack/react-query';
import Loader from '../../../components/Loader';
import {
  FIELD_PRIORITY,
  PRIMARY_INPUT_FIELDS,
  SECONDARY_STATIC_FIELDS,
  EVENT_TYPE_OPTIONS,
  EVENT_TYPE_KEYS,
  SECONDARY_INPUT_FIELDS,
  TOAST_REDUCER_CONSTANTS,
  MESSAGE_STRINGS,
} from '../../../constants';
import { demoActions } from '../../../contexts/demo/actions';
import { useDemoContext } from '../../../contexts/demo/reducer';
import { useToastContext } from '../../../contexts/toastContext';
import { getFactoryHealthDeviationThreshold } from '../../../utils/apiHelpers';
import { FlexBox } from '../styledPeers';
import { InputLabel } from './styledPeers';
import FactoryHealthComponent from './factoryHealthComponent';
import SecondaryInput from './secondaryInput';
import factoryHealthMock from '../../../__mocks__/api/factoryHealthMock.json';
import { getEntityIdOfSelectedPlantHierarchy } from '../../../utils/helpers';

const FactoryHealthThresholdField = () => {
  const { demoState, demoDispatch } = useDemoContext();
  const { toastDispatch } = useToastContext();

  const { value: eventName } =
    demoState.eventForm[FIELD_PRIORITY.PRIMARY][
      PRIMARY_INPUT_FIELDS.SELECT_EVENT.key
    ];

  const eventType = EVENT_TYPE_OPTIONS[eventName]?.eventType;

  const [hierarchyType] = [EVENT_TYPE_OPTIONS[eventName].hierarchyType];
  const { value: entityHierarchy } =
    demoState.eventForm[FIELD_PRIORITY.PRIMARY][hierarchyType];
  const { selectedPlantHierarchy } = demoState;

  const entityId = getEntityIdOfSelectedPlantHierarchy(
    selectedPlantHierarchy,
    entityHierarchy
  );
  const {
    isFetching: fhThresholdsFetching,
    data: fhThresholdData,
    isError: fhThresholdDataError,
  } = useQuery({
    queryKey: [eventType, eventName],
    queryFn: async () => {
      const data = await getFactoryHealthDeviationThreshold(
        entityId,
        eventName
      );
      if (eventName === EVENT_TYPE_KEYS.THROUGHPUT) {
        demoDispatch(
          demoActions.updateSecondaryThresholdApi(
            eventName,
            data.maxTheoretical
          )
        );
      }
      return data;
    },
    onError: () => {
      toastDispatch({
        type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
        payload: {
          message: MESSAGE_STRINGS['Toast.message.FHERROR'],
        },
      });
    },
    placeholderData: factoryHealthMock?.threshold?.value,
    enabled: !!eventType && !!eventName,
    retry: false,
    refetchOnWindowFocus: false,
  });
  const isLoading = fhThresholdsFetching;
  const errorBool = fhThresholdDataError;
  if (errorBool) {
    return null;
  }
  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      <FlexBox isOptionsRow>
        <SecondaryInput inputField={SECONDARY_INPUT_FIELDS[eventName]} />
      </FlexBox>
      <FlexBox isOptionsRow>
        <div>
          <InputLabel>{SECONDARY_STATIC_FIELDS.THRESHOLD_VALUE}</InputLabel>
          {eventType === EVENT_TYPE_KEYS.FH && (
            <FlexBox isOptionsRow>
              <FactoryHealthComponent
                thresholdData={fhThresholdData?.threshold?.value}
              />
            </FlexBox>
          )}
        </div>
      </FlexBox>
    </div>
  );
};

export default FactoryHealthThresholdField;
