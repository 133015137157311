import { useQuery } from '@tanstack/react-query';
import Loader from '../../../components/Loader';
import {
  EVENT_TYPE_OPTIONS,
  FIELD_PRIORITY,
  PRIMARY_INPUT_FIELDS,
  SECONDARY_INPUT_FIELDS,
  SECONDARY_STATIC_FIELDS,
  THRESHOLD_TYPES,
  TOAST_REDUCER_CONSTANTS,
  MESSAGE_STRINGS,
} from '../../../constants';
import { useDemoContext } from '../../../contexts/demo/reducer';
import { getOeeThresholds } from '../../../utils/apiHelpers';
import { FlexBox } from '../styledPeers';
import SecondaryInput from './secondaryInput';
import { InputLabel, ThresholdRow, ThresholdWrapperBox } from './styledPeers';
import { useToastContext } from '../../../contexts/toastContext';
import { getEntityIdOfSelectedPlantHierarchy } from '../../../utils/helpers';

const OeeFields = () => {
  const { demoState } = useDemoContext();
  const { toastDispatch } = useToastContext();

  const { value: eventName } =
    demoState.eventForm[FIELD_PRIORITY.PRIMARY][
      PRIMARY_INPUT_FIELDS.SELECT_EVENT.key
    ];

  const eventType = EVENT_TYPE_OPTIONS[eventName]?.eventType;
  const [hierarchyType] = [EVENT_TYPE_OPTIONS[eventName].hierarchyType];
  const { value: entityHierarchy } =
    demoState.eventForm[FIELD_PRIORITY.PRIMARY][hierarchyType];
  const { selectedPlantHierarchy } = demoState;

  const entityId = getEntityIdOfSelectedPlantHierarchy(
    selectedPlantHierarchy,
    entityHierarchy
  );
  const {
    isFetching: oeeThresholdsFetching,
    data: oeeThresholdData,
    isError: oeeThresholdDataError,
  } = useQuery({
    queryKey: [eventType, eventName],
    queryFn: async () => {
      const response = await getOeeThresholds(entityId);
      const modifiedResponse = [];
      Object.values(THRESHOLD_TYPES).forEach((threshold) => {
        const thresholdMatch = response[eventName].filter(
          (deviation) => deviation.label === threshold.key
        )[0];
        modifiedResponse.push({
          ...threshold,
          lowerLimit: thresholdMatch.lowerBound,
          upperLimit: thresholdMatch.upperBound,
        });
      });
      return modifiedResponse;
    },
    onError: (error) => {
      toastDispatch({
        type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
        payload: {
          message:
            error.response?.data?.message ||
            MESSAGE_STRINGS['Toast.message.ERROR'],
        },
      });
    },
    placeholderData: Object.values(THRESHOLD_TYPES),
    enabled: !!eventType && !!eventName,
    retry: false,
    refetchOnWindowFocus: false,
  });

  return (
    <>
      {oeeThresholdsFetching && <Loader />}
      {!oeeThresholdDataError && (
        <>
          <FlexBox isOptionsRow>
            <SecondaryInput inputField={SECONDARY_INPUT_FIELDS[eventName]} />
          </FlexBox>
          <FlexBox isOptionsRow>
            <div>
              <InputLabel>{SECONDARY_STATIC_FIELDS.THRESHOLD_VALUE}</InputLabel>
              <ThresholdWrapperBox>
                {oeeThresholdData.map((threshold) => (
                  <ThresholdRow key={threshold.key}>
                    <span>{`${threshold.label} Range`}</span>
                    <span data-testid="threshold-lower-limit">{`${threshold.lowerLimit}%`}</span>
                    <span>-</span>
                    <span data-testid="threshold-upper-limit">{`${threshold.upperLimit}%`}</span>
                  </ThresholdRow>
                ))}
              </ThresholdWrapperBox>
            </div>
          </FlexBox>
        </>
      )}
    </>
  );
};
export default OeeFields;
