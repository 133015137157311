import { Box, Typography, styled, Button } from '@mui/material';
import PropTypes from 'prop-types';
import { navigateToUrl } from 'single-spa';
import { useQueryClient } from '@tanstack/react-query';
import ExclamationIcon from '../../assets/img/BlueExclamation.svg';
import TEST_IDS from '../../constants/testIds';
import {
  updateRxjsState,
  updateDDSEventState,
} from '../../utils/rxjsStateNext';
import { useRxjsState } from '../../utils/useRxjsState';
import { QUERY_KEYS } from '../../constants';
import { demoActions } from '../../contexts/demo/actions';
import { useDemoContext } from '../../contexts/demo/reducer';

const ModalContainer = styled(Box)(({ theme }) => ({
  width: '30rem',
  backgroundColor: theme.customColors.blackGrey,
  borderRadius: '.5rem',
}));

const ModalHeadingBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '1rem 0 1rem 1.5rem',
  height: '4rem',
  borderBottom: `1px solid ${theme.customColors.wrapperGrey}`,
}));

const ModalContentBox = styled(Box)(() => ({
  flexDirection: 'column',
  padding: '.875rem 2rem .94rem 4.25rem',
  height: '5.375rem',
}));

const AlertText = styled(Typography, {
  shouldForwardProp: (p) => p !== 'isBold',
})(({ isBold }) => ({
  ...(isBold && { fontWeight: 700 }),
  fontSize: '.875rem',
  lineHeight: '1.19rem',
}));

const ModalHeadingTypography = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: '1.25rem',
  lineHeight: '1.7rem',
  marginLeft: '.75rem',
}));

const ModalButtonBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '1rem 1.5rem 1.5rem 0',
}));

const FilterButton = styled(Button, {
  shouldForwardProp: (p) => p !== 'isApply',
})(({ theme, isApply }) => ({
  minWidth: '7.5rem',
  height: '2.25rem',
  fontWeight: '600',
  fontSize: '0.875rem',
  lineHeight: '1rem',
  padding: '0.625rem 1.75rem',
  border: `1px solid ${theme.customColors[isApply ? 'saveGreen' : 'white']}`,
  borderRadius: '1.25rem',
  color: theme.customColors[isApply ? 'black' : 'white'],
  backgroundColor: isApply ? theme.customColors.saveGreen : 'transparent',
  textTransform: 'none',
  '&.MuiButton-root:hover': {
    color: theme.customColors[isApply ? 'white' : 'black'],
    backgroundColor: isApply ? 'transparent' : theme.customColors.white,
    border: `1px solid ${theme.customColors[isApply ? 'saveGreen' : 'white']}`,
  },
}));

const Modal = ({
  message1,
  message2,
  modalHeading,
  buttonTitle1,
  buttonTitle2,
  logout,
  navAway,
}) => {
  const { rxjsState } = useRxjsState();
  const queryClient = useQueryClient();
  const { demoDispatch } = useDemoContext();
  const ddsLogoutConfiguration = rxjsState?.ddsLogoutConfig;
  const ddsNavigateAwayConfiguration = rxjsState?.ddsNavigateAwayConfig;
  const handleDiscardChanges = (logoutConfig) => {
    if (navAway) {
      updateDDSEventState(
        { isUserNavigating: false, location: '' },
        { isUnsavedChange: false }
      );
      demoDispatch(demoActions.selectDemo(null));
      demoDispatch(demoActions.selectedDemoDataFromAPI(null));
      demoDispatch(demoActions.setPlantHierarchy(null));
      demoDispatch(
        demoActions.selectDemoPlant({ plantId: '', plantName: '', options: [] })
      );
      queryClient.invalidateQueries([QUERY_KEYS.GET_DEMO_LIST]);
      navigateToUrl(ddsNavigateAwayConfiguration?.action?.location);
    } else if (logout) {
      updateRxjsState({
        ddsLogoutConfig: {
          ...logoutConfig,
          isConfirmLogoutClicked: true,
        },
      });
    }
  };

  const handleContinue = (logoutConfig) => {
    if (logout) {
      updateRxjsState({
        ddsLogoutConfig: {
          ...logoutConfig,
          isLogoutBtnClicked: false,
        },
      });
    } else if (navAway) {
      updateDDSEventState(
        { isUserNavigating: false, location: '' },
        { isUnsavedChange: true }
      );
    }
  };

  return (
    <ModalContainer>
      <ModalHeadingBox>
        <ExclamationIcon height="2rem" width="2rem" />
        <ModalHeadingTypography>{modalHeading}</ModalHeadingTypography>
      </ModalHeadingBox>
      <ModalContentBox>
        <AlertText sx={{ marginBottom: '1rem' }}>{message1}</AlertText>
        <AlertText isBold>{message2}</AlertText>
      </ModalContentBox>
      <ModalButtonBox>
        <FilterButton
          onClick={() => handleDiscardChanges(ddsLogoutConfiguration)}
          sx={{ marginRight: '1rem' }}
          data-testid={TEST_IDS.DISCARD_BUTTON}
        >
          {buttonTitle1}
        </FilterButton>
        <FilterButton
          isApply
          onClick={() => handleContinue(ddsLogoutConfiguration)}
          data-testid={TEST_IDS.CONTINUE_BUTTON}
        >
          {buttonTitle2}
        </FilterButton>
      </ModalButtonBox>
    </ModalContainer>
  );
};

Modal.propTypes = {
  message1: PropTypes.string.isRequired,
  message2: PropTypes.string.isRequired,
  modalHeading: PropTypes.string.isRequired,
  buttonTitle1: PropTypes.string.isRequired,
  buttonTitle2: PropTypes.string.isRequired,
  logout: PropTypes.bool.isRequired,
  navAway: PropTypes.bool.isRequired,
};

export default Modal;
