import PropTypes from 'prop-types';
import TextField from '../../../components/TextField';
import { FIELD_PRIORITY } from '../../../constants';
import TEST_IDS from '../../../constants/testIds';
import { demoActions } from '../../../contexts/demo/actions';
import { useDemoContext } from '../../../contexts/demo/reducer';
import FieldsErrorIndicator from './fieldsErrorIndicator';
import { InputLabel } from './styledPeers';

const SecondaryInput = ({ inputField }) => {
  const { demoState, demoDispatch } = useDemoContext();
  const { key, name } = inputField;
  const { value, error, errorMessage } =
    demoState.eventForm[FIELD_PRIORITY.SECONDARY][key];

  const onChangeHandler = (e, fieldKey) => {
    demoDispatch(
      demoActions.updateSecondaryEventFields(fieldKey, e.target.value)
    );
  };

  return (
    <div style={{ width: 'min-content', paddingTop: '.75rem' }}>
      <InputLabel>{name}</InputLabel>
      <TextField
        value={value}
        onChange={(e) => onChangeHandler(e, key)}
        error={error}
        data-testid={TEST_IDS.ADD_OEE_FIELD}
      />
      {error && <FieldsErrorIndicator errorMessage={errorMessage} />}
    </div>
  );
};

SecondaryInput.propTypes = {
  inputField: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  // maxValue: PropTypes.shape({
  //   value: PropTypes.number,
  //   status: PropTypes.string,
  // }),
};

export default SecondaryInput;
