/* eslint-disable no-useless-escape */
const HEADING_LABELS = {
  DEMO_DATA_SIMULATOR: 'Demo Data Simulator',
  DEMOS: 'Demos',
  ADD_EVENT: 'Add Event',
  ADD_DEMO: 'Add Demo',
  SAVE_AS_DEMO: 'Save as a New Demo',
  EDIT_EVENT: 'Event Details',
};

const TOAST_TYPE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO',
};
const TABLE_OF_PARAMETER_CONSTANTS = {
  ERRORS_VALIDATION: {
    ATLEAST_ONE_ROW: 'Please enter a minimum one full row of values',
    VALUE_TO_BE_NUMERIC: 'Value must be numerical',
    VALUE_HEIGHLIGHTED_FIELD:
      'Please select values for the highlighted fields.',
    REGEX_POSITIVE_NEGATIVE_NUMBERS: /^(-?\d+)$/,
  },
  TABLE_HEADERS: ['Parameter', 'UL', 'LL', 'Target', 'PV', 'SV', 'Risk Level'],
  TABLE_HEADING: 'Trigger SOC Risk Level',
};
export const CBM_CONSTANTS = {
  FILTER: 'Filter',
  PARAMETER_TYPE: 'Parameter Type',
  PARAMETER: 'Parameter',
};
export const USER_CONFIGURATION_CONSTANTS = {
  DELETE_BUTTON_LABLES: {
    YES: 'Yes',
    NO: 'No',
  },
  BUTTON_NAME: [
    'Save',
    'Reset',
    'Update',
    'Cancel',
    'Delete',
    'Apply',
    'No',
    'Yes',
  ],
};

const TOAST_REDUCER_CONSTANTS = {
  SHOW_SUCCESS_TOAST: 'SHOW_SUCCESS_TOAST',
  SHOW_ERROR_TOAST: 'SHOW_ERROR_TOAST',
  SHOW_WARNING_TOAST: 'SHOW_WARNING_TOAST',
  SHOW_INFO_TOAST: 'SHOW_INFO_TOAST',
  HIDE_TOAST: 'HIDE_TOAST',
};
const NOTIFICATION_LABEL = {
  TYPE: 'Confirm',
  SAVE_TEXT: 'You have unsaved changes',
  SAVE_CONFIRMATION: 'Do you want save or discard your changes?',
  DELETE_TEXT:
    'You are about to delete this demo and will not be able to recover it.',
  DELETE_CONFIRMATION: 'Would you like to continue?',
  EVENT_DELETE: 'You are about to delete an event',
  DISCARD_CHANGES: 'Discard changes',
  CONTINUE_LOGOUT: 'Continue',
  UNSAVED_LOGOUT: 'You have an unsaved event.',
  CONTINUE_EDIT: 'Would you like to continue editing the event?',
};
/** Demo Data Table Heading */
const TABLE_HEADINGS = [
  { heading: 'Demo Name', id: 'demoName', width: 2 },
  { heading: 'Length of Demo', id: 'duration', width: 1.7 },
  { heading: 'Date Created', id: 'createdAt', width: 1.5 },
  { heading: 'Created By', id: 'createdBy', width: 2 },
  { heading: 'Plants', id: 'plantNames', width: 2.5 },
];
const TABLE_COLUMNS = {
  DEMO_NAME: 'demoName',
  CREATED_AT: 'createdAt',
  CREATED_BY: 'createdBy',
  DURATION: 'duration',
  PLANT_NAME: 'plantNames',
};

export const MESSAGE_STRINGS = {
  // TOAST MESSAGES
  'Toast.message.SUCCESSFUL_CHANGE':
    'A new demo with changes has been created!',
  'Toast.message.ERROR': 'Something went wrong. Please try again later!',
  'Toast.message.WARNING': 'Oops, something went wrong!',
  'Toast.message.INFO': 'Please wait',
  'Toast.message.FHERROR': 'Targets not set for Entity',
  'Toast.message.OEEERROR': 'Targets not set for Entity',
  'Toast.message.RECIPEERROR': 'No Recipe Found',
  'Toast.message.FAULTCODEERROR': 'No Fault Code Found',
};

const QUERY_KEYS = {
  GET_DEMOS_LIST: 'GET_DEMOS_LIST',
  GET_DDS_PLANTS: 'GET_DDS_PLANTS',
  GET_ENTITY_HIERARCHY: 'getEntityHierarchy',
  GET_DEMO_PLANTS: 'GET_DEMO_PLANTS',
  GET_DEMO_LIST: 'GET_DEMO_LIST',
  GET_FACTORY_HEALTH_DEVIATION: 'GET_FACTORY_HEALTH_DEVIATION',
  GET_ALL_PLANTS_BY_DEMO_ID: 'GET_ALL_PLANTS_BY_DEMO_ID',
  DELETE_DEMO: 'DELETE_DEMO',
};

const BUTTON_SIZE = {
  STANDARD: 'standard',
  SMALL: 'small',
};

const BUTTON_LABELS = {
  ADD: 'Add',
  SAVE: 'Save',
  UPDATE: 'Update',
  APPLY: 'Apply',
  NO: 'No',
  YES: 'Yes',
  CANCEL: 'Cancel',
  DELETE: 'Delete',
  CLEAR_ALL: 'Clear All',
  ADD_EVENT: 'Add Event',
  SAVE_AS: 'Save As...',
  RESET: 'Reset',
  RESET_FILTERS: 'Reset Filters',
  DISCARD: 'Discard',
};

const EVENT_FORM_TYPES = { ADD: 'Add', EDIT: 'Edit', SAVE_AS: 'Save As' };

const INPUT_TYPE = {
  TEXT_FIELD: 'textfield',
  SELECT: 'select',
  MULTI_SELECT: 'multiSelect',
};

const FIELD_PRIORITY = {
  PRIMARY: 'PrimaryFields',
  SECONDARY: 'SecondaryFields',
  SOC: 'SOCFields',
};

/**
 *!⚡Please don't change the order, its needed for computing & removing options
 */
const HEIRARCHY_INPUT_FIELDS = {
  PLANT: { key: 'PLANT', name: 'Plant', type: INPUT_TYPE.SELECT },
  AREA: { key: 'AREA', name: 'Area', type: INPUT_TYPE.SELECT },
  LINE: { key: 'LINE', name: 'Line', type: INPUT_TYPE.SELECT },
  ZONE: { key: 'ZONE', name: 'Zone', type: INPUT_TYPE.SELECT },
  CELL: { key: 'CELL', name: 'Cell', type: INPUT_TYPE.SELECT },
  ASSET: { key: 'ASSET', name: 'Asset', type: INPUT_TYPE.SELECT },
};

/**
 *!⚡Please don't change the order, its needed for computing & removing options
 */
const PRIMARY_INPUT_FIELDS = {
  ...HEIRARCHY_INPUT_FIELDS,
  SELECT_EVENT: {
    key: 'SELECT_EVENT',
    name: 'Select Event',
    type: INPUT_TYPE.SELECT,
  },
  START_TIME: {
    key: 'START_TIME',
    name: 'Start Time',
    type: INPUT_TYPE.TEXT_FIELD,
  },
  DURATION: {
    key: 'DURATION',
    name: 'Duration (in minutes)',
    type: INPUT_TYPE.TEXT_FIELD,
  },
};

const EVENT_TYPE_KEYS = {
  OEE: 'OEE',
  QUALITY: 'Quality',
  PERFORMANCE: 'Performance',
  AVAILABILITY: 'Availability',
  FH: 'FH',
  THROUGHPUT: 'Throughput',
  WIP: 'WIP',
  EC: 'EC',
  SOC: 'SOC',
  TRIGGER_SOC_ASSET: 'TriggerSOC',
  TRIGGER_SOC: 'Trigger SOC',
  PV: 'pv',
  SV: 'sv',
  FAULT_EC: 'Fault Included Equipment Constraint',
  FAULT_CODES: 'FaultCode',
  RISK_LEVEL: 'riskLevel',
};

const EVENT_TYPE_COLOR = {
  [EVENT_TYPE_KEYS.AVAILABILITY]: '#005587',
  [EVENT_TYPE_KEYS.QUALITY]: '#00ABAB',
  [EVENT_TYPE_KEYS.PERFORMANCE]: '#0D8390',
  [EVENT_TYPE_KEYS.THROUGHPUT]: '#2C5234',
  [EVENT_TYPE_KEYS.WIP]: '#0d99ff',
  [EVENT_TYPE_KEYS.FAULT_CODES]: '#00ABAB',
  [EVENT_TYPE_KEYS.TRIGGER_SOC_ASSET]: '#2C5234',
};

const EVENT_TYPE_OPTIONS = {
  [EVENT_TYPE_KEYS.QUALITY]: {
    value: EVENT_TYPE_KEYS.QUALITY,
    label: EVENT_TYPE_KEYS.QUALITY,
    eventType: EVENT_TYPE_KEYS.OEE,
    hierarchyType: HEIRARCHY_INPUT_FIELDS.CELL.key,
  },
  [EVENT_TYPE_KEYS.PERFORMANCE]: {
    value: EVENT_TYPE_KEYS.PERFORMANCE,
    label: EVENT_TYPE_KEYS.PERFORMANCE,
    eventType: EVENT_TYPE_KEYS.OEE,
    hierarchyType: HEIRARCHY_INPUT_FIELDS.CELL.key,
  },
  [EVENT_TYPE_KEYS.AVAILABILITY]: {
    value: EVENT_TYPE_KEYS.AVAILABILITY,
    label: EVENT_TYPE_KEYS.AVAILABILITY,
    eventType: EVENT_TYPE_KEYS.OEE,
    hierarchyType: HEIRARCHY_INPUT_FIELDS.CELL.key,
  },
  [EVENT_TYPE_KEYS.WIP]: {
    value: EVENT_TYPE_KEYS.WIP,
    label: EVENT_TYPE_KEYS.WIP,
    eventType: EVENT_TYPE_KEYS.FH,
    hierarchyType: HEIRARCHY_INPUT_FIELDS.CELL.key,
  },
  [EVENT_TYPE_KEYS.THROUGHPUT]: {
    value: EVENT_TYPE_KEYS.THROUGHPUT,
    label: EVENT_TYPE_KEYS.THROUGHPUT,
    eventType: EVENT_TYPE_KEYS.FH,
    hierarchyType: HEIRARCHY_INPUT_FIELDS.LINE.key,
  },
  [EVENT_TYPE_KEYS.TRIGGER_SOC_ASSET]: {
    value: EVENT_TYPE_KEYS.TRIGGER_SOC_ASSET,
    label: EVENT_TYPE_KEYS.TRIGGER_SOC,
    eventType: EVENT_TYPE_KEYS.SOC,
    hierarchyType: HEIRARCHY_INPUT_FIELDS.ASSET.key,
  },
  [EVENT_TYPE_KEYS.FAULT_CODES]: {
    value: EVENT_TYPE_KEYS.FAULT_CODES,
    label: EVENT_TYPE_KEYS.FAULT_EC,
    eventType: EVENT_TYPE_KEYS.EC,
    hierarchyType: HEIRARCHY_INPUT_FIELDS.CELL.key,
  },
};

/**
 *  Change Done :- Secondary Field Can Be Selected Dynamically w.r.t Event Type Selected from Below Object
 */
const SECONDARY_INPUT_FIELDS = {
  [EVENT_TYPE_KEYS.QUALITY]: {
    key: EVENT_TYPE_KEYS.QUALITY,
    name: `${EVENT_TYPE_KEYS.QUALITY} Value`,
    type: INPUT_TYPE.TEXT_FIELD,
  },
  [EVENT_TYPE_KEYS.PERFORMANCE]: {
    key: EVENT_TYPE_KEYS.PERFORMANCE,
    name: `${EVENT_TYPE_KEYS.PERFORMANCE} Value`,
    type: INPUT_TYPE.TEXT_FIELD,
  },
  [EVENT_TYPE_KEYS.AVAILABILITY]: {
    key: EVENT_TYPE_KEYS.AVAILABILITY,
    name: `${EVENT_TYPE_KEYS.AVAILABILITY} Value`,
    type: INPUT_TYPE.TEXT_FIELD,
  },
  [EVENT_TYPE_KEYS.THROUGHPUT]: {
    key: EVENT_TYPE_KEYS.THROUGHPUT,
    name: `${EVENT_TYPE_KEYS.THROUGHPUT} Value`,
    type: INPUT_TYPE.TEXT_FIELD,
  },
  [EVENT_TYPE_KEYS.WIP]: {
    key: EVENT_TYPE_KEYS.WIP,
    name: `${EVENT_TYPE_KEYS.WIP} Value`,
    type: INPUT_TYPE.TEXT_FIELD,
  },
  [EVENT_TYPE_KEYS.FAULT_CODES]: {
    key: EVENT_TYPE_KEYS.FAULT_CODES,
    name: `Fault Code Selected`,
    type: INPUT_TYPE.SELECT,
  },
};

const SOC_FIELDS = {
  RECIPE_SELECTED: {
    key: 'RECIPE_SELECTED',
    name: 'Recipe Selected',
    type: INPUT_TYPE.SELECT,
  },
};

const SOC_TABLE_OF_PARAMETERS = {
  PV_TRIGGER_SOC: {
    key: 'pv',
    type: INPUT_TYPE.TEXT_FIELD,
    errorMessage: '',
    error: false,
    defaultValue: 'NA',
  },
  SV_TRIGGER_SOC: {
    key: 'sv',
    type: INPUT_TYPE.TEXT_FIELD,
    errorMessage: '',
    error: false,
    defaultValue: 'NA',
  },
  RISK_LEVEL_FOR_TABLE_OF_PARAMETERS: {
    key: 'riskLevel',
    type: INPUT_TYPE.SELECT,
    errorMessage: '',
    error: false,
    defaultValue: 'Insignificant',
    options: [],
  },
};

const SECONDARY_STATIC_FIELDS = {
  THRESHOLD_VALUE: 'Threshold Value Range',
  EC_FAULT_CODES: 'Fault Code Selected',
};

const THRESHOLD_TYPES = {
  RED: { key: 'RED', label: 'Red Threshold', lowerLimit: 0, upperLimit: 0 },
  AMBER: {
    key: 'AMBER',
    label: 'Amber Threshold',
    lowerLimit: 0,
    upperLimit: 0,
  },
  GREEN: {
    key: 'GREEN',
    label: 'Green Threshold',
    lowerLimit: 0,
    upperLimit: 0,
  },
};

const ADD_DEMO_INPUT_LABELS = {
  PLANT: 'Plants to include',
  DEMO_NAME: 'Demo Name',
  DURATION: 'Duration (in minutes)',
};

const SAVE_AS_DEMO_INPUT_LABELS = {
  PLANT: 'Plants (select  up to 4)',
  DEMO_NAME: 'Demo Name',
  DURATION: 'Duration',
};

const BUTTON_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

const DEMO_START_CONFIMER = {
  MESSAGE: 'You are about to start a demo.',
  YES: 'Yes',
  NO: 'No',
};

const DISABLED_GROUP_TYPES = ['Plant', 'Area', 'Zone'];

const ADD_DEMO_FIELDS = {
  DEMO_NAME: 'demoName',
  DURATION: 'duration',
  PLANTS: 'plants',
};

const VALIDATION_MESSAGES = {
  REQUIRED_FIELDS: 'Required Field',
  INVALID: 'An invalid character has been entered, please try again',
  DUARTION_NUMERICAL_VALIDATION: 'Please enter a numerical value only',
  EXCEED_PLANT_MAX_COUNT: 'The maximum of 4 plants per demo has been exceeded',
  DURATION_MIN_VALIDATION: 'Value must be 5 minutes or more',
  DURATION_MAX_VALIDATION: 'Value must be 240 minutes or less',
  UNIQUE_DEMONAME_VAIDATION: 'Please enter a unique demo name',
  REMOVE_SPACES: 'Please remove any spaces from the demo name',
};

export const ADD_DEMO_VALIDATIONS_CONSTANTS = {
  DEMO_NAME: 'demoName',
  DURATION: 'duration',
  PLANTS: 'plants',
  DURATION_MAX_MINUTE: 240,
  DURATION_MIN_MINUTE: 5,
  PLANTS_MAX_COUNT: 4,
  DEMO_NAME_REGEX: /^[a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$/,
  REGEX_ALPHABET_SPECIAL_CHARS:
    /[ `!@#$%^&*()_+\-=\[\]{};'"\\|,.:<>\/?~A-Za-z]/,
};

const EVENT_FORM = {
  REGEX: {
    START_TIME: {
      exp: /^[0][0-4]:[0-5][0-9]$/,
      message: 'Value must be in HH:MM Format',
    },
    DURATION: {
      exp: /^[1-9]\d*$/,
      message: 'Please enter a numerical value greater than 0',
    },
    FH_VALUE: {
      exp: /^[0-9]*$/,
      message: 'Please enter numerical positive value',
    },
    OEE_VALUE: {
      exp: /^\d$|^[1-9]\d$|^(100)$/,
      message: 'Value must be between 0 and 100',
    },
  },
  ERROR_MESSAGE: {
    START_TIME_EXCEEDING_DEMO_DEURATION:
      'Start time is beyond the end of the demo',
    DURATION_EXCEEDING_DEMO_DURATION: 'Duration must not exceed demo length',
    THRESHOLD_MAX_VALUE:
      'Throughput value should be less than max Capacity value',
    EVENTS_OVERLAPPING:
      'An event already exists for this duration for the selected event type. Please select a different starting time or duration',
    WIP_MAX_VALUE: 'WIP value should be less than 100,000',
    RECIPE_SELECTED: 'A recipe must be specified',
    FAULT_CODE_ALREADY_SELECTED:
      'Please ensure there is no overlap with an existing equipment constraint event on this cell',
    NON_NUMERICAL_VALUE: 'Value must be an integer',
  },
  VALIDATION_SIGNAL: {
    OK: 'OK',
    ERROR: 'ERROR',
    ONLY_CHANGE_EVENT_VALUE: 'ONLY_CHANGE_EVENT_VALUE',
    NO_MATCH_ENTITY_ID: 'NO_MATCH_ENTITY_ID',
    NO_MATCH_EVENT_TYPE: 'NO_MATCH_EVENT_TYPE',
    NO_MATCH_EVENT_NAME: 'NO_MATCH_EVENT_NAME',
  },
};

export const MODAL_TYPES = {
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
  CONFIRM: 'confirm',
};

const DDS_EDITOR_SIDE_HEADER = 'Equipment Hierarchy';

const DDS_HIERARCHY_LEVEL = 'Asset';

const RISK_LEVEL_VALUES = [
  { label: 'Insignificant', value: 'Insignificant' },
  { label: 'Minor', value: 'Minor' },
  { label: 'Moderate', value: 'Moderate' },
  { label: 'Major', value: 'Major' },
  { label: 'Catastrophic', value: 'Catastrophic' },
];

export {
  QUERY_KEYS,
  HEADING_LABELS,
  TABLE_HEADINGS,
  TABLE_COLUMNS,
  TOAST_TYPE,
  TOAST_REDUCER_CONSTANTS,
  BUTTON_SIZE,
  BUTTON_LABELS,
  EVENT_FORM_TYPES,
  FIELD_PRIORITY,
  HEIRARCHY_INPUT_FIELDS,
  PRIMARY_INPUT_FIELDS,
  EVENT_TYPE_KEYS,
  EVENT_TYPE_OPTIONS,
  SECONDARY_INPUT_FIELDS,
  SECONDARY_STATIC_FIELDS,
  THRESHOLD_TYPES,
  BUTTON_TYPE,
  EVENT_TYPE_COLOR,
  DISABLED_GROUP_TYPES,
  INPUT_TYPE,
  DDS_EDITOR_SIDE_HEADER,
  DDS_HIERARCHY_LEVEL,
  ADD_DEMO_INPUT_LABELS,
  SAVE_AS_DEMO_INPUT_LABELS,
  ADD_DEMO_FIELDS,
  VALIDATION_MESSAGES,
  EVENT_FORM,
  NOTIFICATION_LABEL,
  DEMO_START_CONFIMER,
  SOC_FIELDS,
  RISK_LEVEL_VALUES,
  SOC_TABLE_OF_PARAMETERS,
  TABLE_OF_PARAMETER_CONSTANTS,
};
