import { cloneDeep } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { createContext, useContext, useMemo, useReducer } from 'react';
import {
  ADD_DEMO_FIELDS,
  ADD_DEMO_INPUT_LABELS,
  SAVE_AS_DEMO_INPUT_LABELS,
  EVENT_FORM_TYPES,
  VALIDATION_MESSAGES,
  FIELD_PRIORITY,
  PRIMARY_INPUT_FIELDS,
  SECONDARY_INPUT_FIELDS,
  SOC_FIELDS,
  EVENT_TYPE_KEYS,
} from '../../constants';
import {
  sortDemoByDateDesc,
  generateInitFieldProperties,
  computePrimaryOptions,
  primaryFieldsCleaner,
  updateSelectEvent,
  needToClearSecondaryFields,
  clearDurationOverlappingError,
  insertEventToPlant,
  computeHierarchyForAutoFill,
  clearZoneRequiredOnLineEvent,
  deleteEventFromState,
  setTableData,
  updateSOCTable,
  getFaultCodes,
} from '../../utils/helpers';
import { DEMO_ACTIONS } from './actions';

export const ADD_DEMO_FORM = {
  show: false,
  type: EVENT_FORM_TYPES.ADD,
  isDirty: false,
  primaryFields: {
    [ADD_DEMO_FIELDS.DEMO_NAME]: {
      LABEL: ADD_DEMO_INPUT_LABELS.DEMO_NAME,
      placeholder: '-',
      value: '',
      error: true,
      errorMessage: VALIDATION_MESSAGES.REQUIRED_FIELDS,
    },
    [ADD_DEMO_FIELDS.DURATION]: {
      LABEL: ADD_DEMO_INPUT_LABELS.DURATION,
      value: '',
      placeholder: '-',
      error: true,
      errorMessage: VALIDATION_MESSAGES.REQUIRED_FIELDS,
    },
    [ADD_DEMO_FIELDS.PLANTS]: {
      LABEL: ADD_DEMO_INPUT_LABELS.DURATION,
      defaultValue: [],
      value: [],
      placeholder: '-',
      options: [],
      error: true,
      errorMessage: VALIDATION_MESSAGES.REQUIRED_FIELDS,
    },
  },
};

export const SAVE_AS_DEMO_FORM = {
  show: false,
  type: EVENT_FORM_TYPES.SAVE_AS,
  isDirty: false,
  primaryFields: {
    [ADD_DEMO_FIELDS.DEMO_NAME]: {
      LABEL: SAVE_AS_DEMO_INPUT_LABELS.DEMO_NAME,
      placeholder: '-',
      value: '',
      error: true,
      errorMessage: VALIDATION_MESSAGES.REQUIRED_FIELDS,
    },
    [ADD_DEMO_FIELDS.DURATION]: {
      LABEL: SAVE_AS_DEMO_INPUT_LABELS.DURATION,
      value: '',
      placeholder: '-',
      error: false,
      errorMessage: VALIDATION_MESSAGES.REQUIRED_FIELDS,
    },
    [ADD_DEMO_FIELDS.PLANTS]: {
      LABEL: SAVE_AS_DEMO_INPUT_LABELS.DURATION,
      defaultValue: [],
      value: [],
      placeholder: '-',
      options: [],
      error: false,
      errorMessage: VALIDATION_MESSAGES.REQUIRED_FIELDS,
    },
  },
};

/**
 ** Initial event form properties
 */
export const EVENT_FORM_INIT = {
  show: false,
  type: EVENT_FORM_TYPES.ADD,
  [FIELD_PRIORITY.PRIMARY]: {
    ...generateInitFieldProperties(PRIMARY_INPUT_FIELDS),
  },
  [FIELD_PRIORITY.SECONDARY]: {
    ...generateInitFieldProperties(SECONDARY_INPUT_FIELDS),
  },
  [FIELD_PRIORITY.SOC]: {
    ...generateInitFieldProperties(SOC_FIELDS),
    parameters: [],
    errorMessage: [],
    editParameters: [],
    recipeSeleted: '',
  },
};

const demoInit = {
  demos: [],
  addDemoForm: { ...ADD_DEMO_FORM },
  selectedDemo: null,
  selectedDemoPlant: { plantId: '', plantName: '', options: [] },
  selectedDemoDataFromAPI: null,
  defaultGanttChartState: null,
  selectedPlantHierarchy: null,
  isEventsDirty: false,
  eventForm: { ...EVENT_FORM_INIT },
  demoStartConfirmer: null,
  saveAsForm: { ...SAVE_AS_DEMO_FORM },
};

const demoReducer = (state, action) => {
  switch (action.type) {
    case DEMO_ACTIONS.SET_DEMO_DATA: {
      const sortedDemos = action.payload.sort(sortDemoByDateDesc);
      return {
        ...state,
        demos: sortedDemos,
      };
    }

    case DEMO_ACTIONS.RESET_FORM: {
      return {
        ...state,
        addDemoForm: { ...ADD_DEMO_FORM },
      };
    }

    case DEMO_ACTIONS.RESET_SAVE_AS_FORM: {
      return {
        ...state,
        saveAsForm: { ...SAVE_AS_DEMO_FORM },
      };
    }

    case DEMO_ACTIONS.SET_SELECTED_DEMO: {
      return { ...state, selectedDemo: action.payload };
    }

    /**
     * @param {object} plant - {plantId, plantName, options - optional}
     *
     * set the selected plant of the current demo with options
     * and set the plant in event form as well
     */
    case DEMO_ACTIONS.SET_SELECTED_DEMO_PLANT: {
      return {
        ...state,
        selectedDemoPlant: { ...state.selectedDemoPlant, ...action.payload },
      };
    }

    case DEMO_ACTIONS.SET_SELECTED_DEMO_DATA_FROM_API: {
      return {
        ...state,
        selectedDemoDataFromAPI: action.payload,
        defaultGanttChartState: action.payload,
      };
    }
    /**
     * @param {bool} show - whether to show or not
     * @param {string} type - type of the form
     *
     * if false then reset the state with initial properties,
     * else set the state to show with respective type & values.
     */
    case DEMO_ACTIONS.ADD_DEMO_FORM_SHOW: {
      let { isDirty } = state.addDemoForm;
      if (!action.payload) isDirty = false;
      return {
        ...state,
        addDemoForm: {
          ...state.addDemoForm,
          show: action.payload,
          isDirty,
        },
      };
    }

    case DEMO_ACTIONS.SAVE_AS_FORM_SHOW: {
      let { isDirty } = state.saveAsForm;
      if (!action.payload) isDirty = false;
      return {
        ...state,
        saveAsForm: {
          ...state.saveAsForm,
          show: action.payload,
          isDirty,
        },
      };
    }

    case DEMO_ACTIONS.SET_FORM_DIRTY_STATE: {
      const { formName, value } = action.payload;
      return {
        ...state,
        [formName]: { ...state[formName], isDirty: value },
      };
    }

    case DEMO_ACTIONS.SET_ADD_FORM_PLANTS_OPTIONS: {
      return {
        ...state,
        addDemoForm: {
          ...state.addDemoForm,
          primaryFields: {
            ...state.addDemoForm.primaryFields,
            plants: {
              ...state.addDemoForm.primaryFields.plants,
              options: action.payload || [],
            },
          },
        },
      };
    }

    case DEMO_ACTIONS.SET_SAVE_AS_FORM_PLANTS_OPTIONS: {
      return {
        ...state,
        saveAsForm: {
          ...state.saveAsForm,
          primaryFields: {
            ...state.saveAsForm.primaryFields,
            plants: {
              ...state.saveAsForm.primaryFields.plants,
              options: action.payload || [],
            },
          },
        },
      };
    }

    case DEMO_ACTIONS.CHNAGE_ADD_DEMO_FORM_VALUE: {
      const { field, value } = action.payload;
      return {
        ...state,
        addDemoForm: {
          ...state.addDemoForm,
          primaryFields: {
            ...state.addDemoForm.primaryFields,
            [field]: {
              ...state.addDemoForm.primaryFields[field],
              value,
            },
          },
        },
      };
    }

    case DEMO_ACTIONS.CHANGE_SAVE_AS_DEMO_FORM_VALUE: {
      const { field, value } = action.payload;
      return {
        ...state,
        saveAsForm: {
          ...state.saveAsForm,
          primaryFields: {
            ...state.saveAsForm.primaryFields,
            [field]: {
              ...state.saveAsForm.primaryFields[field],
              value,
            },
          },
        },
      };
    }

    case DEMO_ACTIONS.SET_ERRORS: {
      const { field, error, errorMessage } = action.payload;
      return {
        ...state,
        addDemoForm: {
          ...state.addDemoForm,
          primaryFields: {
            ...state.addDemoForm.primaryFields,
            [field]: {
              ...state.addDemoForm.primaryFields[field],
              error,
              errorMessage,
            },
          },
        },
      };
    }

    case DEMO_ACTIONS.SET_SAVE_AS_ERRORS: {
      const { field, error, errorMessage } = action.payload;
      return {
        ...state,
        saveAsForm: {
          ...state.saveAsForm,
          primaryFields: {
            ...state.saveAsForm.primaryFields,
            [field]: {
              ...state.saveAsForm.primaryFields[field],
              error,
              errorMessage,
            },
          },
        },
      };
    }

    /**
     * @param {bool} show - whether to show or not
     * @param {string} type - type of the form
     *
     * if false then reset the state with initial properties,
     * else set the state to show with respective type & values.
     */
    case DEMO_ACTIONS.EVENT_FORM_SHOW: {
      const { show, type } = action.payload;

      if (!show) {
        return {
          ...state,
          eventForm: { ...state.eventForm, show: false },
        };
      }

      return {
        ...state,
        eventForm: { ...state.eventForm, show, type },
      };
    }

    case DEMO_ACTIONS.SET_PLANT_HIERARCHY: {
      return {
        ...state,
        selectedPlantHierarchy: action.payload,
      };
    }

    /**
     * @param {string} fieldKey - (PRIMARY_INPUT_FIELDS | INPUT_FIELDS) key value
     * @param {string} fieldValue - typed/selected input field's value
     * @param {string} siblingFieldKey - (undefined | PRIMARY_INPUT_FIELDS | INPUT_FIELDS) key value
     *
     * only update the input fields values
     * if needed update the next sibling's options.
     * dont't update defaultValue because it is used to trace changes happened or not
     */
    case DEMO_ACTIONS.UPDATE_PRIMARY_EVENT_FIELDS: {
      const { fieldKey, fieldValue, siblingFieldKey } = action.payload;

      return {
        ...state,
        eventForm: {
          ...state.eventForm,
          [FIELD_PRIORITY.PRIMARY]: {
            ...state.eventForm[FIELD_PRIORITY.PRIMARY],
            [fieldKey]: {
              ...state.eventForm[FIELD_PRIORITY.PRIMARY][fieldKey],
              value: fieldValue,
              error: false,
              errorMessage: '',
            },
            ...(siblingFieldKey && {
              [siblingFieldKey]: {
                ...state.eventForm[FIELD_PRIORITY.PRIMARY][siblingFieldKey],
                value: '',
                options: computePrimaryOptions(
                  fieldKey,
                  fieldValue,
                  state.selectedPlantHierarchy
                ),
              },
            }),
            ...updateSelectEvent(
              fieldKey,
              state.eventForm[FIELD_PRIORITY.PRIMARY][
                PRIMARY_INPUT_FIELDS.SELECT_EVENT.key
              ],
              true
            ),
            ...clearZoneRequiredOnLineEvent(
              fieldKey,
              fieldValue,
              state.eventForm[FIELD_PRIORITY.PRIMARY][
                PRIMARY_INPUT_FIELDS.ZONE.key
              ]
            ),
            ...clearDurationOverlappingError(fieldKey, state.eventForm),
            ...primaryFieldsCleaner(
              siblingFieldKey ?? fieldKey,
              false,
              state.eventForm[FIELD_PRIORITY.PRIMARY]
            ),
          },
          ...(needToClearSecondaryFields(fieldKey) && {
            [FIELD_PRIORITY.SECONDARY]: {
              ...generateInitFieldProperties(SECONDARY_INPUT_FIELDS),
            },
          }),
        },
      };
    }

    /**
     * @param {string} fieldKey - SECONDARY_INPUT_FIELDS key value
     * @param {string} fieldValue - typed/selected input field's value
     *
     * only update the secondary input field's value
     * dont't update defaultValue because it is used to trace changes happened or not
     */
    case DEMO_ACTIONS.UPDATE_SECONDARY_EVENT_FIELDS: {
      const { fieldKey, fieldValue } = action.payload;
      return {
        ...state,
        eventForm: {
          ...state.eventForm,
          [FIELD_PRIORITY.SECONDARY]: {
            ...state.eventForm[FIELD_PRIORITY.SECONDARY],
            [fieldKey]: {
              ...state.eventForm[FIELD_PRIORITY.SECONDARY][fieldKey],
              value: fieldValue,
              error: false,
              errorMessage: '',
            },
          },
        },
      };
    }

    case DEMO_ACTIONS.UPDATE_SOC_EVENT_FIELDS: {
      const data = action.payload;
      return {
        ...state,
        eventForm: {
          ...state.eventForm,
          [FIELD_PRIORITY.SOC]: {
            ...state.eventForm[FIELD_PRIORITY.SOC],
            RECIPE_SELECTED: {
              ...state.eventForm[FIELD_PRIORITY.SOC].RECIPE_SELECTED,
              options: data || [],
              error: false,
              errorMessage: '',
            },
          },
        },
      };
    }
    case DEMO_ACTIONS.UPDATE_RECIPE_SELECT: {
      return {
        ...state,
        eventForm: {
          ...state.eventForm,
          // type: EVENT_FORM_TYPES.ADD,
          [FIELD_PRIORITY.SOC]: {
            ...state.eventForm[FIELD_PRIORITY.SOC],
            RECIPE_SELECTED: {
              ...state.eventForm[FIELD_PRIORITY.SOC].RECIPE_SELECTED,
              value: action.payload,
              error: false,
              errorMessage: '',
            },
          },
        },
      };
    }

    case DEMO_ACTIONS.SET_TRIGGER_SOC_TABLE: {
      const data = action.payload;
      return {
        ...state,
        eventForm: {
          ...state.eventForm,
          [FIELD_PRIORITY.SOC]: {
            ...state.eventForm[FIELD_PRIORITY.SOC],
            parameters: setTableData(data),
            errorMessage: '',
          },
        },
      };
    }
    case DEMO_ACTIONS.UPDATE_TRIGGER_SOC_DATA: {
      const { parameter, value, error, errorMessage, fieldKey } =
        action.payload;
      return {
        ...state,
        eventForm: {
          ...state.eventForm,
          [FIELD_PRIORITY.SOC]: {
            ...state.eventForm[FIELD_PRIORITY.SOC],
            parameters: [
              ...updateSOCTable(
                [...state.eventForm[FIELD_PRIORITY.SOC].parameters],
                parameter,
                fieldKey,
                error,
                errorMessage,
                value
              ),
            ],
          },
        },
      };
    }
    /**
     * @param {string} errorMessage - VALIDATION_MESSAGES value
     *
     * To set the TABLE of Parameter inline error message
     */
    case DEMO_ACTIONS.SET_TABLE_OF_PARAMETER_ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        eventForm: {
          ...state.eventForm,
          [FIELD_PRIORITY.SOC]: {
            ...state.eventForm[FIELD_PRIORITY.SOC],
            errorMessage: error,
          },
        },
      };
    }

    case DEMO_ACTIONS.UPDATE_EC_EVENT_FIELDS: {
      const data = action.payload;
      return {
        ...state,
        eventForm: {
          ...state.eventForm,
          [FIELD_PRIORITY.SECONDARY]: {
            ...state.eventForm[FIELD_PRIORITY.SECONDARY],
            [EVENT_TYPE_KEYS.FAULT_CODES]: {
              ...state.eventForm[FIELD_PRIORITY.SECONDARY][
                EVENT_TYPE_KEYS.FAULT_CODES
              ],
              options: getFaultCodes(data) || [],
              error: false,
              errorMessage: '',
            },
          },
        },
      };
    }

    case DEMO_ACTIONS.UPDATE_SECONDARY_THRESHOLD_API: {
      const { eventType, data } = action.payload;

      return {
        ...state,
        eventForm: {
          ...state.eventForm,
          [FIELD_PRIORITY.SECONDARY]: {
            ...state.eventForm[FIELD_PRIORITY.SECONDARY],
            [eventType]: {
              ...state.eventForm[FIELD_PRIORITY.SECONDARY][eventType],
              maxTheoretical: data,
            },
          },
        },
      };
    }

    /**
     * @param {string} fieldPriority - FIELD_PRIORITY value
     * @param {string} fieldKey - (PRIMARY_INPUT_FIELDS | SECONDARY_INPUT_FIELDS) key value
     * @param {string} errorMessage - VALIDATION_MESSAGES value
     *
     * To set the event form fields error message
     */
    case DEMO_ACTIONS.SET_EVENT_FORM_FIELD_ERROR: {
      const { fieldPriority, fieldKey, errorMessage, error } = action.payload;

      return {
        ...state,
        eventForm: {
          ...state.eventForm,
          [fieldPriority]: {
            ...state.eventForm[fieldPriority],
            [fieldKey]: {
              ...state.eventForm[fieldPriority][fieldKey],
              error: error === false ? error : true,
              errorMessage,
            },
          },
        },
      };
    }

    /**
     * To reset the event form fields
     * And initialize them with selected plant
     */
    case DEMO_ACTIONS.RESET_EVENT_FORM_FIELDS: {
      const { plantId, plantName } = state.selectedDemoPlant;

      return {
        ...state,
        eventForm: {
          ...state.eventForm,
          [FIELD_PRIORITY.PRIMARY]: {
            ...state.eventForm[FIELD_PRIORITY.PRIMARY],
            [PRIMARY_INPUT_FIELDS.PLANT.key]: {
              ...state.eventForm[FIELD_PRIORITY.PRIMARY][
                PRIMARY_INPUT_FIELDS.PLANT.key
              ],
              value: plantId,
              options: [{ value: plantId, label: plantName }],
            },
            [PRIMARY_INPUT_FIELDS.AREA.key]: {
              ...state.eventForm[FIELD_PRIORITY.PRIMARY][
                PRIMARY_INPUT_FIELDS.AREA.key
              ],
              value: '',
              error: false,
              errorMessage: '',
              options: computePrimaryOptions(
                PRIMARY_INPUT_FIELDS.PLANT.key,
                plantId,
                state.selectedPlantHierarchy
              ),
            },
            ...updateSelectEvent(
              PRIMARY_INPUT_FIELDS.PLANT.key,
              state.eventForm[FIELD_PRIORITY.PRIMARY][
                PRIMARY_INPUT_FIELDS.SELECT_EVENT.key
              ]
            ),
            ...primaryFieldsCleaner(PRIMARY_INPUT_FIELDS.AREA.key, true),
          },
          [FIELD_PRIORITY.SECONDARY]: {
            ...generateInitFieldProperties(SECONDARY_INPUT_FIELDS),
          },
          [FIELD_PRIORITY.SOC]: {
            ...generateInitFieldProperties(SOC_FIELDS),
            parameters: [],
          },
        },
      };
    }

    /**
     * @param {string} plantIndex - position
     * @param {object} events - the events
     */
    case DEMO_ACTIONS.SET_EVENTS: {
      const { plantIndex, events } = action.payload;

      const { plants } = state.selectedDemoDataFromAPI;
      plants[plantIndex].events = { ...events };

      return {
        ...state,
        isEventsDirty: true,
        selectedDemoDataFromAPI: {
          ...state.selectedDemoDataFromAPI,
          plants,
        },
      };
    }

    /**
     * @param {boolean} isDirty - the events dirtyness flag
     *
     * with this flag we can findout if there is any unchanged chan
     */
    case DEMO_ACTIONS.SET_EVENTS_DIRTYNESS: {
      return { ...state, isEventsDirty: action.payload };
    }

    /**
     * @param {string} demoName - confirmer for which demo show(value)/hide(null)
     *
     */
    case DEMO_ACTIONS.SET_DEMO_START_CONFIRMER: {
      return { ...state, demoStartConfirmer: action.payload };
    }

    /**
     * @param {number} insertPosition - index to insert the event
     * @param {string} missingProperty - VALIDATION_SIGNAL
     *
     * add event to the respective entity
     */
    case DEMO_ACTIONS.INSERT_EVENT_INTO_STATE: {
      const { validationSignal } = action.payload;

      return {
        ...state,
        isEventsDirty: true,
        selectedDemoDataFromAPI: {
          ...state.selectedDemoDataFromAPI,
          plants: [...insertEventToPlant(state, validationSignal)],
        },
      };
    }

    case DEMO_ACTIONS.SAVE_EVENTS: {
      return {
        ...state,
        defaultGanttChartState: action.payload,
      };
    }

    case DEMO_ACTIONS.DISCARD_EVENTS: {
      return {
        ...state,
        isEventsDirty: false,
        selectedDemoDataFromAPI: {
          ...state.defaultGanttChartState,
        },
      };
    }

    /**
     * delete event from state for the selected event
     */
    case DEMO_ACTIONS.DELETE_EVENT_FROM_STATE: {
      const { selectedItem } = action.payload;
      return {
        ...state,
        isEventsDirty: true,
        selectedDemoDataFromAPI: {
          ...state.selectedDemoDataFromAPI,
          plants: [
            ...deleteEventFromState(
              cloneDeep(state.selectedDemoDataFromAPI.plants),
              state.selectedDemoPlant.plantId,
              selectedItem
            ),
          ],
        },
      };
    }

    /**
     * @param {array} splitedEntityId - selected entityId in pipe separated
     * @param {string} startTime - selected time in HH:MM formart
     * @param {array} eventData - undefined | event data [type, name, position] - as per state obj structure
     *
     * automatically fill the event form based on the selection in Gantt chart
     * based on event data presence decide its new or edit form
     */
    case DEMO_ACTIONS.AUTO_FILL_EVENT_FORM: {
      const { splitedEntityId, startTime, eventData, selectedEventData } =
        action.payload;
      const isEditForm = Boolean(eventData);
      const [eventType, eventName, eventSlotPosition] = isEditForm
        ? eventData
        : Array(3).fill('');
      const autofilledFileds = computeHierarchyForAutoFill(
        splitedEntityId,
        state.selectedPlantHierarchy,
        state.eventForm[FIELD_PRIORITY.PRIMARY]
      );

      let startmin;
      let endmin;

      if (isEditForm) {
        const evenMetaData = state.selectedDemoDataFromAPI.plants.filter(
          (plant) => plant.plantId === parseInt(splitedEntityId[0], 10)
        )[0].events[splitedEntityId.join('|')][eventType][eventName][
          eventSlotPosition
        ];
        startmin = evenMetaData.startmin;
        endmin = evenMetaData.endmin;
      }
      const startTimeValue = isEditForm
        ? moment().startOf('day').add(startmin, 'minute').format('HH:mm')
        : startTime;
      return {
        ...state,
        eventForm: {
          ...state.eventForm,
          show: true,
          type: isEditForm ? EVENT_FORM_TYPES.EDIT : EVENT_FORM_TYPES.ADD,
          [FIELD_PRIORITY.PRIMARY]: {
            ...state.eventForm[FIELD_PRIORITY.PRIMARY],
            ...autofilledFileds,
            [PRIMARY_INPUT_FIELDS.START_TIME.key]: {
              ...state.eventForm[FIELD_PRIORITY.PRIMARY][
                PRIMARY_INPUT_FIELDS.START_TIME.key
              ],
              defaultValue: startTimeValue,
              value: startTimeValue,
            },
            ...(isEditForm && {
              [PRIMARY_INPUT_FIELDS.SELECT_EVENT.key]: {
                ...autofilledFileds[PRIMARY_INPUT_FIELDS.SELECT_EVENT.key],
                defaultValue: eventName,
                value: eventName,
              },
              [PRIMARY_INPUT_FIELDS.DURATION.key]: {
                ...state.eventForm[FIELD_PRIORITY.PRIMARY][
                  PRIMARY_INPUT_FIELDS.DURATION.key
                ],
                defaultValue: (endmin - startmin).toString(),
                value: (endmin - startmin).toString(),
              },
            }),
          },

          ...(isEditForm && {
            [FIELD_PRIORITY.SECONDARY]: {
              ...state.eventForm[FIELD_PRIORITY.SECONDARY],
              [eventName]: {
                ...state.eventForm[FIELD_PRIORITY.SECONDARY][eventName],
                defaultValue: selectedEventData[0]?.value?.toString(),
                value: selectedEventData[0]?.value?.toString(),
              },
            },
            ...(isEditForm && {
              [FIELD_PRIORITY.SOC]: {
                ...state.eventForm[FIELD_PRIORITY.SOC],
                RECIPE_SELECTED: {
                  ...state.eventForm[FIELD_PRIORITY.SOC][
                    SOC_FIELDS.RECIPE_SELECTED.key
                  ],
                  value: selectedEventData[0]?.recipeId?.toString(),
                },
                editParameters: selectedEventData[0]?.parameters,
                recipeSeleted: selectedEventData[0]?.recipeId?.toString(),
              },
            }),
          }),
        },
      };
    }

    default: {
      return state;
    }
  }
};

const DemoContext = createContext();

const DemoProvider = ({ children, state = demoInit }) => {
  const [demoState, demoDispatch] = useReducer(demoReducer, state);

  const demoContextValue = useMemo(
    () => ({ demoState, demoDispatch }),
    [demoState]
  );

  return (
    <DemoContext.Provider value={demoContextValue}>
      {children}
    </DemoContext.Provider>
  );
};

DemoProvider.propTypes = {
  children: PropTypes.node,
  state: PropTypes.shape({}),
};

export const useDemoContext = () => useContext(DemoContext);
export default DemoProvider;
