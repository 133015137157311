import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, Box } from '@mui/material';
import { GridItem, TableContentLabel } from './styledPeers';
import TEST_IDS from '../../constants/testIds';
import { TABLE_HEADINGS, TABLE_COLUMNS } from '../../constants';
import SortAscIcon from '../../assets/img/DDSTableSortAsc.svg';
import SortDescIcon from '../../assets/img/DDSTableSortDesc.svg';

const TableHeader = ({ handleSorting, getPopoverContent }) => {
  const [sortOrder, setSortOrder] = useState('desc');

  const handleSortChange = (property) => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    handleSorting(property, sortOrder);
  };

  const sortIcon = (heading) => {
    if (sortOrder === 'asc') {
      return (
        <SortAscIcon
          data-testid={`${TEST_IDS.DEMOS_TABLE_HEADER_ASC_SORT_ICON}-${heading.id}`}
        />
      );
    }
    return (
      <SortDescIcon
        data-testid={`${TEST_IDS.DEMOS_TABLE_HEADER_DESC_SORT_ICON}-${heading.id}`}
      />
    );
  };
  return (
    <Grid
      container
      sx={{
        backgroundColor: (theme) => theme.customColors.blackishGrey,
        border: (theme) => `.0625rem solid ${theme.customColors.wrapperGrey}`,
        height: '3.5rem',
        borderRadius: '.3rem .3rem 0 0',
      }}
    >
      <GridItem xs={0.1} />
      {TABLE_HEADINGS.map((heading) => (
        <GridItem
          xs={heading.width}
          key={heading.id}
          sx={{
            [`&:hover .changeVisibility-${heading.id}`]: {
              visibility: 'visible',
            },
          }}
        >
          <TableContentLabel>{heading.heading}</TableContentLabel>
          {heading.id !== TABLE_COLUMNS.PLANT_NAME && (
            <IconButton
              className={`changeVisibility-${heading.id}`}
              onClick={() => handleSortChange(heading.id)}
              size="small"
              sx={{
                visibility: 'hidden',
              }}
              data-testid={`${TEST_IDS.DEMOS_TABLE_HEADER_SORT_BUTTON}-${heading.id}`}
            >
              {sortIcon(heading)}
            </IconButton>
          )}
          <Box
            className={`changeVisibility-${heading.id}`}
            sx={{
              visibility: 'hidden',
              marginLeft: '5px',
            }}
            data-testid={`${TEST_IDS.DEMOS_TABLE_HEADER_FILTER_BUTTON}-${heading.id}`}
          >
            {getPopoverContent(heading.id)}
          </Box>
        </GridItem>
      ))}
      <GridItem xs={2.2} />
    </Grid>
  );
};
export default TableHeader;

TableHeader.propTypes = {
  handleSorting: PropTypes.func,
  getPopoverContent: PropTypes.func,
};
